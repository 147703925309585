import moment from 'moment';
class Display {
	userDate(user, date, options = {}) {
		let value = user && (user.first_name + " " + user.last_name) || (options.missing || '');
		value += date && (" on " + moment(date).format('lll'));
		return value
	}
}
export {Display}
export default new Display()
