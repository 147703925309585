import React from 'react';

import DatePicker from '../../reusables/DatePicker';
import { FilterSection } from 'components/reusables/AppStyles';

const BalancesActions = props => {
  let {
    dateRange,
    pickerRef,
    setPickerRef,
    showFromMonth,
    handleFromChange,
    handleToChange,
    handleFromTodayButton,
    handleToTodayButton,
    currentBalance
  } = props;
  return (
    <FilterSection>
      <div className="flex flex-row-se justify-between">
        <div className="flex flex-row-sb">
          <DatePicker
            dateRange={dateRange}
            pickerRef={pickerRef}
            setPickerRef={setPickerRef}
            showFromMonth={showFromMonth}
            handleFromChange={handleFromChange}
            handleToChange={handleToChange}
            handleFromTodayButton={handleFromTodayButton}
            handleToTodayButton={handleToTodayButton}
          />
        </div>
        <div className="flex-row-eb">
          {currentBalance && (<>
          Current Balance:&nbsp;&nbsp;
          <h1>${currentBalance}</h1></>)
          }
        </div>
      </div>
    </FilterSection>
  );
};

export default BalancesActions;
