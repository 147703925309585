import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';
import Grid from '@material-ui/core/Grid';
import {
  DisplayList,
  PageHeader,
  ContentCardShadowed,
  FormButton,
  Page
} from '@tycoonlabs/web-components';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Session from '../../utils/session';
import { useLazyQuery, useMutation } from 'react-apollo';
import { CLIENT, ADMIN, CS, PA, SALES, ASSOCIATE } from 'utils/roles';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import DefaultAvatar from 'assets/avatar.png';
import { GET_USERS_FOR_COMPANY } from 'graphql/queries';
import {
  ADD_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER,
  RESEND_INVITE
} from 'graphql/mutations';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AddUserDrawer from './AddUserDrawer';
import configs from 'config';


const ProfileCard = styled(Card)`
  && {
    box-shadow: none;
    border-radius: 8px;
    margin: 4px;
    padding: 8px;
    border: 1px solid #ffffff;
    :hover {
      border: 1px solid #eeeeee;
    }
  }
`;
const ProfileMedia = styled(CardMedia)`
  && {
    margin: 8px;
    width: 80px;
    min-height: 80px;
    box-shadow: 0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #cce1e9;
    border-radius: 50%;
  }
`;
const ProfileContent = styled(CardContent)`
  h3 {
    font-size: 18px;
    font-size: 1.8rem;
    color: #282828;
    padding: 8px 0;
  }
  h5 {
    font-size: 12px;
    font-size: 1.2rem;
    color: #383838;
    padding: 4px 0;
  }
  && {
    padding: 0 8px;
    hr {
      width: 100%;
      color: #FF00000;
    }
    :last-child {
      padding-bottom: 0;
    }
  }
  .actions {
    // padding:3px;
  }
  .role {
    text-transform: capitalize;
  }
  a {
    color: #00b0ff;
  }
`;
const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
const ProfileBox = (
  compUser,
  currUserObj,
  is_admin,
  companyId,
  numOfUsers,
  cb,
  currentCompany,
  handleResend
) => {
  return (
    <ProfileCard className={'flex-row-ss '} key={compUser.user.id}>
      <ProfileMedia image={compUser.user.profile_image_url || DefaultAvatar} />
      <ProfileContent className={' flex flex-row-ss'}>
        <div className={'flex'}>
          <h3 className={''}>
            {compUser.user.first_name} {compUser.user.last_name}
          </h3>
          <h5 className={' role'}>{compUser.user.role}</h5>
          {(compUser.user.role === CLIENT || compUser.user.role === PA )&& (
            <h5 className={''}>
              <a href={`mailto:${compUser.user.email}`}>
                {compUser.user.email}
              </a>
              {compUser.user.phone && ` | ${compUser.user.phone}`}
            </h5>
          )}
          {/* {(compUser.user.role !== CLIENT &&  compUser.user.role !== PA ) && (
            <h5 className={''}>
              <a
                href={`mailto:${configs.app.support}?subject=Support Request for ${currentCompany.name
                  }${currentCompany.customer_num && '/' + currentCompany.customer_num}`}
              >
                Request Support
              </a>
            </h5>
          )} */}
          {compUser.pending ? (
            <h5 style={{ fontSize: '1rem', color: 'gray' }}>Pending Invite</h5>
          ) : (
              ''
            )}
          {compUser.user.calendar && (
            <h5>
              <a href={compUser.user.calendar} target={'_blank'}>
                Book a meeting
              </a>
            </h5>
          )}
        </div>
      </ProfileContent>
      <Actions>
        {numOfUsers > 1 &&
          currUserObj.role !== ASSOCIATE &&
          (currUserObj.role === ADMIN ||
            currUserObj.role === CS ||
            currUserObj.role === SALES ||
            compUser.user.id === currUserObj.id ||
            is_admin) && (
            <FormButton
              compact
              onClick={() =>
                cb({
                  variables: {
                    user_id: compUser.user.id,
                    company_id: companyId
                  }
                })
              }
            >
              {compUser.user.id === currUserObj.id ? 'Leave' : 'Remove'}
            </FormButton>
          )}
        {currUserObj.role === ASSOCIATE && (compUser.user.id === currUserObj.id) && (
          <FormButton
            compact
            onClick={() =>
              cb({
                variables: {
                  user_id: compUser.user.id,
                  company_id: companyId
                }
              })
            }
          >
            Leave
          </FormButton>
        )}
        {compUser.pending &&
          (currUserObj.role === ADMIN ||
            currUserObj.role === CS ||
            currUserObj.role === SALES ||
            compUser.user.id === currUserObj.id) && (
            <FormButton
              onClick={() => handleResend(compUser.user.id)}
              style={{ marginTop: '10px' }}
            >
              Resend Invite
            </FormButton>
          )}
      </Actions>
    </ProfileCard>
  );
};
const Team = () => {
  const { companyId } = useParams();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [open, setOpen] = useState(false);
  const company = Session.getCompany(companyId) || {};
  const currUserObj = Session.getUser();
  const [companyUsers, setCompanyUsers] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [getCompanyUsers, { loading }] = useLazyQuery(GET_USERS_FOR_COMPANY, {
    fetchPolicy: 'no-cache',
    variables: { company_id: company.id },
    onCompleted: ({ getUsersForCompany }) => {
      setCompanyUsers(
        getUsersForCompany.sort((a, b) =>
          a.user.first_name.localeCompare(b.user.first_name)
        )
      );
      prepareCalendars(getUsersForCompany);
    },
    onError: error => console.log(error)
  });
  const [addTeamMember, { }] = useMutation(ADD_TEAM_MEMBER, {
    onCompleted: ({ addTeamMember }) => {
      setOpen(false);
      getCompanyUsers();
    }
  });
  const [removeTeamMember, { }] = useMutation(REMOVE_TEAM_MEMBER, {
    onCompleted: data => getCompanyUsers()
  });
  const [resendInvite, { }] = useMutation(RESEND_INVITE, {
    onCompleted: ({ resendActivationEmail }) => {
      console.log(resendActivationEmail);
    }
  });
  const prepareCalendars = companyUsers => {
    if (companyUsers) {
      setCalendars(
        companyUsers.filter(({ user }) => user.role !== CLIENT && user.calendar)
      );
    }
  };
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };
  const handleResend = id => {
    resendInvite({
      variables: {
        user_id: id
      }
    });
  };
  useEffect(() => {
    getCompanyUsers();
  }, []);
  const currUser = companyUsers.filter(u => u.user.id === currUserObj.id);
  const is_admin = currUser[0] ? currUser[0].is_admin : false;
  const numOfUsers = companyUsers.length;
  console.log(companyUsers);
  return (
    <>
      {/* <StyledAppBar position={'fixed'} className={'flex-row'}>
        <Toolbar className={'flex'}>
          <Title className='flex'>Your Back Office</Title>
          <MainContact>
            <ContactIcon /> <a href={'tel:1-800-711-3307'}>1-800-711-3307</a>
          </MainContact>
        </Toolbar>
      </StyledAppBar> */}

      <PageHeader>
        <h1>Team</h1>
      </PageHeader>
      <Page>
        <Grid container spacing={4}>
          <Grid item xs={8}>
            <ContentCardShadowed>
              <CardContent>
                {currUserObj &&
                  currUserObj.role !== ASSOCIATE &&
                  (currUserObj.role === ADMIN ||
                    currUserObj.role === CS ||
                    currUserObj.role === SALES ||
                    is_admin) && (
                    <div style={{ textAlign: 'end' }}>
                      <FormButton
                        variant='outlined'
                        onClick={() => setOpen(true)}
                      >
                        Invite A Team Member
                      </FormButton>
                    </div>
                  )}
                {companyUsers &&
                  companyUsers.map(
                    compUser =>
                      compUser.user.role === CLIENT &&
                      ProfileBox(
                        compUser,
                        currUserObj,
                        is_admin,
                        companyId,
                        numOfUsers,
                        removeTeamMember,
                        company,
                        handleResend
                      )
                  )}
                {companyUsers &&
                  companyUsers.map(
                    compUser =>
                      compUser.user.role !== CLIENT &&
                      ProfileBox(
                        compUser,
                        currUserObj,
                        is_admin,
                        companyId,
                        numOfUsers,
                        removeTeamMember,
                        company
                      )
                  )}
              </CardContent>
            </ContentCardShadowed>
          </Grid>
          {/* <Grid item xs={4} >
            <ContentCardShadowed>
              <CardHeader title='Contact Us' />
              <CardContent>
                <DisplayList>
                  {calendars &&
                    calendars.length > 0 &&
                    calendars.map(({ user }) => (
                      <ListItem
                        button
                        onClick={() => window.open(user.calendar, '_blank')}
                      >
                        <ListItemIcon>
                          <EventAvailableIcon fontSize={'large'} />
                        </ListItemIcon>
                        <ListItemText
                          primary={`Book a meeting with ${user.first_name}`}
                        />
                      </ListItem>
                    ))}
                  <ListItem
                    style={{ cursor: 'pointer' }}
                    button
                    onClick={() =>
                      window.open(
                        `mailto:${configs.app.support}?subject=Support Request for ${company.name
                        }${company.customer_num ? '/' + company.customer_num : ''}`
                      )
                    }
                  >
                    <ListItemIcon>
                      <EmailIcon fontSize={'large'} />
                    </ListItemIcon>
                    <ListItemText primary='Email Support' />
                  </ListItem>
                </DisplayList>
              </CardContent>
            </ContentCardShadowed>
          </Grid> */}
        </Grid>
      </Page>
      {open ? (
        <AddUserDrawer
          open={open}
          setOpen={setOpen}
          addTeamMember={addTeamMember}
        />
      ) : null}
    </>
  );
};
export default Team;
