import React, { useState, useEffect } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import useWindowDimensions from 'components/reusables/WindowSize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RightDrawer,
  DrawerCard,
  FormButton,
  FormTextField,
  DrawerCardActions,
  DrawerCloseButton
} from 'components/reusables/Contents';
import { Form, Formik } from 'formik';
const FilterDrawer = ({
  openDrawer,
  setOpenDrawer,
  setFilter,
  search,
  filter,
  handleApplyFilters,
  defaultFilters,
  data
}) => {
  const [pickerRef, setPickerRef] = useState(null);
  const { width, height } = useWindowDimensions();
  const handleFilterClear = () => {
    setFilter(defaultFilters);
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const initValues = () => {
    return {
      search: filter.search || search || '',
      showRemoved: filter.showRemoved
    };
  };
  const resetValues = {
    search: defaultFilters.filename,
    showRemoved: defaultFilters.showRemoved
  };
  const filterOptions = () => {
    console.log('### Filter form for clients', filter);
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={(values, { setSubmitting }) => {
          console.log("##### values", values);
          handleApplyFilters(values);
          setOpenDrawer(false);
        }}
      >
        {props => {
          const {
            values,
            handleChange,
            handleSubmit,
            setValues,
            setFieldValue
          } = props;
          const handleFormClearing = () => {
            handleFilterClear();
            setValues(resetValues, false);
          };
          const handleChecked = value => {
            setFieldValue('showRemoved', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete={'nope'}>
              <DrawerCard className={''} style={{ height: `${height}px` }}>
                <CardHeader
                  title={'Filter Clients'}
                  action={
                    <DrawerCloseButton
                      aria-label='Close'
                      onClick={() => handleClose()}
                    />
                  }
                />
                <CardContent>
                  <FormTextField
                    error
                    margin='dense'
                    name='search'
                    label='Search'
                    placeholder='Search'
                    value={values.search}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='showRemoved'
                        checked={values.showRemoved}
                        onChange={() => handleChecked(!values.showRemoved)}
                      />
                    }
                    label='Show Inactive Clients'
                  />
                </CardContent>
                <DrawerCardActions>
                  <FormButton color='primary' type='submit' size={'medium'}>
                    Apply Filter
                  </FormButton>
                  <FormButton onClick={handleFormClearing}>Reset</FormButton>
                </DrawerCardActions>
              </DrawerCard>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        width={400}
        variant='temporary'
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {filterOptions()}
      </RightDrawer>
    </div>
  );
};
export default FilterDrawer;
