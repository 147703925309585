import React, { useRef, createRef} from 'react';
import { useParams } from 'react-router-dom';
import configs from 'config';
import Session from 'utils/session';
import { PageHeader, Page } from '@tycoonlabs/web-components';
import DISCLAIMER from "./disclaimer";
import EMAILS from "./templates";
import Clipboard from "./Clipboard";
import Grid from '@material-ui/core/Grid';

const Communication = () => {
	const { companyId } = useParams();
	const company = Session.getCompany(companyId);
	const user = Session.getUser();
	let dashboardLink = configs.web.URI + "/bookkeeping/" + companyId;
	let data = {
		company: company,
		user: user,
		disclaimer: DISCLAIMER,
		dashboardLink: dashboardLink
	};
	const inputRef = useRef(EMAILS.map(() => createRef()));
	return (
		<>
			<PageHeader>
				<h1>Templates</h1>
			</PageHeader>
			<Page>
				<h3>Email Templates</h3>
				<p>For communication with clients, find the corresponding email to copy and paste the email to client.</p>
				<Grid container spacing={0} >
					<Grid item xs={12} sm={10} md={8}>
						<form>

							{EMAILS.map((email, index) => (
								<Clipboard
									ref={inputRef.current[index]}
									email={email}
									key={index}
									id={index}
									data={data}
								/>
							))}
						</form>
					</Grid>
				</Grid>
			</Page>
		</>
	);
};
export default Communication;
