import React, { useMemo } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import Paginator from 'components/reusables/Paginator';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { DataTableStyles } from 'components/reusables/styles';
import { ContentEmpty } from 'components/reusables/Contents';

const StatementsTable = ({
  files,
  loading,
  handleDownload,
  handleRowClick,
  count,
  size,
  setSize,
  page,
  setPage,
  sort,
  setSort,
  direction,
  setDirection
}) => {
  const columns = useMemo(
    () => [
      {
        name: 'DATE UPLOADED',
        selector: 'date',
        sortable: true,
        cell: row => {
          return `${moment(row.date).format('lll')}`;
        },
        grow: 2
      },
      {
        name: 'ACCOUNT NAME',
        selector: 'account_name',
        sortable: true,
        grow: 2
      },
      {
        name: 'FILENAME',
        selector: 'filename',
        sortable: true,
        grow: 2
      },
      {
        name: 'SOURCE',
        selector: 'source',
        sortable: true,
        grow: 1
      },
      {
        name: 'TYPE',
        selector: 'filetype',
        sortable: true,
        grow: 1,
        cell: row => {
          return row.filetype && row.filetype.name;
        }
      },
      {
        name: 'TAG',
        selector: 'filetag',
        sortable: true,
        grow: 1,
        cell: row => {
          return row.filetag && row.filetag.name;
        }
      },
      {
        name: 'DESCRIPTION',
        selector: 'description',
        sortable: true,
        grow: 4
      },
      {
        name: '',
        sortable: false,
        grow: 1,
        right: true,
        cell: row => {
          return (
            <IconButton onClick={() => handleDownload(row.id)}>
              <DownloadIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          );
        }
      }
    ],
    []
  );

  const handlePageChange = p => {
    setPage(p);
  };
  const handlePerRowsChange = (perPage, page) => {
    setSize(perPage);
    setPage(page);
  };
  const handleSort = (sort, direction) => {
    setSort(sort.selector);
    setDirection(direction);
  };
  return (
    <>
      <div className='data-table'>
        {files.length > 0 && (
          <DataTable
            columns={columns}
            data={files}
            defaultSortAsc={false}
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            defaultSortField={'date'}
            defaultSortAsc={false}
            onSort={handleSort}
            sortServer={true}
            paginationPerPage={size}
            paginationRowsPerPageOptions={[10, 15, 25, 50]}
            noHeader
            onRowClicked={handleRowClick}
            highlightOnHover
            pointerOnHover={true}
            customStyles={DataTableStyles}
          />
        )}
      </div>
      {!loading && !files.length && (
        <ContentEmpty>No file found. Please adjust your filter.</ContentEmpty>
      )}
    </>
  );
};
export default StatementsTable;
