import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {
  DrawerCard,
  FormButton,
  FormTextField,
  DrawerCardContent,
  DrawerCardActions
} from 'components/reusables/Contents';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const SettingsForm = ({
  selectedAccount,
  setSelectedAccount,
  setSelectedVendor,
  selectedVendor,
  openDrawer,
  accountTypes,
  editAccount,
  editVendor,
  initialAccountState,
  initialVendorState,
  drawer
}) => {
  const [currentAcc, setCurrentAcc] = useState([]);
  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    const val = type === 'checkbox' ? checked : value;
    drawer === 'vendors'
      ? setSelectedVendor({ ...selectedVendor, [name]: val })
      : setSelectedAccount({ ...selectedAccount, [name]: val });
  };

  const handleEditSubmit = e => {
    e.preventDefault();
    if (drawer === 'accounts' || drawer === 'categories') {
      editAccount();
    } else if (drawer == 'vendors') {
      editVendor();
    }
  };

  const handleReset = () => {
    drawer === 'vendors'
      ? setSelectedVendor(initialVendorState)
      : setSelectedAccount(initialAccountState);
  };
  useEffect(() => {
    if (accountTypes) {
      setCurrentAcc(
        accountTypes.filter(acc => acc.account === selectedAccount.account_type)
      );
    }
  }, [selectedAccount]);
  const accountForm = () => (
    <>
      <FormTextField
        error
        name='name'
        label='Name'
        placeholder='Enter Account Name'
        value={selectedAccount.name}
        onChange={handleChange}
      />
      <div>
        <FormTextField
          error
          name='description'
          label='Description'
          placeholder='Enter Account Description'
          value={selectedAccount.description ? selectedAccount.description : ''}
          onChange={handleChange}
        />
      </div>
      <div>
        <FormTextField
          // width='80%'
          error
          select
          label='Account Type'
          name='account_type'
          value={selectedAccount.account_type}
          onChange={handleChange}
        >
          {accountTypes.map(account => (
            <MenuItem key={account.account} value={account.account}>
              {account.account}
            </MenuItem>
          ))}
        </FormTextField>
      </div>
      <div>
        <FormTextField
          // width='80%'
          error
          select
          label='Account Detail'
          name='account_subtype'
          value={selectedAccount.account_subtype}
          onChange={handleChange}
        >
          {currentAcc.length ? (
            currentAcc[0].subAccounts.map(subAcc =>
              subAcc.value ? (
                <MenuItem key={subAcc.value} value={subAcc.value}>
                  {subAcc.name}
                </MenuItem>
              ) : null
            )
          ) : (
            <MenuItem value=''>Please select an Account Type</MenuItem>
          )}
        </FormTextField>
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name='active'
              checked={selectedAccount.active}
              onChange={handleChange}
            />
          }
          label='Active'
        />
      </div>
    </>
  );

  const vendorForm = () => (
    <>
      <div>
        <FormTextField
          error
          name='display_name'
          label='Display Name'
          placeholder='Enter Vendor Display Name'
          value={selectedVendor.display_name}
          onChange={handleChange}
        />
      </div>
      <div>
        <FormTextField
          error
          name='print_name'
          label='Print Name'
          placeholder='Enter Vendor Print Name'
          value={selectedVendor.print_name}
          onChange={handleChange}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              name='active'
              checked={selectedVendor.active}
              onChange={handleChange}
            />
          }
          label='Active'
        />
      </div>
    </>
  );

  const contentForm = () =>
    drawer === 'vendors' ? vendorForm() : accountForm();

  return (
    <DrawerCardContent>
      <form style={{ width: '100%' }} onSubmit={handleEditSubmit}>
        {contentForm()}
        <DrawerCardActions style={{ justifyContent: 'space-between' }}>
          <FormButton color='primary' type='submit' size={'medium'}>
            Submit
          </FormButton>
          <FormButton onClick={handleReset}>Reset</FormButton>
        </DrawerCardActions>
      </form>
    </DrawerCardContent>
  );
};

export default SettingsForm;
