/* eslint-disable prefer-template */
/* eslint-disable no-useless-escape */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const exportTransactionsToCSV = (arr, accountName) => {
  let CSVData = [['Row', 'Account', 'Date', 'Description', 'Amount']];
  arr.forEach(element =>
    CSVData.push([
      accountName,
      element.date,
      element.name.replace(/[^\w\.]/gi, '%20'),
      element.amount
    ])
  );
  CSVData = CSVData.map(item => item.join(',')).join('%0A');
  const a = document.createElement('a');
  a.href = 'data:atachment/csv' + CSVData;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.download = 'account_transactions.csv';
  document.body.appendChild(a);
  a.click();
};

export const exportBalancesToCSV = (arr, accountName) => {
  let CSVData = [['Row', 'Account', 'Date', 'Balance', 'Last Updated']];
  arr.forEach(element =>
    CSVData.push([
      accountName,
      element.date,
      element.balance,
      element.timestamp.substring(0, 10)
    ])
  );
  CSVData = CSVData.map(item => item.join(',')).join('%0A');
  const a = document.createElement('a');
  a.href = 'data:atachment/csv' + CSVData;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.download = 'account_daily_balance.csv';
  document.body.appendChild(a);
  a.click();
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


export const exportTransactions = arr => {
  let CSVData = [
    ['Row', 'Account', 'Date', 'Description', 'Category', 'Payee', 'Amount']
  ];
  arr.forEach(element => {
    const dIndex = element.description.indexOf(':');
    const description = element.description.substring(dIndex + 1);
    CSVData.push([
      element.account_name,
      element.date,
      description.replace(/[^\w\.]/gi, '%20'),
      element.category_name,
      element.vendor_name,
      element.amount
    ]);
  });
  CSVData = CSVData.map(item => item.join(',')).join('%0A');
  const a = document.createElement('a');
  a.href = 'data:atachment/csv' + CSVData;
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.download = 'account_transactions.csv';
  document.body.appendChild(a);
  a.click();
};
