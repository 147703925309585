import React, { useState, useEffect } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DatePicker from 'components/reusables/DatePicker';
import moment from 'moment';
import useWindowDimensions from 'components/reusables/WindowSize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RightDrawer,
  DrawerCard,
  FormButton,
  FormTextField,
  DrawerCardActions,
  DrawerCloseButton,
  FormMenuItem
} from 'components/reusables/Contents';
import { Form, Formik } from 'formik';
const FilterDrawer = ({
  openDrawer,
  setOpenDrawer,
  setFilter,
  search,
  filter,
  handleApplyFilters,
  defaultFilters,
  data
}) => {
  const [pickerRef, setPickerRef] = useState(null);
  const { width, height } = useWindowDimensions();
  const defaultDate = {
    from: moment()
      .subtract(2, 'months')
      .toDate(),
    to: moment().toDate()
  };
  const [dateFrom, setDateFrom] = useState(defaultDate.from);
  const [dateTo, setDateTo] = useState(defaultDate.to);
  const dateRange = {
    from: dateFrom,
    to: dateTo
  };
  //date picker
  const showFromMonth = () => {
    if (!dateFrom) {
      return;
    }
    if (moment(dateTo).diff(moment(dateFrom), 'months') < 2) {
      pickerRef.getDayPicker().showMonth(dateFrom);
    }
  };
  const handleFilterClear = () => {
    setFilter(defaultFilters);
    setDateFrom(defaultDate.from);
    setDateTo(defaultDate.to);
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {
    if (filter.dateFrom && filter.dateTo) {
      setDateFrom(filter.dateFrom);
      setDateTo(filter.dateTo);
    }
  }, []);
  const initValues = () => {
    return {
      filename: filter.filename || search || '',
      type: filter.type || '',
      accountName: filter.accountName || '',
      tag: filter.tag || '',
      showArchived: filter.showArchived,
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo
    };
  };
  const resetValues = {
    filename: defaultFilters.filename,
    type: defaultFilters.type,
    accountName: defaultFilters.accountName,
    tag: defaultFilters.tag,
    showArchived: defaultFilters.showArchived,
    dateFrom: dateFrom,
    dateTo: dateTo
  };
  const filterOptions = () => {
    console.log('### Filter form for files', filter);
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={(values, { setSubmitting }) => {
          handleApplyFilters(values);
          setOpenDrawer(false);
        }}
      >
        {props => {
          const {
            values,
            handleChange,
            handleSubmit,
            setValues,
            setFieldValue
          } = props;
          const handleFormClearing = () => {
            handleFilterClear();
            setValues(resetValues, false);
          };
          const handleChecked = value => {
            setFieldValue('showArchived', value);
          };
          const handleFromChange = value => {
            setFieldValue('dateFrom', value);
            setDateFrom(value);
          };
          const handleToChange = value => {
            setFieldValue('dateTo', value);
            setDateTo(value);
            showFromMonth();
          };
          const handleFromTodayButton = value => {
            setFieldValue('dateFrom', value);
            setDateFrom(value);
            pickerRef.getInput().focus();
          };
          const handleToTodayButton = value => {
            setFieldValue('dateTo', value);
            setDateTo(value);
            pickerRef.hideDayPicker();
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete={'nope'}>
              <DrawerCard className={''} style={{ height: `${height}px` }}>
                <CardHeader
                  title={'Filter Files'}
                  action={
                    <DrawerCloseButton
                      aria-label='Close'
                      onClick={() => handleClose()}
                    />
                  }
                />
                <CardContent>
                  <DatePicker
                    dateRange={dateRange}
                    pickerRef={pickerRef}
                    setPickerRef={setPickerRef}
                    showFromMonth={showFromMonth}
                    handleFromChange={handleFromChange}
                    handleToChange={handleToChange}
                    handleFromTodayButton={handleFromTodayButton}
                    handleToTodayButton={handleToTodayButton}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    name='filename'
                    label='File name'
                    placeholder='Enter file name'
                    value={values.filename}
                    onChange={handleChange}
                  />
                  <FormTextField
                    width='80%'
                    error
                    select
                    SelectProps={{ displayEmpty: true }}
                    margin='dense'
                    label='Chart of Account'
                    name='accountName'
                    value={values.accountName}
                    onChange={handleChange}
                  >
                    <FormMenuItem value=''>
                      <em>Select an account</em>
                    </FormMenuItem>
                    {data.accounts.map(account => (
                      <FormMenuItem key={account.id} value={account.name}>
                        {account.name}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                  <FormTextField
                    width='80%'
                    error
                    select
                    SelectProps={{ displayEmpty: true }}
                    margin='dense'
                    label='Type'
                    name='type'
                    value={values.type}
                    onChange={handleChange}
                  >
                    <FormMenuItem value=''>
                      <em>Select a type</em>
                    </FormMenuItem>
                    {data.types.map(type => (
                      <FormMenuItem key={type.id} value={type.id}>
                        {type.name}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                  <FormTextField
                    width='80%'
                    error
                    select
                    SelectProps={{ displayEmpty: true }}
                    margin='dense'
                    label='Tag'
                    name='tag'
                    value={values.tag}
                    onChange={handleChange}
                  >
                    <FormMenuItem value=''>
                      <em>Select a tag</em>
                    </FormMenuItem>
                    {data.tags.map(tag => (
                      <FormMenuItem key={tag.id} value={tag.id}>
                        {tag.name}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='showArchived'
                        checked={values.showArchived}
                        onChange={() => handleChecked(!values.showArchived)}
                      />
                    }
                    label='Show Archived Files'
                  />
                </CardContent>
                <DrawerCardActions>
                  <FormButton color='primary' type='submit' size={'medium'}>
                    Apply Filter
                  </FormButton>
                  <FormButton onClick={handleFormClearing}>Reset</FormButton>
                </DrawerCardActions>
              </DrawerCard>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        width={400}
        variant='temporary'
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {filterOptions()}
      </RightDrawer>
    </div>
  );
};
export default FilterDrawer;
