import { toast } from 'react-toastify';

import {
  ERROR_MESSAGE,
  GET_USER,
  SET_USER,
  RESET_USER,
  SHOW_MODAL,
  HIDE_MODAL,
  EXCLUDE_PLAID_ACCOUNT,
  GET_COMPANIES,
  ADD_PLAID_ACCOUNT,
  UPLOAD_DOCUMENT,
  SET_COMPANY,
  RESIZE,
  ADD_COMPANIES,
  EDIT_USER,
  LOGOUT_USER,
  UPDATE_CLIENT_PROFILE
} from './actionTypes';

export const setUser = user => {
  return dispatch => {
    dispatch({ type: SET_USER, user });
  };
};

export const logoutUser = () => {
  return dispatch => {
    dispatch({ type: LOGOUT_USER });
  };
};

export const editUser = user => {
  return dispatch => {
    dispatch({ type: EDIT_USER, user });
  };
};

export const getUser = user => {
  return dispatch => {
    dispatch({ type: GET_USER, user });
  };
};

export const resetUser = user => {
  return dispatch => {
    dispatch({ type: RESET_USER, user });
  };
};

export const plaidError = error => {
  return dispatch => {
    dispatch({
      type: ERROR_MESSAGE,
      error
    });
    toast.warn(error, {
      autoClose: false
    });
  };
};

export const showModal = () => {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL
    });
  };
};

export const hideModal = () => {
  return dispatch => {
    dispatch({
      type: HIDE_MODAL
    });
  };
};

export const excludePlaidAccount = (companyIndex, accountIndex, excluded) => {
  return dispatch => {
    dispatch({
      type: EXCLUDE_PLAID_ACCOUNT,
      companyIndex,
      accountIndex,
      excluded
    });
    excluded
      ? toast.success('Account was successfully excluded.')
      : toast.success('Account was successfully reincluded.');
  };
};

export const getCompanies = companies => {
  return dispatch => {
    dispatch({
      type: GET_COMPANIES,
      companies
    });
  };
};

export const plaidAddAccount = (companyIndex, accounts) => {
  return dispatch => {
    dispatch({
      type: ADD_PLAID_ACCOUNT,
      companyIndex,
      accounts
    });
    toast.success('Account was successfully linked.');
  };
};

export const RDX_uploadDocument = (companyIndex, documents) => {
  return dispatch => {
    dispatch({
      type: UPLOAD_DOCUMENT,
      companyIndex,
      documents
    });
    toast.success(`${documents.filename} was successfully uploaded.`);
  };
};

export const setCompany = company => {
  return dispatch => {
    dispatch({
      type: SET_COMPANY,
      company
    });
  };
};

export const resizeWindow = resize => {
  return dispatch => {
    dispatch({
      type: RESIZE,
      resize
    });
  };
};

export const addCompanies = companies => {
  return dispatch => {
    dispatch({
      type: ADD_COMPANIES,
      companies
    });
  };
};


export const updateClientProfile = (companyIndex, profile) => {
  return dispatch => {
    dispatch({
      type: UPDATE_CLIENT_PROFILE,
      companyIndex,
      profile
    });
  };
};
