import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Paginator from 'components/reusables/Paginator';
import { ReactComponent as MatchedIcon } from 'assets/matched.svg';
import {DataTableStyles} from "components/reusables/styles";

const VendorsTable = ({ vendors, handleRowClick }) => {
  const tableColumns = useMemo(() => [
    {
      name: 'DISPLAY NAME',
      selector: 'display_name',
      sortable: true,
      wrap: true
    },
    {
      name: 'PRINT NAME',
      selector: 'print_name',
      sortable: true,
      wrap: true
    },
    {
      name: 'ACTIVE',
      selector: 'active',
      sortable: true,
      center: true,
      cell: row => {
        return row.active ? <MatchedIcon /> : '';
      }
    }
  ]);

  return (
    <>
      <div className='transactions-table' style={{ height: '100%' }}>
        {vendors.length && (
          <DataTable
            columns={tableColumns}
            data={vendors}
            defaultSortField='name'
            defaultSortAsc={true}
            pagination={vendors && vendors.length > 25}
            // paginationComponent={Paginator}
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            highlightOnHover
            onRowClicked={handleRowClick}
            noHeader
						customStyles={DataTableStyles}
          />
        )}
      </div>
    </>
  );
};

export default VendorsTable;
