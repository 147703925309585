import { gql } from 'apollo-boost';

export const FILTER_TRANSACTIONS = gql`
  query(
    $company_id: ID!
    $page: Int
    $size: Int
    $sort: String
    $direction: String
    $filter: TransactionFilterType
  ) {
    filterTransactions(
      company_id: $company_id
      page: $page
      size: $size
      sort: $sort
      direction: $direction
      filter_options: $filter
    ) {
      page
      pages
      count
      has_next
      has_prev
      objects {
        id
        qb_id
        status
        account_name
        account_id
        date
        description
        category_name
        category_id
        vendor_name
        vendor_id
        payment_type
        amount
        pushed
        created
        source
        excluded
      }
    }
  }
`;
