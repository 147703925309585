import React, { useMemo } from 'react';
import { Img } from "react-image";
import configs from 'config';

import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import AccountsIcon from '@material-ui/icons/AccountBalanceOutlined';
import StatementsIcon from '@material-ui/icons/LocalAtmOutlined';
import ReportsIcon from '@material-ui/icons/AssessmentOutlined';
import TeamIcon from '@material-ui/icons/GroupOutlined';
import DocumentsIcon from '@material-ui/icons/DescriptionOutlined';
import CommunicationIcon from '@material-ui/icons/ForumOutlined';
import TransactionsIcon from "@material-ui/icons/ReceiptOutlined";
import InternalIcon from "@material-ui/icons/FolderSpecialOutlined";
import IntegrationsIcon from "@material-ui/icons/AccountTreeOutlined";
import UpdatesIcon from '@material-ui/icons/AssignmentIndOutlined';


import { ADMIN, CS, ACCOUNTANT, ASSOCIATE, PA, SALES } from 'utils/roles';
import { BusinessWidget } from './BusinessWidget';
import { AccountWidget } from './AccountWidget';
import Session from '../../utils/session';
const Navigation = () => {
	const role = Session.getRole();
	const user = Session.getUser();
	const companyId = Session.getCompanyId();
	const company = Session.getCurrentBusiness();


	const NavLink = (label, Icon, path) => {
		return {
			text: label,
			icon: <Icon style={{ color: configs.styles.navicon, fontSize: "24px" }} />,
			path: path
		}
	}
	const DASHBOARD = NavLink("Dashboard", DashboardIcon, `/dashboard/${companyId}`);
	const ACCOUNTS = NavLink("Accounts", AccountsIcon, `/accounts/${companyId}`);
	const STATEMENTS = NavLink("Statements", StatementsIcon, `/statements/${companyId}`);
	const DOCUMENTS = NavLink("Documents", DocumentsIcon, `/documents/${companyId}`);
	const REPORTS = NavLink("Reports", ReportsIcon, `/reports/${companyId}`);
	const TEAM = NavLink("Team", TeamIcon, `/team/${companyId}`);
	const TRANSACTION = NavLink("Transactions", TransactionsIcon, `/transactions/${companyId}`);
	const INTERNAL = NavLink("Internal", InternalIcon, `/internal/${companyId}`);
	const TEMPLATES = NavLink("Templates", CommunicationIcon, `/templates/${companyId}`);
	const UPDATES = NavLink("Updates", UpdatesIcon, `/updates/${companyId}`);
	const INTEGRATIONS = NavLink("Integrations", IntegrationsIcon, `/integrations/${companyId}`);

	const { primaryNavs, account } = useMemo(() => {
		let navs = [];
		if (companyId && company) {
			if (role !== SALES) {
				navs.push(DASHBOARD);
				navs.push(ACCOUNTS);
				// navs.push(STATEMENTS);
				navs.push(DOCUMENTS);
				navs.push(REPORTS);
			}
			navs.push(TEAM);
			switch (role) {
				case PA:
					navs.push(INTEGRATIONS);
					break;
				case ASSOCIATE:
					navs.push(INTERNAL);
					break;
				case ACCOUNTANT:
				case CS:
				case ADMIN:
					navs.push(TRANSACTION)
					navs.push(INTERNAL)
					navs.push(TEMPLATES)
					navs.push(UPDATES)
					navs.push(INTEGRATIONS)
					break;
			}
		}
		return { primaryNavs: navs, account: <AccountWidget /> };
	}, [user]);

	const business = useMemo(() => {
		return <BusinessWidget />
	}, [company]);

	const companyName = "Back Office";
	const appIcon = <Img src={configs.app.appIcon} />;
	const footer = (<Img src={configs.app.footer} style={{ maxWidth: "145px" }} />);
	const footerExpand = (<Img src={configs.app.navCaret} />);



	return { primaryNavs, business, companyName, account, appIcon, footer, footerExpand };
};
export default Navigation;
