import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Paginator from 'components/reusables/Paginator';
import { ReactComponent as MatchedIcon } from 'assets/matched.svg';
import {DataTableStyles} from "components/reusables/styles";

const AccountsTable = ({ accounts, handleRowClick }) => {
  const tableColumns = useMemo(() => [
    {
      name: 'NAME',
      selector: 'name',
      sortable: true,
      wrap: true
    },
    {
      name: 'DESCRIPTION',
      selector: 'description',
      sortable: true,
      wrap: true
    },
    {
      name: 'ACCOUNT TYPE',
      selector: 'account_type',
      sortable: true,
      wrap: true
    },
    {
      name: 'DETAIL TYPE',
      selector: 'account_subtype',
      sortable: true,
      wrap: true
    },
    {
      name: 'CLASSIFICATION',
      selector: 'classification',
      sortable: true,
      wrap: true
    },
    {
      name: 'BALANCE',
      selector: 'current_balance',
      sortable: true,
      wrap: true
    },
    {
      name: 'ACTIVE',
      selector: 'active',
      sortable: true,
      center: true,
      cell: row => {
        return row.active ? <MatchedIcon /> : '';
      }
    }
  ]);

  return (
    <>
        {accounts.length && (
          <DataTable
            columns={tableColumns}
            data={accounts}
            defaultSortField='name'
            defaultSortAsc={true}
            pagination={accounts && accounts.length > 25}
            // paginationComponent={Paginator}
            paginationPerPage={25}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            highlightOnHover
            onRowClicked={handleRowClick}
            noHeader
						customStyles={DataTableStyles}
          />
        )}
    </>
  );
};

export default AccountsTable;
