import { gql } from 'apollo-boost';
import { COMPANY_DETAIL, COMPANY_INPUT, USER_DETAIL } from './model';

export const LOGIN_MUTATION = gql`
  mutation($email: String!, $password: String!) {
    token_auth(email: $email, password: $password) {
      token
      user {
      	${USER_DETAIL}
      }
      company {
      	${COMPANY_DETAIL}
      }
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation($token: String!) {
    verify_token(token: $token) {
      payload
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation($token: String!) {
    refresh_token(token: $token) {
      payload
      token
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation(
    $email: String!
    $first_name: String!
    $last_name: String!
    $companies: [CompanyInput]
  ) {
    createUser(
      createuserinput: {
        first_name: $first_name
        last_name: $last_name
        email: $email
      }
      companies: $companies
    ) {
      id
      first_name
      last_name
      email
      companies {
        id
        name
        customer_num
        last_rec
        last_touched
        last_upload
        removed
        disable_overlay
        created_at
        modified_at
        plaid_accounts {
          id
          account_id
          institution_name
          institution_id
          name
          mask
          type
          subtype
          excluded
          created_at
          modified_at
        }
        documents {
          id
          filename
          handle
          mimetype
          size
          source
          status
          uploaded_at
          url
        }
      }
    }
  }
`;

export const EDIT_USER_ON_REGISTER = gql`
  mutation(
    $user_id: ID!
    $first_name: String!
    $last_name: String!
    $email: String!
    $modified_by_id: ID!
    $companies: [CompanyInput]
  ) {
    editUserRegistration(
      user_id: $user_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      modified_by_id: $modified_by_id
      companies: $companies
    ) {
      id
      first_name
      last_name
      email
      companies {
        id
        name
        customer_num
        last_rec
        last_touched
        last_upload
        removed
        disable_overlay
        created_at
        modified_at
        plaid_accounts {
          id
          account_id
          institution_name
          institution_id
          name
          mask
          type
          subtype
          excluded
          created_at
          modified_at
        }
        documents {
          id
          filename
          handle
          mimetype
          size
          source
          status
          uploaded_at
          url
        }
      }
    }
  }
`;

export const EDIT_USER = gql`
  mutation($user_id: ID!, $userinput: UserInput!, $modified_by_id: ID!) {
    editUser(
      user_id: $user_id
      userinput: $userinput
      modified_by_id: $modified_by_id
    ) {
      id
      profile_image_url
      first_name
      last_name
      email
      phone
      address
      address_two
      city
      state
      postal_code
      country
    }
  }
`;

export const ADD_TEAM_MEMBER = gql`
  mutation(
    $company_id: ID!
    $first_name: String!
    $last_name: String!
    $email: String!
    $admin: Boolean!
  ) {
    addTeamMember(
      company_id: $company_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      admin: $admin
    ) {
      success
    }
  }
`;

export const REMOVE_TEAM_MEMBER = gql`
  mutation($company_id: ID!, $user_id: ID!) {
    removeTeamMember(company_id: $company_id, user_id: $user_id) {
      success
    }
  }
`;
export const ADD_PLAID_ACCOUNT = gql`
  mutation($companyId: ID!, $public_token: String!, $metadata: JSONString!) {
    addPlaidAccount(
      company_id: $companyId
      plaidaccountinput: { public_token: $public_token, metadata: $metadata }
    ) {
      accounts
    }
  }
`;

export const SET_USER_PASSWORD = gql`
  mutation($password: String!, $user_id: ID!) {
    setUserPassword(password: $password, user_id: $user_id) {
      success
    }
  }
`;

export const MODIFY_USER_PASSWORD = gql`
  mutation(
    $newPasswordOne: String!
    $newPasswordTwo: String!
    $currentPassword: String!
    $user_id: ID!
  ) {
    modifyUserPassword(
      newPasswordOne: $newPasswordOne
      newPasswordTwo: $newPasswordTwo
      currentPassword: $currentPassword
      user_id: $user_id
    ) {
      success
    }
  }
`;

export const RESET_PASSWORD_EMAIL = gql`
  mutation($email: String!) {
    resetPasswordEmail(email: $email) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation($newPasswordOne: String!, $user_id: ID!, $token: String!) {
    resetPassword(
      newPassword: $newPasswordOne
      user_id: $user_id
      token: $token
    ) {
      success
    }
  }
`;

export const EXCLUDE_PLAID_ACCOUNT = gql`
  mutation($modified_by_id: ID!, $plaid_account_id: ID!) {
    excludePlaidAccount(
      modified_by_id: $modified_by_id
      plaid_account_id: $plaid_account_id
    ) {
      excluded
      plaid_account_id
    }
  }
`;

export const UPLOAD_FILESTACK_DOCUMENT = gql`
  mutation(
    $companyId: ID!
    $filename: String!
    $handle: String
    $mimetype: String
    $size: Int
    $source: String
    $status: String
    $uploaded_at: DateTime
    $url: String!
  ) {
    uploadDocument(
      company_id: $companyId
      documentinput: {
        filename: $filename
        handle: $handle
        mimetype: $mimetype
        size: $size
        source: $source
        status: $status
        uploaded_at: $uploaded_at
        url: $url
      }
    ) {
      id
      filename
      handle
      uploaded_at
    }
  }
`;

export const DISCONNECT_QB = gql`
  mutation($company_id: ID!) {
    revokeQuickBooksAccess(company_id: $company_id) {
      success
    }
  }
`;

export const OPEN_FILETHIS_SESSION = gql`
  mutation($companyId: ID!) {
    openFileThisSession(company_id: $companyId) {
      account_id
      access_token
    }
  }
`;

export const CLOSE_FILETHIS_SESSION = gql`
  mutation($account_id: String!, $session_id: String!) {
    closeFileThisSession(account_id: $account_id, session_id: $session_id) {
      success
    }
  }
`;


export const REAUTHENTICATE_PLAID_ACCOUNT = gql`
  mutation($account_id: ID!) {
    reauthenticateAccount(account_id: $account_id) {
      public_token
    }
  }
`;

export const EDIT_INACTIVE_STATUS = gql`
  mutation($access_token: String) {
    editPlaidInactiveStatus(access_token: $access_token) {
      success
    }
  }
`;

export const ADD_PLAID_ERROR = gql`
  mutation($company_id: ID, $metadata: JSONString) {
    addPlaidError(company_id: $company_id, metadata: $metadata) {
      success
    }
  }
`;

export const RENAME_CARD = gql`
  mutation($card_token: String!, $name: String!) {
    editCard(card_token: $card_token, name: $name) {
      success
    }
  }
`;

export const CREATE_VIRTUAL_CARD = gql`
  mutation(
    $company_id: ID!
    $name: String!
    $card_type: String!
    $user_id: ID!
  ) {
    createCard(
      company_id: $company_id
      name: $name
      card_type: $card_type
      user_id: $user_id
    ) {
      success
    }
  }
`;

export const UPDATE_BOOKKEEPING_STATUS = gql`
  mutation($input: BookkeepingUpdateInput!) {
    updateBookkeepingStatus(input: $input) {
      id
      last_rec
      last_touched
    }
  }
`;

export const ADD_USER_TO_COMPANY = gql`
  mutation($company_id: ID!, $is_default: Boolean, $user_id: ID!) {
    addUserToCompany(
      company_id: $company_id
      is_default: $is_default
      user_id: $user_id
    ) {
      company {
        id
        name
      }
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

export const REMOVE_USER_FROM_COMPANY = gql`
  mutation($company_id: ID!, $user_id: ID!) {
    removeUserFromCompany(company_id: $company_id, user_id: $user_id) {
      company
      user
    }
  }
`;

export const EDIT_COMPANY_PRODUCTS = gql`
  mutation($company_id: ID!, $enabled: Boolean!, $product: String!) {
    editCompanyProducts(
      company_id: $company_id
      enabled: $enabled
      product: $product
    ) {
      success
    }
  }
`;

export const SYNC_QB_DATA = gql`
  mutation($company_id: ID!) {
    syncQBData(company_id: $company_id) {
      transactions
      vendors
      chartOfAccounts
      categories
    }
  }
`;

export const ADD_TRANSACTION = gql`
  mutation($company_id: ID!, $transactions: [TransactionInput]!) {
    addTransactions(company_id: $company_id, transactions: $transactions) {
      success
    }
  }
`;

export const EDIT_TRANSACTION = gql`
  mutation($company_id: ID!, $transactions: [TransactionInput]!) {
    editTransaction(company_id: $company_id, transactions: $transactions) {
      success
    }
  }
`;

export const PUSH_TRANSACTIONS = gql`
  mutation($company_id: ID!, $transactions: [TransactionInput]!) {
    pushTransactions(company_id: $company_id, transactions: $transactions) {
      success
    }
  }
`;
export const CREATE_ACCOUNT = gql`
  mutation(
    $company_id: ID!
    $account_type: String!
    $account_subtype: String
    $name: String!
  ) {
    createAccount(
      company_id: $company_id
      account_type: $account_type
      account_subtype: $account_subtype
      name: $name
    ) {
      errors {
        message
        detail
        code
      }
      account {
        id
        name
        account_type
        active
        qb_id
      }
      category {
        id
        name
        qb_id
      }
    }
  }
`;

export const CREATE_VENDOR = gql`
  mutation($company_id: ID!, $name: String!) {
    createVendor(company_id: $company_id, name: $name) {
      errors {
        message
        detail
        code
      }
      vendor {
        id
        display_name
        qb_id
      }
    }
  }
`;

export const TEST_QB_CONNECTION = gql`
  mutation($company_id: ID!) {
    testQBConnection(company_id: $company_id) {
      success
      qb_integration {
        refresh_token_timestamp
      }
    }
  }
`;

export const EDIT_QB_ACCOUNT = gql`
  mutation(
    $company_id: ID!
    $qb_id: ID!
    $name: String
    $description: String
    $account_type: String
    $account_subtype: String
    $active: Boolean
  ) {
    editAccount(
      company_id: $company_id
      qb_id: $qb_id
      name: $name
      description: $description
      account_type: $account_type
      account_subtype: $account_subtype
      active: $active
    ) {
      errors {
        message
        detail
        code
      }
      success
    }
  }
`;

export const EDIT_QB_VENDOR = gql`
  mutation(
    $company_id: ID!
    $qb_id: ID!
    $display_name: String
    $print_name: String
    $active: Boolean
  ) {
    editVendor(
      company_id: $company_id
      qb_id: $qb_id
      display_name: $display_name
      print_name: $print_name
      active: $active
    ) {
      errors {
        message
        detail
        code
      }
      success
    }
  }
`;

export const GENERATE_PRESIGNED_URL = gql`
  mutation($company_id: ID!, $upload_input: UploadInputType!) {
    generatePresignedUrl(company_id: $company_id, upload_input: $upload_input) {
      success
      url
      obj_id
    }
  }
`;

export const EDIT_FILE = gql`
  mutation($file_id: ID!, $uploaded: Boolean, $upload_input: UploadInputType) {
    editFile(
      file_id: $file_id
      uploaded: $uploaded
      upload_input: $upload_input
    ) {
      success
    }
  }
`;

export const CREATE_TAG_OR_TYPE = gql`
  mutation($name: String!, $created_type: String!) {
    createTagOrType(name: $name, created_type: $created_type) {
      id
      name
      created_type
    }
  }
`;

export const RESEND_INVITE = gql`
  mutation($user_id: ID!) {
    resendActivationEmail(user_id: $user_id) {
      success
    }
  }
`;
