import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { PageHeader, PrimaryButton, Page } from '@tycoonlabs/web-components'
import Session from '../../utils/session';

import {
  OPEN_FILETHIS_SESSION,
  CLOSE_FILETHIS_SESSION
} from '../../graphql/mutations';

const FileThis = () => {
  const history = useHistory();
  const { companyId } = useParams();
  const [config, setConfig] = useState(null);
  const company = Session.getCompany(companyId);

  const onWizardError = e => {
    console.log(e);
  };

  const onWizardCanceledCommand = e => {
    console.log(e);
  };

  const onWizardDoneCommand = e => {
    console.log(e);
  };
  const wizard = useRef();

  const [openFileThisSession, { data }] = useMutation(OPEN_FILETHIS_SESSION, {
    variables: {
      companyId
    },
    fetchPolicy: 'no-cache',
    onCompleted: ({ openFileThisSession }) => {
      const { access_token, account_id, session_id } = openFileThisSession;
      // use account_id and session_id to close session
      setConfig({
        access_token,
        account_id,
        session_id
      });
    }
  });

  const [closeFileThisSession, { }] = useMutation(CLOSE_FILETHIS_SESSION, {
    fetchPolicy: 'no-cache',
    variables: { account_id: '', session_id: '' },
    onCompleted: ({ closeFileThisSession }) => {
      const { success } = closeFileThisSession;
    }
  });

  useEffect(() => {
    const connectWizard = document.createElement('link');
    connectWizard.rel = 'import';
    connectWizard.href =
      'https://connect.filethis.com/ft-connect-wizard/2.0.8/ft-connect-wizard.html';
    document.head.appendChild(connectWizard);
    openFileThisSession();

    return () => {
      closeFileThisSession();
    };
  }, []);

  useEffect(() => {
    if (wizard.current) {
      wizard.current.addEventListener('ft-connect-error', onWizardError);
      wizard.current.addEventListener(
        'wizard-canceled-command',
        onWizardCanceledCommand
      );
      wizard.current.addEventListener(
        'wizard-done-command',
        onWizardDoneCommand
      );
    }
    return () => {
      if (wizard.current) {
        wizard.current.removeEventListener('ft-connect-error', onWizardError);
        wizard.current.removeEventListener(
          'wizard-canceled-command',
          onWizardCanceledCommand
        );
        wizard.current.removeEventListener(
          'wizard-done-command',
          onWizardDoneCommand
        );
      }
    };
  }, [config]);

  return (
    <>
      <PageHeader>
        <h1>Statements</h1>
        <PrimaryButton size="medium" color="secondary" onClick={() => history.push(`/bookkeeping/${company.id}/filethis`)}>
          Retrieve Statements
				</PrimaryButton>
      </PageHeader>
      <Page>
        {config ? (
          <div className='filethis-container'>
            <ft-source-panel-settings ft-source-panel-heading='Please choose a bank' />
            <ft-connect-wizard
              id='wizard'
              ref={wizard}
              style={{ width: '100%', height: '100vh', maxHeight: '500px' }}
              user-account-id={config.account_id}
              token={config.access_token}
              live='true'
              debug='true'
              fake-sources='true'
            />
          </div>
        ) : (
            ''
          )}
      </Page>
    </>
  );
};

export default FileThis;
