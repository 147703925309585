import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import configs from 'config';
import { Img } from "react-image";

import { ReactComponent as BackOfficeLogo } from '../../assets/back-office.svg';
import Button from '../reusables/Button';
import Spinner from '../reusables/Spinner';

import { RESET_PASSWORD_EMAIL } from '../../graphql/mutations';

const ResetPassword = () => {
  const history = useHistory();

  const [email, setEmail] = useState('');

  const notify = () =>
    toast.success(`An email with instructions was sent to ${email}`);

  const notifyError = e => toast.error(e.message.replace('GraphQL error:', ''));

  const onError = e => {
    setEmail('');
    notifyError(e);
  };

  const onCompleted = () => {
    notify();
  };

  const [sendEmail, { loading }] = useMutation(RESET_PASSWORD_EMAIL, {
    variables: { email },
    onCompleted: () => onCompleted()
    // onError: e => onError(e)
  });

  const changeHandler = e => {
    const { value } = e.target;
    setEmail(value);
  };

  const onFormSubmit = e => {
    e.preventDefault();
    sendEmail();
  };
  const LOGO = <Img src={configs.app.logo} style={{ maxWidth: "300px" }} />;
  return (
    <main className='creds-container'>
      <div className='creds-card'>
        <div className='logo-container'>
          {LOGO}
        </div>
        <form
          onSubmit={e => onFormSubmit(e)}
          className='creds-inputs-container'
        >
          <span>Reset your password</span>
          <p className='reset-p'>
            Enter your email address below and we'll send you a link to reset
            your password.
          </p>
          <input
            value={email}
            onChange={changeHandler}
            name='email'
            type='email'
            placeholder='Your email'
            required
          />
          {loading && <Spinner />}
          {!loading && (
            <Button type='submit' classN='creds-button'>
              Send reset password email
            </Button>
          )}
        </form>
        <div className='redirect-signup'>
          <span>
            Already have an account?
            <p onClick={() => history.push('/login')}>Sign in</p>
          </span>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
