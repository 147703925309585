import { gql } from 'apollo-boost';
import {COMPANY, COMPANY_INPUT, USER_DETAIL} from './model';


export const EDIT_CLIENT = gql`
  mutation(
    $input: CompanyInput!
  ) {
    editClient(
      input: $input
    ) {
     	${COMPANY_INPUT} 
    }
  }
`;

export const SEARCH_CLIENTS = gql`
  query($search: String, $showRemoved: Boolean, $size: Int!, $page: Int!, $sort: String, $direction: String) {
    clients(
      search: $search
      showRemoved: $showRemoved
      size: $size
      page: $page
      sort: $sort
      direction: $direction
    )  {
    	page
    	pages
    	count
    	has_next
    	has_prev
    	objects {
    		${COMPANY}
    	}
    }
  }
`;
