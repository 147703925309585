import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import AddBoxOutlined from '@material-ui/icons/PlaylistAddOutlined';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import {
  DrawerContent,
  DrawerSecondaryCard,
  DrawerCard,
  FormButton,
  FormTextField,
  DrawerCardContent,
  DrawerCardActions,
  DrawerCloseButton,
  FormMenuItem
} from 'components/reusables/Contents';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from 'components/reusables/WindowSize';
import DateInput from 'components/reusables/DateInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { formatDate, parseDate } from 'react-day-picker/moment';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  EDIT_TRANSACTION,
  CREATE_ACCOUNT,
  CREATE_VENDOR,
  PUSH_TRANSACTIONS
} from 'graphql/mutations';
import { GET_QB_ACCOUNT_TYPES } from 'graphql/queries';
import { compose } from 'redux';
const drawerWidth = 600;
const accFormInitialState = {
  name: '',
  account: '',
  accountSubtype: ''
};
const useStyles = makeStyles({
  drawer: {
    width: '100%',
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  addIcon: {
    margin: '6px',
    cursor: 'pointer',
    color: '#757575'
  },
  transactionContainer: {
    width: '400px'
  },
  transactionForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  createContainer: {
    width: '300px'
  },
  createForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  listItem: {
    width: '95%',
    display: 'flex',
    justifyContent: 'space-between'
  }
});
const TransactionDrawer = ({
  openDrawer,
  setOpenDrawer,
  selectedTransaction,
  data,
  addTransaction,
  setAccounts,
  setCategories,
  setVendors,
  accounts,
  categories,
  vendors,
  accountTypes,
  fetchTransactions,
  inputVariables
}) => {
  const { companyId } = useParams();
  const classes = useStyles();
  const [transaction, setTransaction] = useState({});
  const [createForm, setCreateForm] = useState(null);
  const [accForm, setAccForm] = useState(accFormInitialState);
  const [currentAcc, setCurrentAcc] = useState([]);
  const [vendorForm, setVendorForm] = useState('');
  const [open, setOpen] = useState(false);
  const { width, height } = useWindowDimensions();
  const [editTransaction, {}] = useMutation(EDIT_TRANSACTION, {
    variables: {
      company_id: companyId,
      transactions: [transaction]
    },
    onCompleted: data =>
      fetchTransactions({
        variables: inputVariables
      })
  });
  const [pushTransactions, {}] = useMutation(PUSH_TRANSACTIONS, {
    variables: {
      company_id: companyId,
      transactions: [transaction]
    },
    onCompleted: () =>
      fetchTransactions({
        variables: inputVariables
      })
  });
  const [createAccount, {}] = useMutation(CREATE_ACCOUNT, {
    variables: {
      company_id: companyId,
      account_type: accForm.account,
      account_subtype: accForm.accountSubtype,
      name: accForm.name
    },
    onCompleted: data => {
      const { account, errors, category } = data.createAccount;
      if (!errors) {
        if (createForm === 'add_account') {
          setTransaction({
            ...transaction,
            account_name: account.name,
            account_id: account.qb_id
          });
          setAccounts([...accounts, account]);
          setCategories([...categories, category]);
        } else {
          setTransaction({
            ...transaction,
            category_name: category.name,
            category_id: category.qb_id
          });
          setCategories([...categories, category]);
        }
        handleCancel();
      }
    }
  });
  const [createVendor, {}] = useMutation(CREATE_VENDOR, {
    variables: {
      company_id: companyId,
      name: vendorForm
    },
    onCompleted: data => {
      const { vendor, errors } = data.createVendor;
      if (!errors) {
        setTransaction({
          ...transaction,
          vendor_name: vendor.display_name,
          vendor_id: vendor.qb_id
        });
        setVendors([...vendors, vendor]);
        handleCancel();
      }
    }
  });
  useEffect(() => {
    if (openDrawer) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openDrawer]);
  const date = new Date(formatDate(transaction.date));
  const paymentTypes = ['Cash', 'Check', 'CreditCard'];
  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setTransaction({ ...transaction, [name]: val });
  };
  const handleSelectChange = e => {
    const { name, value } = e.target;
    if (name === 'account_name') {
      data.accounts.forEach(account => {
        if (account.name === value) {
          return setTransaction({
            ...transaction,
            account_name: account.name,
            account_id: account.qb_id
          });
        }
      });
    } else if (name === 'category_name') {
      data.categories.forEach(category => {
        if (category.name === value) {
          return setTransaction({
            ...transaction,
            category_name: category.name,
            category_id: category.qb_id
          });
        }
      });
    } else if (name === 'vendor_name') {
      data.vendors.forEach(vendor => {
        if (vendor.display_name === value) {
          return setTransaction({
            ...transaction,
            vendor_name: vendor.display_name,
            vendor_id: vendor.qb_id
          });
        }
      });
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (selectedTransaction.id) {
      editTransaction();
    } else {
      addTransaction({
        variables: { company_id: companyId, transactions: [transaction] }
      });
    }
    handleClose();
  };
  const handleCancel = () => {
    setCreateForm(null);
    setAccForm(accFormInitialState);
    setCurrentAcc([]);
    setVendorForm('');
  };
  const handleClose = () => {
    setOpenDrawer(false);
    handleCancel();
  };
  const handleAddIcon = name => {
    console.log('####ADD ', name);
    setCreateForm(name);
  };
  const handleAccountChange = e => {
    const { name, value } = e.target;
    if (name === 'account') {
      setCurrentAcc(accountTypes.filter(acc => acc.account === value));
      setAccForm({ ...accForm, [name]: value });
    } else {
      setAccForm({ ...accForm, [name]: value });
    }
  };
  const handleVendorChange = e => {
    const { value } = e.target;
    setVendorForm(value);
  };
  const handleCreateForm = e => {
    e.preventDefault();
    if (createForm !== 'add_vendor') {
      createAccount();
    } else {
      createVendor();
    }
  };
  const handleDateChange = value => {
    const formattedDate = moment(value).format('YYYY-MM-DD');
    setTransaction({ ...transaction, date: formattedDate });
  };
  useEffect(() => {
    if (selectedTransaction) {
      setTransaction({
        id: selectedTransaction.id,
        qb_id: selectedTransaction.qb_id,
        account_name: selectedTransaction.account_name,
        account_id: selectedTransaction.account_id,
        category_name: selectedTransaction.category_name,
        category_id: selectedTransaction.category_id,
        description: selectedTransaction.description,
        vendor_name: selectedTransaction.vendor_name,
        vendor_id: selectedTransaction.vendor_id,
        amount: selectedTransaction.amount,
        payment_type: selectedTransaction.payment_type,
        date: moment(selectedTransaction.date).format('YYYY-MM-DD'),
        excluded: selectedTransaction.excluded,
        source: selectedTransaction.source
          ? selectedTransaction.source
          : 'manual'
      });
    }
  }, [selectedTransaction]);
  useEffect(() => console.log(transaction), [transaction]);

  const ListItem = account => (
    <div className={classes.listItem}>
      <div>{account.name}</div>
      <div style={{ fontStyle: 'italic', color: '#9E9E9E', fontSize: '1rem' }}>
        {account.classification} / {account.name}
      </div>
    </div>
  );

  const transactionForm = () => (
    <DrawerCardContent>
      <form onSubmit={handleSubmit} className={classes.transactionForm}>
        <DateInput
          label='Transaction Date'
          name='date'
          value={formatDate(transaction.date)}
          formatDate={formatDate}
          handleDateChange={handleDateChange}
          dayPickerProps={{
            selectedDays: date,
            initialMonth: date
          }}
        />
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={11}>
            <FormTextField
              error
              select
              label='Chart of Account'
              name='account_name'
              value={transaction.account_name}
              onChange={handleSelectChange}
            >
              <FormMenuItem value=''>Select an account</FormMenuItem>
              {data.accounts.map(account => (
                <FormMenuItem key={account.qb_id} value={account.name}>
                  {ListItem(account)}
                </FormMenuItem>
              ))}
            </FormTextField>
          </Grid>
          <Grid item xs={1}>
            <AddBoxOutlined
              fontSize={'large'}
              className={classes.addIcon}
              onClick={() => handleAddIcon('add_account')}
            />
          </Grid>
        </Grid>
        <FormTextField
          error
          name='description'
          label='Description'
          placeholder='Enter transaction description'
          value={transaction.description}
          onChange={handleChange}
          multiline
          rows={2}
        />
        <Grid container spacing={1} alignItems='center' justify='flex-end'>
          <Grid item xs={11}>
            <FormTextField
              error
              select
              name='category_name'
              label='Category'
              value={transaction.category_name}
              onChange={handleSelectChange}
            >
              <FormMenuItem value=''>Select Category</FormMenuItem>
              {data.categories.map(category => (
                <FormMenuItem key={category.qb_id} value={category.name}>
                  {ListItem(category)}
                </FormMenuItem>
              ))}
            </FormTextField>
          </Grid>
          <Grid item xs={1}>
            <AddBoxOutlined
              fontSize={'large'}
              className={classes.addIcon}
              onClick={() => handleAddIcon('add_category')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems='center' justify='flex-end'>
          <Grid item xs={11}>
            <FormTextField
              error
              select
              label='Payee'
              name='vendor_name'
              value={transaction.vendor_name}
              onChange={handleSelectChange}
            >
              <FormMenuItem value=''>Select Payee</FormMenuItem>
              {data.vendors.map(vendor => (
                <FormMenuItem key={vendor.qb_id} value={vendor.display_name}>
                  {vendor.display_name}
                </FormMenuItem>
              ))}
            </FormTextField>
          </Grid>
          <Grid item xs={1}>
            <AddBoxOutlined
              fontSize={'large'}
              className={classes.addIcon}
              onClick={() => handleAddIcon('add_vendor')}
            />
          </Grid>
        </Grid>
        <FormTextField
          width='200px'
          error
          name='amount'
          type='number'
          label='Amount'
          placeholder='Enter transaction amount'
          value={transaction.amount}
          onChange={handleChange}
        />
        <FormTextField
          error
          select
          width={'200px'}
          name='payment_type'
          label='Payment Type'
          value={transaction.payment_type}
          onChange={handleChange}
        >
          <FormMenuItem value=''>Select Payment Type</FormMenuItem>
          {paymentTypes.map(payment => (
            <FormMenuItem key={payment} value={payment}>
              {payment}
            </FormMenuItem>
          ))}
        </FormTextField>
        {selectedTransaction.id ? (
          <FormControlLabel
            control={
              <Checkbox
                name='excluded'
                checked={transaction.excluded}
                onChange={handleChange}
              />
            }
            label='Excluded'
          />
        ) : null}
        <DrawerCardActions>
          <FormButton color='primary' type='submit' size={'medium'}>
            Submit
          </FormButton>
          <FormButton onClick={handleClose}>Cancel</FormButton>
        </DrawerCardActions>
      </form>
    </DrawerCardContent>
  );
  const AccountForm = () => (
    <>
      <CardHeader
        title={
          createForm === 'add_account' ? 'Create Account' : 'Create Category'
        }
      />
      <DrawerCardContent>
        <form onSubmit={handleCreateForm}>
          <FormTextField
            error
            name='name'
            label='Name'
            placeholder={
              createForm === 'add_account'
                ? 'Enter Account Name'
                : 'Enter Category Name'
            }
            value={accForm.name}
            onChange={handleAccountChange}
          />
          <FormTextField
            error
            select
            name='account'
            label='Account Type'
            value={accForm.account}
            onChange={handleAccountChange}
          >
            {createForm === 'add_account'
              ? accountTypes.map(acc => {
                  if (acc.account === 'Bank' || acc.account === 'Credit Card') {
                    return (
                      <FormMenuItem key={acc.account} value={acc.account}>
                        {acc.account}
                      </FormMenuItem>
                    );
                  }
                })
              : accountTypes.map(acc => (
                  <FormMenuItem key={acc.account} value={acc.account}>
                    {acc.account}
                  </FormMenuItem>
                ))}
          </FormTextField>
          <FormTextField
            error
            select
            name='accountSubtype'
            label='Detail Type'
            value={accForm.accountSubtype}
            onChange={handleAccountChange}
          >
            {currentAcc.length ? (
              currentAcc[0].subAccounts.map(subAcc =>
                subAcc.value ? (
                  <FormMenuItem key={subAcc.value} value={subAcc.value}>
                    {subAcc.name}
                  </FormMenuItem>
                ) : null
              )
            ) : (
              <FormMenuItem value=''>
                Please select an Account Type
              </FormMenuItem>
            )}
          </FormTextField>
          <DrawerCardActions>
            <FormButton color='primary' type='submit' size={'medium'}>
              Create
            </FormButton>
            <FormButton onClick={handleCancel}>Cancel</FormButton>
          </DrawerCardActions>
        </form>
      </DrawerCardContent>
    </>
  );
  const VendorForm = () => (
    <>
      <CardHeader title='Create Payee' />
      <DrawerCardContent>
        <form onSubmit={handleCreateForm}>
          <FormTextField
            error
            margin='dense'
            name='name'
            label='Name'
            placeholder='Enter Payee Name'
            value={vendorForm}
            onChange={handleVendorChange}
          />
          <DrawerCardActions>
            <FormButton color='primary' type='submit' size={'medium'}>
              Create
            </FormButton>
            <FormButton onClick={handleCancel}>Cancel</FormButton>
          </DrawerCardActions>
        </form>
      </DrawerCardContent>
    </>
  );
  const fields = () => (
    <DrawerContent>
      <DrawerSecondaryCard
        style={{ width: `${createForm ? 280 : 0}px`, height: `${height}px` }}
      >
        {createForm === 'add_vendor' ? VendorForm() : AccountForm()}
      </DrawerSecondaryCard>
      <DrawerCard className={''} style={{ height: '100%' }}>
        <CardHeader
          title={
            selectedTransaction.id ? 'Edit Transaction' : 'Add Transaction'
          }
          action={
            <DrawerCloseButton aria-label='Close' onClick={handleClose} />
          }
        />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={classes.transactionContainer}>
            {transactionForm()}
          </div>
        </div>
      </DrawerCard>
    </DrawerContent>
  );
  return (
    <div>
      <Drawer
        variant='temporary'
        // classes={{
        //   paper: classes.drawerPaper
        // }}
        anchor='right'
        open={open}
        onClose={handleClose}
      >
        {fields()}
      </Drawer>
    </div>
  );
};
export default TransactionDrawer;
