/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React, {useEffect, useRef} from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {formatDate, parseDate} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import styled from 'styled-components';
import 'react-day-picker/lib/style.css';
const DateBox = styled.div`
 	display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin-top: 8px;
  margin-bottom: 2px;
  h5 {
  	font-size:11px;
		font-size:1.1rem;
		color:#282828;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
`;
const inputStyles = {
	background: "#F6F6F6",
	borderRadius: "5px",
	border: "1px solid #FFFFFF",
	fontSize: "1.4rem",
	padding: "5px",
	textAlign: "center",
	margin: "0 8px 0 0",
	width: "10rem"
};
const DatePicker = props => {
	let {
		dateRange,
		pickerRef,
		setPickerRef,
		showFromMonth,
		handleFromChange,
		handleToChange,
		handleFromTodayButton,
		handleToTodayButton
	} = props;
	setPickerRef(useRef(dateRange.to));
	useEffect(() => {
		if (pickerRef && pickerRef.current) {
			showFromMonth();
		}
	}, [dateRange]);
	const {from, to} = dateRange;
	const modifiers = {start: from, end: to};
	return (
		<DateBox>
			<h5 className="flex-row-eb items-end"><DateRangeIcon fontSize={"small"} color={"action"}/>&nbsp;&nbsp;Date Range</h5>
			<div>
				<DayPickerInput
					value={from ? `${formatDate(from)}` : ''}
					placeholder='From'
					// format='LL'
					formatDate={formatDate}
					parseDate={parseDate}
					inputProps={{style: inputStyles}}
					dayPickerProps={{
						selectedDays: [from, {from, to}],
						disabledDays: {after: to},
						toMonth: to,
						modifiers,
						numberOfMonths: 2,
						onDayClick: () => pickerRef.getInput().focus(),
						onTodayButtonClick: day => handleFromTodayButton(day),
						todayButton: 'Today',
						pagedNavigation: true,
					}}
					onDayChange={handleFromChange}
				/>{' '}
				—{' '}
				<DayPickerInput
					ref={el => {
						setPickerRef(el);
					}}
					value={to ? `${formatDate(to)}` : ''}
					inputProps={{style: inputStyles}}
					placeholder='To'
					// format='LL'
					formatDate={formatDate}
					parseDate={parseDate}
					dayPickerProps={{
						selectedDays: [from, {from, to}],
						disabledDays: {after: new Date(), before: from},
						modifiers,
						// month: from,
						// fromMonth: from,
						toMonth: to,
						numberOfMonths: 2,
						onTodayButtonClick: day => handleToTodayButton(day),
						todayButton: 'Today',
						pagedNavigation: true
					}}
					onDayChange={handleToChange}
				/>
			</div>
		</DateBox>
	);
};
export default DatePicker;
