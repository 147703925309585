import React, { useMemo } from 'react';
import moment from 'moment';
import Tippy from '@tippy.js/react';
import DataTable from 'react-data-table-component';

import Paginator from '../../reusables/Paginator';

import { ReactComponent as PostedIcon } from '../../../assets/posted.svg';
import { ReactComponent as PendingIcon } from '../../../assets/pending.svg';
import {DataTableStyles} from "../../reusables/styles";

const TransactionsTable = ({ transactions, loading }) => {
  const columns = useMemo(
    () => [
      {
        selector: 'pending',
        sortable: true,
        cell: row => {
          return row.pending ? (
            <Tippy arrow content='Pending' animation='fade' placement='right'>
              <PendingIcon />
            </Tippy>
          ) : (
            <Tippy arrow content='Posted' animation='fade' placement='right'>
              <PostedIcon />
            </Tippy>
          );
        }
      },
      {
        name: 'DATE',
        selector: 'date',
        sortable: true,
        cell: row => {
          return `${moment(row.date).format('L')}`;
        }
      },

      {
        name: 'DESCRIPTION',
        selector: 'name',
        sortable: true,
        wrap: true,
        grow: 15
      },
      {
        name: 'AMOUNT',
        selector: 'amount',
        sortable: true,
        right: true,
        grow: 1,
        cell: row => {
          return `${new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(row.amount)}`;
        }
      }
    ],
    []
  );

  return (
    <>
      <div className='transactions-table'>
        {transactions.length > 0 && (
          <DataTable
            columns={columns}
            data={transactions}
            defaultSortField='date'
            defaultSortAsc={false}
            pagination={transactions && transactions.length > 25}
            // paginationComponent={Paginator}
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            noHeader
            highlightOnHover
						customStyles={DataTableStyles}
          />
        )}
      </div>
      {!loading && !transactions.length && (
        <div className='table-empty' style={{ textAlign: 'center' }}>
          There are no records to display
        </div>
      )}
    </>
  );
};

export default TransactionsTable;
