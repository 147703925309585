import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';

import Session from '../../utils/session';
import LinkAccountOptions from './LinkAccountOptions';

import { plaidAddAccount, plaidError } from '../../actions';
import { ADD_PLAID_ACCOUNT } from '../../graphql/mutations';
import { PrimaryButton } from '@tycoonlabs/web-components'
import { ADMIN, CS, ACCOUNTANT, PA } from 'utils/roles';

const LinkAccountButton = ({refresh}) => {
  const [isShowing, setIsShowing] = useState(false);

  const handleClick = e => {
    e.preventDefault();
    console.log('opening');

    setIsShowing(true);
  };

  const handleClose = () => {
    console.log('closing');
    setIsShowing(false);
  };

  const { companyId } = useParams();

  const dispatch = useDispatch();

  const companyIndex = Session.getCompanyIndex(companyId);
  const role = Session.getRole();

  const [hideModal, setHideModal] = useState(false);
  // const [loading, setLoading] = useState(false);

  const [addPlaidAccount, { data }] = useMutation(ADD_PLAID_ACCOUNT, {
    onCompleted: data => {
      const accounts = data.addPlaidAccount.accounts.map(account =>
        JSON.parse(account)
      );
      dispatch(plaidAddAccount(companyIndex, accounts));
      refresh();
    }
  });

  // const onLoad = () => setLoading(true);

  const handleOnExit = (error, metadata) => {
    dispatch(plaidError('You did not link an account.'));
  };

  const handleOnSuccess = (public_token, metadata) => {
    addPlaidAccount({
      variables: {
        companyId,
        public_token,
        metadata: JSON.stringify(metadata)
      }
    });
  };

  return (
    <>
      {(role === ADMIN ||
        role === ACCOUNTANT ||
        role === PA ||
        role === CS) && (
          <PrimaryButton size="medium" color="secondary" onClick={handleClick}>
            LINK ACCOUNT
          </PrimaryButton>
        )}
      {isShowing && (
        <LinkAccountOptions
          onClose={handleClose}
          handleOnSuccess={handleOnSuccess}
          handleOnExit={handleOnExit}
        />
      )}
    </>
  );
};

export default LinkAccountButton;
