import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { ADMIN, CS, ACCOUNTANT } from 'utils/roles';
import Session from "utils/session";
import {
  GET_TYPES,
  GET_TAGS,
  GET_FILES,
  DOWNLOAD_FILE,
  GET_CHART_OF_ACCOUNTS,
  FILTER_FILES
} from 'graphql/queries';
import moment from 'moment';
import {
  FullPage,
  PageHeader,
  PrimaryButton
} from '@tycoonlabs/web-components';
import FilesActions from './FilesActions';
import FilesTable from './FilesTable';
import FilterDrawer from './FilterDrawer';
import UploadDrawer from './UploadDrawer';
const defaultFilters = {
  filename: '',
  type: '',
  tag: '',
  showArchived: false,
  dateFrom: null,
  dateTo: null
};
const Files = () => {
  const { companyId } = useParams();
  const [drawer, setDrawer] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const role = Session.getRole();
  const [data, setData] = useState({ tags: null, types: null, accounts: null });
  const [selectedFile, setSelectedFile] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [filter, setFilter] = useState(defaultFilters);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [getTypes, { }] = useLazyQuery(GET_TYPES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getTypes }) => setData({ ...data, types: getTypes })
  });
  const [getTags, { }] = useLazyQuery(GET_TAGS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getTags }) => setData({ ...data, tags: getTags })
  });
  const [getAccounts, { }] = useLazyQuery(GET_CHART_OF_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: {
      company_id: companyId
    },
    onCompleted: ({ getChartOfAcc }) => {
      setData({ ...data, accounts: getChartOfAcc });
    }
  });
  const [getFiles, { }] = useLazyQuery(FILTER_FILES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ filterFiles: { objects, count, page } }) => {
      setFilteredFiles(objects);
      setCount(count);
      setPage(page);
    }
  });
  const [downloadFile, { }] = useLazyQuery(DOWNLOAD_FILE, {
    fetchPolicy: 'no-cache',
    // onCompleted: ({ downloadFile }) => window.open(downloadFile, '_blank')
    onCompleted: ({ downloadFile }) => (window.location.href = downloadFile)
  });
  const getData = async () => {
    await getTypes();
    await getTags();
    await getAccounts();
  };
  useEffect(() => {
    if (!data.tags && !data.types) {
      getData();
    }
  }, [data]);
  const onSearchPressed = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      console.log('####onSearchChange', e.target.value);
      setFilter({ ...filter, filename: e.target.value });
    }
  };
  const onSearchChange = e => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const handleApplyFilters = filtersToApply => {
    setFilter({
      ...filter,
      filename: filtersToApply.filename,
      accountName: filtersToApply.accountName,
      type: filtersToApply.type,
      tag: filtersToApply.tag,
      showArchived: filtersToApply.showArchived,
      dateFrom: filtersToApply.dateFrom,
      dateTo: filtersToApply.dateTo
    });
    setSearch(filtersToApply.filename);
    console.log('####handleApplyFilters', filter);
  };
  const handleDownload = id => {
    downloadFile({
      variables: {
        id: id
      }
    });
  };
  const handleRowClick = row => {
    console.log(row);
    if (role === ADMIN ||
      role === ACCOUNTANT ||
      role === CS) {
      setSelectedFile(row);
      handleOpenDrawer('edit');
    }
  };
  const inputVariables = {
    company_id: companyId,
    page,
    size,
    sort,
    direction,
    filter
  };
  useEffect(() => {
    console.log('#######Get files with filter:', filter);
    getFiles({
      variables: inputVariables
    });
  }, [filter, size, page, sort, direction]);
  const contentDrawer = () => {
    if (openDrawer) {
      if (drawer === 'filter') {
        return (
          <FilterDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            search={search}
            setFilter={setFilter}
            filter={filter}
            defaultFilters={defaultFilters}
            handleApplyFilters={handleApplyFilters}
            data={data}
          />
        );
      } else {
        return (
          <UploadDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            drawer={drawer}
            data={data}
            setData={setData}
            getTags={getTags}
            getTypes={getTypes}
            getFiles={getFiles}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleDownload={handleDownload}
            inputVariables={inputVariables}
          />
        );
      }
    }
  };
  const handleOpenDrawer = drawer => {
    setDrawer(drawer);
    setOpenDrawer(true);
  };
  return (
    <>
      <PageHeader>
        <h1>Files</h1>
        {(role === ADMIN ||
          role === ACCOUNTANT ||
          role === CS) && (
            <PrimaryButton size="medium" color="secondary" onClick={() => handleOpenDrawer('upload')}>
              Upload
            </PrimaryButton>
          )}
      </PageHeader>
      <FullPage>
        <FilesActions
          search={search}
          onSearchChange={onSearchChange}
          onSearchPressed={onSearchPressed}
          handleOpenDrawer={handleOpenDrawer}
        />
        <FilesTable
          files={filteredFiles}
          handleDownload={handleDownload}
          handleRowClick={handleRowClick}
          count={count}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          direction={direction}
          setDirection={setDirection}
        />
      </FullPage>
      {contentDrawer()}
    </>
  );
};
export default Files;
