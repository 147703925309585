import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { ReactComponent as BackOfficeLogo } from '../../assets/back-office.svg';
import Button from '../reusables/Button';
import Spinner from '../reusables/Spinner';
import configs from 'config';
import { Img } from "react-image";

import { GET_PASSWORD_RESET_TOKEN } from '../../graphql/queries';
import { RESET_PASSWORD } from '../../graphql/mutations';

const ModifyPassword = () => {
  const history = useHistory();
  const { token } = useParams();

  const [creds, setCreds] = useState({
    newPasswordOne: '',
    newPasswordTwo: ''
  });

  const { newPasswordOne, newPasswordTwo } = creds;

  const [user, setUser] = useState({
    id: '',
    email: ''
  });

  const notifyError = error => {
    toast.warn(error);
    setCreds({
      newPasswordOne: '',
      newPasswordTwo: ''
    });
  };

  const handleRequestError = error => {
    if (error === 'invalid request') {
      toast.warn(
        'Not a valid request. Please login or click on forgot password'
      );
      history.push('/login');
    } else {
      toast.warn(error);
      history.push('/login');
    }
  };

  const { data } = useQuery(GET_PASSWORD_RESET_TOKEN, {
    variables: { token },
    onCompleted: data =>
      setUser({
        id: data.getPwdResetToken.user.id,
        email: data.getPwdResetToken.user.email
      }),
    onError: error => handleRequestError(error.graphQLErrors[0].message)
  });

  const [editPassword, { loading, error }] = useMutation(RESET_PASSWORD, {
    variables: { newPasswordOne, user_id: user.id, token },
    onCompleted: data => {
      history.replace('/login');
    },
    onError: error => notifyError(error.graphQLErrors[0].message)
  });

  const changeHandler = e => {
    const { name, value } = e.target;
    setCreds({ ...creds, [name]: value });
  };

  const onFormSubmit = e => {
    e.preventDefault();
    if (newPasswordOne === newPasswordTwo) {
      editPassword();
    } else {
      notifyError('Passwords do not match.');
    }
  };
  const LOGO = <Img src={configs.app.logo} style={{ maxWidth: "300px" }} />;

  return (
    <main className='creds-container'>
      <div className='creds-card'>
        <div className='logo-container'>
          {LOGO}
        </div>
        <form
          onSubmit={e => onFormSubmit(e)}
          className='creds-inputs-container'
        >
          <span>Create a new password</span>
          <h2 style={{ fontSize: 16, marginBottom: 15 }}>{user.email}</h2>

          <input
            name='newPasswordOne'
            value={creds.newPasswordOne}
            type='password'
            onChange={changeHandler}
            placeholder='Enter New Password'
            required
          />
          <input
            name='newPasswordTwo'
            value={creds.newPasswordTwo}
            type='password'
            onChange={changeHandler}
            placeholder='Verify New Password'
            required
          />
          {loading && <Spinner />}
          {!loading && (
            <Button type='submit' classN='creds-button'>
              Update Password
            </Button>
          )}
        </form>
        <div className='redirect-signup'>
          <span>
            Already have an account?
            <p onClick={() => history.push('/login')}>Sign in</p>
          </span>
        </div>
      </div>
    </main>
  );
};

export default ModifyPassword;
