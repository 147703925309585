import React, {useState,useEffect} from 'react';
import {toast} from 'react-toastify';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {ContentCardShadowed, FormButton} from '@tycoonlabs/web-components';
import Collapse from '@material-ui/core/Collapse';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
const useStyles = makeStyles(theme => ({
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	instruction:{
		padding: '0 10px',
		color: '#616161',
	}
}));
const EmailContent = styled(TextareaAutosize)`
	width: 100%;
	border:1px solid #EEEEEE;
	border-radius: 5px;
	padding:10px;
	font-size:1.4px;
	font-size:1.4rem;
	white-space: pre-wrap;
`;
String.prototype.interpolate = function (params) {
	const names = Object.keys(params);
	const vals = Object.values(params);
	return new Function(...names, `return \`${this}\`;`)(...vals);
};

const Clipboard = React.forwardRef((props, ref) => {
	const email = props.email;
	const data = props.data;
	const id = props.id;
	const classes = useStyles();
	const [expanded, setExpanded] = useState({[id]:false});
	const [copying, setCopying] = useState(false);
	let templateContent = email.template.interpolate(data);
	const handleExpandClick = () => {
		setExpanded(prevState => ({...prevState, [id]: !expanded[id]}));
	};
	useEffect(()=>{
		if (expanded[id] && copying){
			const timer = setTimeout(() => {
				copy()
			}, 300);
			return () => clearTimeout(timer);
		}
	},[expanded]);
	const copy = () =>{
		ref.current.select();
		document.execCommand('copy');
		window.getSelection().removeAllRanges();
		toast.info('Email content copied to clipboard.  You can now paste into your email.  Please double check before you send out.');
		setCopying(false);
	};
	const handleCopy = () => {
		setExpanded(prevState => ({...prevState, [id]: true}));
		setCopying(true);
	};
	return (
		<ContentCardShadowed key={id}   style={{margin: "20px 0"}}>
			<CardHeader
				title={props.email.title}
				subheader={props.email.desc}
			/>
			<CardActions disableSpacing className={"flex-row-sc justify-between"}>
				<div className={"flex flex-row-se"}>
					<FormButton color="primary" onClick={() => handleCopy()}>Copy</FormButton>
					<span className={classes.instruction}>Click copy and then paste into mail.</span>
				</div>
				<FormButton
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
				>
					Details
					<ExpandMoreIcon className={clsx(classes.expand, {[classes.expandOpen]: expanded[id],})}/>
				</FormButton>
			</CardActions>
			<Collapse in={expanded[id]} timeout="auto" unmountOnExit>
				<CardContent>
					<EmailContent
						className={"flex"}
						id="emailTemplate001"
						label="Email Content"
						rowsMin={3}
						rowsMax={10}
						ref={ref}
						variant="outlined"
						defaultValue={templateContent}
					/>
				</CardContent>
			</Collapse>
		</ContentCardShadowed>
	)
});
export default Clipboard;
