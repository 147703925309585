import React, { useMemo } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { EmptyPage } from '@tycoonlabs/web-components'
import { DataTableStyles } from 'components/reusables/styles';

const DocumentsTable = ({ documents, handleDownload }) => {
  const threshold = documents.length > 25;
  console.log("###### documents", documents.length);
  console.log("###### documents", documents);
  const columns = useMemo(
    () => [
      {
        name: 'DATE',
        selector: 'date',
        sortable: true,
        cell: row => {
          return `${moment(row.date).format('lll')}`;
        }
      },
      {
        name: 'FILENAME',
        selector: 'filename',
        sortable: true,
        grow: 3
      },
      {
        name: '',
        sortable: false,
        grow: 1,
        right: true,
        cell: row => {
          return (
            <IconButton onClick={() => handleDownload(row.id)}>
              <DownloadIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          );
        }
      }
    ],
    []
  );

  return (
    <div className='I-just-did'>
      {documents && documents.length > 0 && (
        <DataTable
          columns={columns}
          data={documents}
          defaultSortField='uploaded_at'
          defaultSortAsc={false}
          pagination={threshold}
          paginationPerPage={25}
          noHeader
          highlightOnHover
          customStyles={DataTableStyles}
        // noDataComponent={<Component />}
        />
      )}
      {(!documents || documents.length <= 0) && (
        <EmptyPage>
          No document found. 
        </EmptyPage>
      )}
    </div>
  );
};

export default DocumentsTable;
