import React, { useMemo } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';

import { DataTableStyles } from '../reusables/styles';

const StatementsTable = ({ statements, handleDownload }) => {
  const threshold = statements.length > 25;

  const columns = useMemo(
    () => [
      {
        name: 'DATE',
        selector: 'date',
        sortable: true,
        cell: row => {
          return `${moment(row.date).format('lll')}`;
        }
      },
      {
        name: 'ACCOUNT NAME',
        selector: 'account_name',
        sortable: true
      },
      {
        name: 'FILENAME',
        selector: 'filename',
        sortable: true,
        grow: 3
      },
      {
        name: '',
        sortable: false,
        grow: 1,
        right: true,
        cell: row => {
          return (
            <IconButton onClick={() => handleDownload(row.id)}>
              <DownloadIcon style={{ fontSize: '2rem' }} />
            </IconButton>
          );
        }
      }
    ],
    []
  );

  return (
    <div className='I-just-did'>
      <DataTable
        columns={columns}
        data={statements}
        defaultSortField='date'
        defaultSortAsc={false}
        pagination={threshold}
        paginationPerPage={25}
        noHeader
        highlightOnHover
        customStyles={DataTableStyles}
        // noDataComponent={<Component />}
      />
    </div>
  );
};

export default StatementsTable;
