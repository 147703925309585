import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PortalWithState } from 'react-portal';
import { Img } from 'react-image'
import { List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Divider } from "@material-ui/core";
import { ReactComponent as ProfileIcon } from '../../assets/profile_black.svg';
import {ReactComponent as LogoutIcon} from '../../assets/logout_black.svg';

const ActionsContainer = styled.div`
  position: fixed;
  z-index: 9998;
  top: 102px;
  right: 36px;
  background-color: #fffdfd;
  border: 1px solid #ececec;
  padding: 28px 38px 28px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 150px;
  justify-content: center;
`;

const ActionText = styled(ListItemText)`
	&&{
		.MuiListItemText-primary{
			font-size: 12px;
			font-weight: 600;
			color: #505050;
			cursor: pointer;
		}
	}
`;
const NavButton = styled.button`
  background: transparent;
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
`;

const Actions = (props) => {
	const history = useHistory();
  const jumpTo = (link, closePortal) => {
    history.replace(link)
    closePortal();
  }
  const logout = () => {
		localStorage.removeItem('authToken');
		history.replace('/login');
		window.location.reload();
	};
  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ openPortal, portal, closePortal }) => (
        <>
          <NavButton color="primary" onClick={openPortal}>{props.children}</NavButton>
          {portal(
            <ActionsContainer>
              <List>
                <ListItem disableGutters={true} button  onClick={() => jumpTo("/profile", closePortal)}>
                  <ListItemIcon>
                    <ProfileIcon />
                  </ListItemIcon>
                  <ListItemText primary={<h2 className={"link"}>Profile</h2>} />
                </ListItem>
                <ListItem disableGutters={true} button  onClick={logout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={<h2 className={"link"}>Logout</h2>} />
                </ListItem>
              </List>
            </ActionsContainer>
          )}
        </>
      )}
    </PortalWithState>
  );
}
const AccountWidget = (props) => {
  return (
    <Actions>
      <Img src={`//cdn.backoffice.co/media/menu/account.svg`} />
    </Actions>
  )
}

export { AccountWidget };