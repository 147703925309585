/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';

import DocumentsTable from './DocumentsTable';
import UploadDocument from './UploadDocument';
import { GET_CLIENT_FILES, DOWNLOAD_FILE } from '../../graphql/queries';
import { PageHeader, FullPage, Page } from '@tycoonlabs/web-components'
import UploadButton from './UpbloadButton';
import { HeaderActionLoader,HeaderActionContainer} from 'components/reusables/AppStyles';

const Documents = () => {
  const { companyId } = useParams();
  const history = useHistory();
  const [isShowing, setIsShowing] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [getFiles, { loading }] = useLazyQuery(GET_CLIENT_FILES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getAllUserDocuments: documents }) => {
      console.log("##### documents...", documents)
      if (documents.length < 1) {
        setIsShowing(true);
      }
      setDocuments(documents);
    }
  });

  const [downloadFile, { }] = useLazyQuery(DOWNLOAD_FILE, {
    fetchPolicy: 'no-cache',
    // onCompleted: ({ downloadFile }) => window.open(downloadFile, '_blank')
    onCompleted: ({ downloadFile }) => (window.location.href = downloadFile)
  });

  const handleDownload = id => {
    downloadFile({
      variables: {
        id: id
      }
    });
  };

  useEffect(() => {
    console.log('this ran');
    getFiles({
      variables: { company_id: companyId }
    });
  }, []);

  return (
    <>
      <PageHeader>
        <h1>Documents</h1>
        <HeaderActionContainer>
          {loading && (
            <HeaderActionLoader />
          )}
          <UploadButton />
        </HeaderActionContainer>
      </PageHeader>
      <FullPage className='documents-container'>
      {!loading && (
        <DocumentsTable documents={documents} handleDownload={handleDownload} />
      )}
      </FullPage>
    </>
  );
};

export default Documents;
