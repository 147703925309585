import React, { useMemo, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import Spinner from 'components/reusables/Spinner';
import Tippy from '@tippy.js/react';
import { FormButton, ContentEmpty } from 'components/reusables/Contents';
import DataTable from 'react-data-table-component';
import Paginator from 'components/reusables/Paginator';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactComponent as PlaidIcon } from 'assets/plaid-logo.svg';
import { ReactComponent as MatchedIcon } from 'assets/matched.svg';
import { ReactComponent as QBIcon } from 'assets/quickbooks-logo.svg';
import { DataTableStyles } from 'components/reusables/styles';
const TransactionsTable = ({
  transactions,
  loading,
  selectedIndex2,
  handleTransactionSelection,
  setSelectedRows,
  selectDropdown,
  setSelectDropdown,
  count,
  size,
  setSize,
  page,
  setPage,
  sort,
  setSort,
  direction,
  setDirection
}) => {
  const setIcon = row => {
    if (row.source === 'plaid') {
      return (
        <Tippy arrow content='Plaid' animation='fade' placement='right'>
          <PlaidIcon />
        </Tippy>
      );
    } else if (row.source === 'quickbooks') {
      return (
        <Tippy arrow content='Quickbooks' animation='fade' placement='right'>
          <QBIcon />
        </Tippy>
      );
    } else if (row.source === 'statement') {
    }
  };
  const handleSelectedRowChange = useCallback(state => {
    setSelectDropdown(false);
    setSelectedRows(state.selectedRows);
    return state.selectedRows;
  }, []);
  const handleRowClick = row => {
    handleTransactionSelection(row);
  };
  const handleSelectedTransactions = row => {
    if (selectedIndex2 === 1) {
      return row;
    } else if (selectedIndex2 === 2) {
      return row.status === 'New';
    } else if (selectedIndex2 === 3) {
      return row.status === 'Processed';
    } else {
      return null;
    }
  };
  const columns = useMemo(
    () => [
      {
        name: 'SRC',
        selector: 'source',
        sortable: true,
        wrap: true,
        center: true,
        width: '60px',
        cell: row => {
          return setIcon(row);
        }
      },
      {
        name: 'STATUS',
        selector: 'status',
        sortable: true,
        wrap: true
      },
      {
        name: 'ACCOUNT NAME',
        selector: 'account_name',
        sortable: true,
        wrap: true
      },
      {
        name: 'DATE',
        selector: 'date',
        sortable: true,
        cell: row => {
          return `${moment(row.date).format('L')}`;
        }
      },
      {
        name: 'DESCRIPTION',
        selector: 'description',
        sortable: true,
        wrap: true,
        grow: 13
      },
      {
        name: 'CATEGORY',
        selector: 'category_name',
        sortable: true,
        wrap: true
      },
      { name: 'PAYEE', selector: 'vendor_name', sortable: true, wrap: true },
      {
        name: 'AMOUNT',
        selector: 'amount',
        sortable: true,
        // right: true,
        // grow: 1,
        cell: row => {
          return `${new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(row.amount)}`;
        }
      },
      {
        name: 'PUSHED',
        selector: 'pushed',
        sortable: true,
        wrap: true,
        width: '60px',
        // grow: 15,
        cell: row => {
          return row.pushed ? <MatchedIcon /> : '';
        }
      },
      // {name: 'RULES', selector: 'rules', sortable: true, wrap: true},
      {
        name: 'RECORDED',
        selector: 'created',
        sortable: true,
        wrap: true,
        cell: row => {
          if (row.created) return `${moment(row.created).format('L')}`;
          else return '';
        }
      }
    ],
    []
  );
  const handlePageChange = p => {
    setPage(p);
  };
  const handlePerRowsChange = (perPage, page) => {
    setSize(perPage);
    setPage(page);
  };
  const handleSort = (sort, direction) => {
    setSort(sort.selector);
    setDirection(direction);
  };
  return (
    <>
      <div className='data-table'>
        {transactions.length > 0 && (
          <DataTable
            columns={columns}
            data={transactions}
            onRowClicked={handleRowClick}
            defaultSortField={'date'}
            defaultSortAsc={false}
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            onSort={handleSort}
            paginationPerPage={size}
            paginationRowsPerPageOptions={[10, 15, 25, 50]}
            selectableRowsNoSelectAll={true}
            noHeader
            highlightOnHover
            pointerOnHover={true}
            selectableRows
            onSelectedRowsChange={handleSelectedRowChange}
            selectableRowSelected={
              selectDropdown ? handleSelectedTransactions : null
            }
            customStyles={DataTableStyles}
          />
        )}
      </div>
      {!transactions.length && (
        <ContentEmpty>
          No transaction record found. Please adjust your filter or sync
          transactions.
        </ContentEmpty>
      )}
    </>
  );
};
export default TransactionsTable;
