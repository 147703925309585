/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-apollo';
import { useDropzone } from 'react-dropzone';
import { uploadFile, deleteFile } from 'react-s3';
import { toast } from 'react-toastify';

import { editUser } from 'actions';
import { EDIT_USER } from '../../graphql/mutations';
import Session from '../../utils/session';

const Avatar = () => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const user = Session.getUser();
  const avatar = user.profile_image_url;

  const [editUserGQL, {}] = useMutation(EDIT_USER, {
    variables: {
      user_id: user.id,
      modified_by_id: user.id,
      userinput: {
        profile_image_url: url
      }
    },
    onCompleted: data => {
      dispatch(editUser(data.editUser));
      toast.success('Your avatar was successfully updated.');
    },
    onError: () => {
      toast.error(
        'Something went wrong updating your avatar. Please try again.'
      );
    }
  });

  useEffect(() => {}, [user]);

  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    dirName: 'avatar',
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  };

  const onDrop = useCallback(acceptedFiles => {
    uploadFile(acceptedFiles[0], config)
      .then(res => {
        setUrl(res.location);
        editUserGQL();
      })
      .catch(err => console.warn(err));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <form className='avatar-wrapper' {...getRootProps()}>
      {/* <input {...getInputProps()} /> */}
      {avatar ? (
        <img src={avatar} alt='AVATAR' />
      ) : (
        <div className='avatar-default'>
          {user && user.first_name.toUpperCase().charAt(0)}
          {user && user.last_name.toUpperCase().charAt(0)}
        </div>
      )}
    </form>
  );
};

export default Avatar;
