import React from 'react';
// import AddClient from './AddClient';
import AddSalesClient from './AddSalesClient';

const Registration = props => {
  return (
    <>
      {/* if sales <AddSalesClient />
      if accountant or higher <AddClient /> */}
      {/* <AddClient /> */}
      <AddSalesClient {...props} />
    </>
  );
};

export default Registration;
