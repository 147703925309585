import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
// import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import { useDropzone } from 'react-dropzone';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import {
  FormTextField,
  FormMenuItem,
  FormButton
} from 'components/reusables/Contents';
import { GENERATE_PRESIGNED_URL, EDIT_FILE } from 'graphql/mutations';

const useStyles = makeStyles({
  documentsUploadModal: {
    position: 'fixed',
    zIndex: 99999,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(47, 53, 62, 0.86)'
  },
  mainContainer: {
    width: '750px',
    height: '500px',
    margin: '10% auto',
    backgroundColor: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px'
  },
  title: {
    fontSize: '1.8rem',
    margin: '10px'
  },
  closeIcon: {
    width: '24px',
    height: '24px',
    color: '#9E9E9E',
    '&:hover': {
      cursor: 'pointer',
      color: 'black'
    }
  },
  content: {
    width: '100%',
    height: '450px'
    // padding: '10px'
  },
  uploadContainer: {
    width: '100%',
    height: '100%',
    padding: '30px'
  },
  uploadContent: {
    width: '100%',
    height: '100%',
    border: '1px #9E9E9E dotted',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'white',
      cursor: 'pointer',
      '& svg': {
        color: 'blue'
      }
    }
  },
  uploadTextContainer: {
    height: '140px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  uploadIcon: {
    width: '75px',
    height: '75px',
    color: '#9E9E9E'
  },
  uploadTextMain: {
    fontSize: '2rem'
  },
  uploadTextSecondary: {
    fontSize: '1.3rem',
    color: '#9E9E9E'
  },
  listContainer: {
    width: '100%',
    height: '420px',
    marginTop: '30px',
    borderTop: '1px #9E9E9E dotted'
  },
  listContent: {
    margin: '20px'
  },
  listHeader: {
    height: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '10px'
  },
  listTitle: {
    fontSize: '1.4rem'
  },
  listStatus: {
    fontSize: '1.2rem',
    color: '#9E9E9E'
  },
  listFileContainer: {
    height: '300px',
    overflowY: 'auto',
    marginTop: '10px',
    paddingRight: '10px'
  },
  fileContainer: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '10px',
    padding: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fileSize: {
    color: '#9E9E9E',
    paddingTop: '3px'
  },
  clearFileIcon: {
    color: '#9E9E9E',
    '&:hover': {
      color: 'black',
      cursor: 'pointer'
    }
  },
  fileButtons: {
    width: '100%',
    height: '60px',
    paddingRight: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});

const UploadDocument = () => {
  const { companyId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(null);

  console.log(files);

  const handleUpload = async (resp, file) => {
    const response = await fetch(
      new Request(resp.url, {
        method: 'PUT',
        body: file,
        headers: new Headers({
          'Content-Type': `${file.type}`
        })
      })
    );
    if (response.status !== 200) {
      setStatus('Failed');
      editFile({
        variables: {
          file_id: resp.obj_id,
          uploaded: false,
          upload_input: null
        }
      });
    }
  };

  const [editFile, {}] = useMutation(EDIT_FILE, {
    onCompleted: ({ editFile }) => {
      if (editFile.success) {
        console.log('#### edit files success');
      } else {
        setStatus('failed');
      }
    }
  });

  const [generatePresignedUrl, {}] = useMutation(GENERATE_PRESIGNED_URL);

  const handleMultipleFileUpload = async () => {
    setStatus('uploading');
    let count = 1;
    for (const file of files) {
      const {
        data: { generatePresignedUrl: dataObj }
      } = await generatePresignedUrl({
        variables: {
          company_id: companyId,
          upload_input: {
            filename: file.name,
            source: 'userUpload',
            access_level: 'client'
          }
        }
      });
      const _data = await handleUpload(dataObj, file);
      if (count === files.length) {
        setStatus('success');
      }
      count += 1;
    }
  };

  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  const removeFile = path => {
    const fileList = files.filter(file => {
      if (file.path !== path) {
        return file;
      }
    });
    setFiles(fileList);
  };

  const removeAll = () => {
    setFiles([]);
  };

  const handleClose = () => {
    history.push(`/documents/${companyId}`);
  };

  const FileContainer = file => (
    <div key={file.path} className={classes.fileContainer}>
      <div className={classes.fileData}>
        <div className={classes.fileName}>{file.name}</div>
        <div className={classes.fileSize}>{file.size} KB</div>
      </div>
      <div
        onClick={() => removeFile(file.path)}
        className={classes.clearFileIcon}
      >
        <ClearIcon />
      </div>
    </div>
  );

  const UploadView = () => (
    <div className={classes.uploadContainer}>
      <div className={classes.uploadContent} {...getRootProps()}>
        <div className={classes.uploadTextContainer}>
          <NoteAddIcon className={classes.uploadIcon} />
          <h3 className={classes.uploadTextMain}>Select Files to Upload</h3>
          <p className={classes.uploadTextSecondary}>or Drag and Drop Files</p>
        </div>
        <input {...getInputProps()} />
      </div>
    </div>
  );

  const showStatus = () => {
    if (status === 'uploading') {
      return 'Uploading Files';
    } else if (status === 'success') {
      return 'Successfully Uploaded Files';
    } else {
      return 'Failed to Upload Files';
    }
  };

  const ListView = () => (
    <div className={classes.listContainer}>
      <div className={classes.listContent}>
        <div className={classes.listHeader}>
          <div className={classes.listTitle}>DOCUMENTS</div>
          <div className={classes.listStatus}>{status ? showStatus() : ''}</div>
        </div>
        <div className={classes.listFileContainer}>
          {files.map(file => FileContainer(file))}
        </div>
        <div className={classes.fileButtons}>
          {status ? (
            <div></div>
          ) : (
            <FormButton onClick={removeAll}>
              Clear All {files.length} Files
            </FormButton>
          )}
          {status ? (
            status === 'success' ? (
              <FormButton onClick={handleClose} color='primary'>
                Done
              </FormButton>
            ) : (
              ''
            )
          ) : (
            <FormButton onClick={handleMultipleFileUpload} color='primary'>
              Upload
            </FormButton>
          )}
        </div>
      </div>
    </div>
  );

  const content = (
    <div className={classes.documentsUploadModal}>
      <div className={classes.mainContainer}>
        <div className={classes.header}>
          <span>
            <h2 className={classes.title}>
              {files.length ? 'Selected Documents' : 'Upload Documents'}
            </h2>
          </span>
          <span onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </span>
        </div>
        <div className={classes.content}>
          {files.length ? ListView() : UploadView()}
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(content, document.querySelector('#modal'));
};

export default UploadDocument;
