import React, { useEffect, useRef } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import styled from 'styled-components';
import 'react-day-picker/lib/style.css';
const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  margin-top: 8px;
  margin-bottom: 2px;
  h5 {
    font-size: 11px;
    font-size: 1.1rem;
    color: #282828;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left:3px;
    padding-bottom: 3px;
  }
`;

const inputStyles = {
	background: '#F6F6F6',
	borderRadius: '5px',
	border: '1px solid #FFFFFF',
	fontSize: '1.4rem',
	padding: '0 8px',
	lineHeight: "3rem",
	textAlign: 'center',
	margin: '0 0 8px 0',
	width: '10rem'
};

const DateInput = props => {
  let { label, value, formatDate, dayPickerProps, handleDateChange, styles } = props;
  let style = {...inputStyles, ...styles};
  return (
    <DateBox>
      <h5>{label}</h5>
      <DayPickerInput
        value={value}
        formatDate={formatDate}
        inputProps={{ style: style }}
        dayPickerProps={dayPickerProps}
        onDayChange={handleDateChange}
      />
    </DateBox>
  );
};
export default DateInput;
