import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { ADMIN, CS, ACCOUNTANT, PA, CLIENT } from 'utils/roles';

import { plaidAddAccount } from 'actions';
import { ADD_PLAID_ACCOUNT, ADD_PLAID_ERROR } from 'graphql/mutations';

import PlaidModal from './PlaidModal';
import PlaidCard from './PlaidCard';
import Session from '../../../utils/session';
import Spinner from '../../reusables/Spinner';
import { toast } from 'react-toastify';

const PlaidConnect = ({refresh}) => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const companyIndex = Session.getCompanyIndex(companyId);
  const [hideModal, setHideModal] = useState(false);
  // const [loading, setLoading] = useState(false);
  const role = Session.getRole();

  const [addPlaidAccount, { data }] = useMutation(ADD_PLAID_ACCOUNT, {
    onCompleted: data => {
      const accounts = data.addPlaidAccount.accounts.map(account =>
        JSON.parse(account)
      );
      dispatch(plaidAddAccount(companyIndex, accounts));
      refresh();
    }
  });

  const [sendPlaidError, {}] = useMutation(ADD_PLAID_ERROR, {
    onCompleted: data => {
      toast.warn(
        'There was an error linking your account. Please try again later.'
      );
    }
  });

  const handleOnExit = (error, metadata) => {
    toast.warn('You did not link an account.');
  };

  const handleOnSuccess = (public_token, metadata) => {
    addPlaidAccount({
      variables: {
        companyId,
        public_token,
        metadata: JSON.stringify(metadata)
      }
    });
  };

  const handleOnEvent = (eventname, metadata) => {
    if (eventname === 'EXIT') {
      sendPlaidError({
        variables: { company_id: companyId, metadata: JSON.stringify(metadata) }
      });
    }
  };

  const closeModal = e => {
    e.stopPropagation();
    setHideModal(true);
  };

  return (
    <>
      {!hideModal && (
        <PlaidModal
          closeModal={closeModal}
          handleOnExit={handleOnExit}
          handleOnSuccess={handleOnSuccess}
          handleOnEvent={handleOnEvent}
        />
      )}
      {hideModal && (
        <PlaidCard
          handleOnExit={handleOnExit}
          handleOnSuccess={handleOnSuccess}
          handleOnEvent={handleOnEvent}
        />
      )}
    </>
  );
};

export default PlaidConnect;
