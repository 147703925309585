import React, { useEffect, useState } from 'react';
import ClientsActions from './ClientsActions';
import ClientsTable from './ClientsTable';
import { useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { SEARCH_CLIENTS } from 'graphql/client';
import Spinner from 'components/reusables/Spinner';
import { ContentHeader } from '../reusables/Contents';
import EditCompany from './EditCompany';
import FilterDrawer from './FilterDrawer';
import useWindowDimensions from '../reusables/WindowSize';
import UsersTable from '../Platform/User/UsersTable';
import { ADMIN, CS, PA, ACCOUNTANT, ASSOCIATE, SALES } from 'utils/roles';
import Session from 'utils/session';
import {
  FullPage,
  PageHeader,
  PrimaryButton
} from '@tycoonlabs/web-components';
const defaultFilters = {
  search: '',
  showRemoved: false
};

const Clients = () => {
  const { width } = useWindowDimensions();
  const role = Session.getRole();
  //For table and filter
  const [clients, setClients] = useState([]);
  const [filter, setFilter] = useState(defaultFilters);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(15);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('name');
  const [direction, setDirection] = useState('desc');
 
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedClient, setSelectedClient] = useState({});
  const [fetchClients, { loading }] = useLazyQuery(SEARCH_CLIENTS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      console.log('### Fetched clients', data);
      let page = data.clients;
      setClients(page.objects);
      setCount(page.count);
      setPage(page.page);
    },
    onError: e => {
      toast.error('Failed to load user.');
    }
  });
  const inputVariables = {
    search: filter.search,
    showRemoved: filter.showRemoved,
    size: size,
    page: page,
    sort: sort,
    direction: direction
  };
  useEffect(() => {
    console.log('### fetch clients', inputVariables, search, size, page, sort, direction);
    fetchClients({
      variables: inputVariables
    });
  }, [fetchClients, filter, size, page, sort, direction]);
  const onSearchChange = e => {
    e.preventDefault();
    console.log('### in onSearchChange', e.target.value);
    setSearch(e.target.value);
  };
  const onSearchPressed = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      console.log('### in onSearchChange', e.target.value);
      setFilter(e.target.value);
    }
  };
  const handleEditClient = company => {
    setSelectedClient(company);
    handleOpenDrawer("edit")
  };
  const handleNewClient = () => {
    setSelectedClient({});
    handleOpenDrawer(true);
  };
  const handleOpenDrawer = drawer => {
    setDrawer(drawer);
    setOpenDrawer(true);
  };
  const handleApplyFilters = filtersToApply => {
    console.log('####handleApplyFilters', filtersToApply);
    setFilter({
      ...filter,
      search: filtersToApply.search,
      showRemoved: filtersToApply.showRemoved
    });
    setSearch(filtersToApply.search);
  };
  const contentDrawer = () => {
    if (openDrawer) {
      if (drawer === 'filter') {
        return (
          <FilterDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            search={search}
            setFilter={setFilter}
            filter={filter}
            defaultFilters={defaultFilters}
            handleApplyFilters={handleApplyFilters}
          />
        );
      } else {
        return (
          <EditCompany
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            selectedClient={selectedClient}
            refreshClient={fetchClients}
            inputVariables={inputVariables}
          />
        );
      }
    }
  };
  return (
    <>
      <PageHeader>
        <h1>Clients</h1>
        {(role === ADMIN ||
          role === ACCOUNTANT ||
          role === CS ||
          role === PA ||
          role === SALES) && (
          <PrimaryButton
            size='medium'
            color='secondary'
            onClick={() => handleNewClient('')}
          >
            New
          </PrimaryButton>
        )}
      </PageHeader>
      <FullPage>
        <ClientsActions
          search={search}
          onSearchChange={onSearchChange}
          onSearchPressed={onSearchPressed}
          handleNewClient={handleNewClient}
          handleOpenDrawer={handleOpenDrawer}
        />
        <ClientsTable
          clients={clients}
          setOpenDrawer={setOpenDrawer}
          handleEditClient={handleEditClient}
          loading={loading}
          count={count}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          direction={direction}
          setDirection={setDirection}
        />
      </FullPage>
      {contentDrawer()}
    </>
  );
};
export default Clients;
