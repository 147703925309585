/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import React from 'react';
import ReactDOM from 'react-dom';
import { ADMIN, CS, ACCOUNTANT, PA, CLIENT, ASSOCIATE } from 'utils/roles';
import Session from '../../../utils/session';
import PlaidLink from 'react-plaid-link';
import configs from 'config';

import { ReactComponent as XIcon } from 'assets/x.svg';

const PlaidModal = ({
  closeModal,
  handleOnExit,
  handleOnSuccess,
  handleOnEvent
}) => {
  const role = Session.getRole();
  return ReactDOM.createPortal(
    <div className='modal-container'>
      <div className='modal-content'>
        <p className='close-modal'>
          <span>
            <XIcon onClick={e => closeModal(e)} />
          </span>
        </p>
        {(role === ADMIN ||
          role === ACCOUNTANT ||
          role === PA ||
          role === CLIENT ||
          role === CS) && (
            <>
              <h1>Let's get started by linking your business accounts</h1>
              <p>
                We use our {configs.app.partner} proprietary web app to complete your monthly
                bookkeeping by utilizing two types of bank connections. One connection
                is in charge of pulling your bank transactions and balances in real
                time. The second connection is in charge of pulling your monthly bank
                statements.
              </p>
              <div>
                <PlaidLink
                  className='modal-button'
                  clientName={configs.app.partner}
                  env='production'
                  // publicKey='1a73cefba99cb20309a1e926c53481'
                  publicKey='41268990c10b611a6f7b030a6882c3'
                  product={['auth', 'transactions']}
                  webhook='https://app.backoffice.co/plaid/webhook/'
                  onExit={handleOnExit}
                  onSuccess={handleOnSuccess}
                  onEvent={handleOnEvent}
                  style={null} // overrride built-in style
                  onClick={e => closeModal(e)}
                >
                  LINK ACCOUNTS
                </PlaidLink>
              </div>
              <div className='modal-footer'>
                <span>Your financial information is always safe.</span>
                <span>
                  We use industry standard 256-bit encryption to connect your data to
                  our software.
                </span>
                <span>This is the same level of security used with online banking.</span>
              </div>
            </>
          )}
        {(role === ASSOCIATE) && (
          <>
            <h1>No business account has been setup yet.</h1>
            <p>Please comeback later or contact your associate to set it up. </p>
          </>
        )}
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export default PlaidModal;
