import React, { useMemo } from 'react';
import moment from 'moment';
import Tippy from '@tippy.js/react';
import DataTable from 'react-data-table-component';

import Paginator from 'components/reusables/Paginator';

import { ReactComponent as PostedIcon } from 'assets/posted.svg';
import { ReactComponent as PendingIcon } from 'assets/pending.svg';
import { ReactComponent as MatchedIcon } from 'assets/matched.svg';
import Button from '@material-ui/core/Button';
import {DataTableStyles} from "components/reusables/styles";

const AddTransactionsTable = ({ transactions, loading }) => {
  console.log('transactions in Table:', transactions);
  const columns = useMemo(
    () => [
      {
        name: 'DATE',
        selector: 'date',
        sortable: true,
        cell: row => {
          return `${moment(row.date).format('L')}`;
        }
      },

      {
        name: 'DESCRIPTION',
        selector: 'description',
        sortable: true,
        wrap: true
      },
      {
        name: 'PAYEE',
        selector: 'payee',
        sortable: true,
        wrap: true
      },
      {
        name: 'CATEGORY',
        selector: 'category',
        sortable: true
      },
      {
        name: 'SPEND',
        selector: 'spend',
        sortable: true,
        wrap: true,
        cell: row => {
          return row.spend
            ? `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(row.spend)}`
            : '';
        }
      },

      {
        name: 'RECEIVED',
        selector: 'received',
        sortable: true,
        wrap: true,
        cell: row => {
          return row.received
            ? `${new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
              }).format(row.received)}`
            : '';
        }
      },
      {
        name: 'ACTIONS',
        sortable: true,
        cell: row => {
          // only show button when status is NEW
          return (
            <Button
              style={{
                border: '1px solid #686868',
                borderRadius: '5px',
                padding: '5px'
              }}
              onClick={null}
            >
              EDIT
            </Button>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <div className='transactions-table'>
        {transactions.length > 0 && (
          <DataTable
            columns={columns}
            data={transactions}
            defaultSortField='date'
            defaultSortAsc={false}
            pagination={transactions && transactions.length > 25}
            // paginationComponent={Paginator}
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25, 50, 100, 500]}
            noHeader
            highlightOnHover
						customStyles={DataTableStyles}
          />
        )}
      </div>
      {!loading && !transactions.length && (
        <div className='table-empty' style={{ textAlign: 'center' }}>
          There are no records to display
        </div>
      )}
    </>
  );
};

export default AddTransactionsTable;
