import produce, { setAutoFreeze } from 'immer';

import {
  LOADING,
  GET_USER,
  ERROR_MESSAGE,
  GET_ALL,
  SET_USER,
  EXCLUDE_PLAID_ACCOUNT,
  GET_COMPANIES,
  ADD_PLAID_ACCOUNT,
  UPLOAD_DOCUMENT,
  RESET_USER,
  ADD_COMPANIES,
  EDIT_USER,
  LOGOUT_USER,
  UPDATE_CLIENT_PROFILE
} from '../actions/actionTypes';

const initialState = {
  isLoggedIn: false
};

setAutoFreeze(false);

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL:
      return [...state];
    case GET_USER:
      return {
        ...action.user,
        isLoggedIn: true
      };
    case RESET_USER:
      return { ...action.user, isLoggedIn: true };
    case SET_USER:
      return { ...action.user, isLoggedIn: true };
    case EDIT_USER:
      return { ...state, ...action.user };
    case LOGOUT_USER:
      return initialState;
    case LOADING:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case EXCLUDE_PLAID_ACCOUNT:
      return produce(state, draftState => {
        draftState.companies[action.companyIndex].plaid_accounts[
          action.accountIndex
        ].excluded = action.excluded;
        draftState.loading = false;
        draftState.error = '';
      });
    case GET_COMPANIES:
      return produce(state, draftState => {
        draftState.companies = action.companies;
        draftState.loading = false;
        draftState.error = '';
      });
    case ADD_PLAID_ACCOUNT:
      return produce(state, draftState => {
        draftState.companies[action.companyIndex].plaid_accounts = [
          ...draftState.companies[action.companyIndex].plaid_accounts,
          ...action.accounts
        ];
        draftState.loading = false;
        draftState.error = '';
      });
    case UPLOAD_DOCUMENT:
      return produce(state, draftState => {
        draftState.companies[action.companyIndex].documents.push(
          action.documents
        );
        draftState.loading = false;
        draftState.error = '';
      });
    case ADD_COMPANIES:
      return produce(state, draftState => {
        draftState.companies = [...draftState.companies, ...action.companies];
        draftState.loading = false;
        draftState.error = '';
      });
    case UPDATE_CLIENT_PROFILE:
      return produce(state, draftState => {
        draftState.companies[action.companyIndex] = {
          ...draftState.companies[action.companyIndex],
          ...action.profile
        };
        draftState.loading = false;
        draftState.error = '';
      });
    default:
      return state;
  }
};
