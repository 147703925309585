import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from "@material-ui/core/InputBase";

const useStyles = makeStyles(theme => ({
	grow: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	search: {
		position: 'relative',
		borderRadius: '20px',
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25)
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: 'auto'
		},
		padding: '2px 10px',
		border: '1px solid #BDBDBD'
	},
	searchIcon: {
		width: theme.spacing(2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#9E9E9E'
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 3),
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: 200
		}
	}
}));
const SearchBox = ({value, onChange, onPress, placeholder}) => {
	const classes = useStyles();
  return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon fontSize={'large'} />
			</div>
			<InputBase
				value={value}
				placeholder={placeholder || 'Search…'}
				onChange = {onChange && (e => onChange(e))}
				onKeyPress = {onPress && (e => onPress(e))}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput
				}}
				inputProps={{ 'aria-label': 'search' }}
			/>
		</div>
  );
};

export default SearchBox;
