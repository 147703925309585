import React from 'react';
import styled from 'styled-components';
import { ContentCardShadowed} from '@tycoonlabs/web-components'
import { Link} from 'react-router-dom';
import BackIcon from '@material-ui/icons/ArrowBackOutlined';


const HeaderTitle = styled(({title, back, ...otherProps}) => (
	<div>
		{back && <Link {...otherProps}><BackIcon/>&nbsp;{back}</Link>}
		{title && <h1>{title}</h1>}
	</div>
))`
 	&& {
		display:flex;
		flex-direction: row;
		align-item: center;
		font-size: 1rem;
		height:2rem;
		margin-bottom: 4px; 
		color: #757575;
		font-weight: bold;
		cursor: pointer;
		&:hover{
			color:#4169e1;
		}
 }
`;
const HeaderActionLoader = styled.div`
	border: 12px solid #4169e1;
	border-radius: 50%;
	border-top: 12px solid #d4d4d4;
	width: 38px;
	height: 38px;
	margin-bottom: 0px;
	margin-right: 20px;
	animation: spin 2s linear infinite;
	@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
	}
`;
const HeaderActionContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: baseline;
	align-items: flex-end;
	.date-macro-options {
		display: -webkit-box;
		display: -moz-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-moz-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
		background-color: #282828;
		border-radius: 30px;
		color: #FFFFFF;
		padding: 10px 31px;
		justify-content: center;
		font-size: 12px;
		font-size: 1.2rem;
		cursor: pointer;
	}
	.tippy-tooltip {
		background-color: #ffffff;
		box-shadow: 0 10px 20px rgba(3, 49, 86, 0.2);
		color: #000000;

		.date-macro-line{
			cursor: pointer;
		}
		.date-picker-container {
			-webkit-box-align: center;
			-moz-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
			display: -webkit-box;
			display: -moz-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			font-size: 10px;
			font-size: 1rem;
			padding-right: 12px;
			width: 100%;
			.dates {
				font-size: 1.2rem;
			}
			input {
				-webkit-box-align: center;
				-moz-box-align: center;
				-ms-flex-align: center;
				-webkit-align-items: center;
				align-items: center;
				border: none;
				display: -webkit-box;
				display: -moz-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				text-align: center;
				width: 65px;
			}

			.InputFromTo
				.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
				background-color: #f0f8ff;
				color: #4a90e2;
			}

			.InputFromTo .DayPicker-Day {
				border-radius: 0;
			}

			.InputFromTo .DayPicker-Day--start {
				border-top-left-radius: 50%;
				border-bottom-left-radius: 50%;
			}

			.InputFromTo .DayPicker-Day--end {
				border-top-right-radius: 50%;
				border-bottom-right-radius: 50%;
			}

			.InputFromTo {
				display: -webkit-box;
				display: -moz-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-moz-box-align: center;
				-ms-flex-align: center;
				-webkit-align-items: center;
				align-items: center;
				-webkit-flex-wrap: wrap;
				-ms-flex-wrap: wrap;
				flex-wrap:wrap;
			}
		}

		.DayPickerInput-OverlayWrapper {
			margin-left: -100%;
		}
	}
`;
const ActionCard = styled(ContentCardShadowed)`
	cursor: pointer;
	&:hover {
		box-shadow: 0 10px 20px rgba(3, 49, 86, 0.5);
	}
`
const ReportEmpty = styled.div`
			color:#383838;
			text-align: center;
			padding:20px;
			padding:2rem;
			font-size: 16px;
			font-size: 1.6rem;
			background: #EFEFEF;
`;
const FilterSection = styled.div`
	display: flex;
	flex-direction: row;
	padding: 10px 20px;
	border-top: 1px solid #d4d4d4;
`;

export {
	HeaderTitle,
	HeaderActionLoader,
	HeaderActionContainer,
	ActionCard,
	ReportEmpty,
	FilterSection
}