/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../reusables/Button';
import Spinner from '../../reusables/Spinner';
import Session from '../../../utils/session';
import {
  REGISTER_MUTATION,
  EDIT_USER_ON_REGISTER
} from '../../../graphql/mutations';
import { addCompanies } from '../../../actions';

const AddSalesClient = () => {
  const [isRegistered, setIsRegistered] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [companies, setCompanies] = useState([true]);
  const [business, setBusiness] = useState({ 0: '' });
  const [returnedBusiness, setReturnedBusiness] = useState([]);
  const [companiesAdded, setCompaniesAdded] = useState([]);
  const [creds, setCreds] = useState({
    email: '',
    firstName: '',
    lastName: ''
  });

  const dispacth = useDispatch();
  // Newly created user
  const [userId, setUserId] = useState('');

  // Logged in user
  const id = Session.getUserId();

  const businessInputChange = e => {
    const { name, value } = e.target;
    setBusiness({ ...business, [name]: value });
  };

  const { firstName, lastName, email } = creds;

  const notifyError = () =>
    toast('Something went wrong! Please review your info and try again.');
  const notifySuccess = () =>
    toast(
      `User successfully registered! An activation email was sent to ${email}`
    );

  const handleChange = e => {
    const { name, value } = e.target;
    setCreds({ ...creds, [name]: value });
  };

  const addBusinessInput = () => {
    if (companies.length < 3) {
      setCompanies([...companies, true]);
    }
  };

  const pairIndexWithID = response => {
    const companyArr = [];
    const listOfCompanies = response.data.createUser.companies;
    if (listOfCompanies.length) {
      listOfCompanies.forEach(comp => {
        for (let [key, value] of Object.entries(business)) {
          if (value === comp.name) {
            companyArr.push({
              index: key,
              id: comp.id,
              name: comp.name
            });
          }
        }
      });
      setReturnedBusiness(companyArr);
    }
  };

  const substractBusinessInput = index => {
    if (companies.length > 1) {
      const arr = [...companies];
      arr.pop();
      setCompanies(arr);
      const obj = { ...business };
      delete obj[index];
      setBusiness(obj);
    }
  };

  const allCompaniesCreated = business => {
    const list = Object.values(business).map(company => ({
      name: company
    }));
    if (list.length === 1 && list[0].name === '') {
      return null;
    } else {
      return list;
    }
  };

  const allCompaniesEdited = companies => {
    if (companies.length === 1 && companies[0] === '') {
      return [];
    } else {
      const editedCompanies = [];
      companies.map(comp => {
        const companyName = business[comp.index];
        editedCompanies.push({ id: comp.id, name: companyName });
      });
      return editedCompanies;
    }
  };

  const onCompleted = data => {
    notifySuccess();
    setIsRegistered(true);
    setEditMode(false);
    setCompaniesAdded(data);
  };

  const [createUser, { loading, error }] = useMutation(REGISTER_MUTATION, {
    variables: {
      first_name: firstName,
      last_name: lastName,
      companies: allCompaniesCreated(business),
      email
    },
    onCompleted: data => onCompleted(data.createUser.companies),
    onError: () => notifyError()
  });

  const [editUser, { loading: editLoading }] = useMutation(
    EDIT_USER_ON_REGISTER,
    {
      variables: {
        user_id: userId,
        first_name: firstName,
        last_name: lastName,
        email,
        modified_by_id: id,
        companies: allCompaniesEdited(returnedBusiness)
      },
      onCompleted: data => onCompleted(data.editUserRegistration.companies),
      onError: () => notifyError()
    }
  );

  useEffect(() => {}, [isRegistered, companies, business]);

  const onFormSubmit = async e => {
    e.preventDefault();
    if (!isRegistered && !editMode) {
      const res = await createUser();
      if (res && res.data.createUser) {
        setUserId(res.data.createUser.id);
        pairIndexWithID(res);
      }
    } else if (isRegistered) {
      setEditMode(true);
      setIsRegistered(false);
    }
    if (editMode) {
      editUser();
    }
  };

  const resetForm = () => {
    setEditMode(false);
    setCompanies([true]);
    setBusiness({ 0: '' });
    setCreds({
      email: '',
      firstName: '',
      lastName: ''
    });
    setIsRegistered(false);
    dispacth(addCompanies(companiesAdded));
    setCompaniesAdded([]);
  };

  return (
    <main className='add-client-main'>
      <h1>Client Registration</h1>
      <div className='add-client-card'>
        {/* <div className='add-client-header'>
          <h1>New Client Registration</h1>
        </div> */}
        <form onSubmit={e => onFormSubmit(e)} className='add-client-form'>
          <div className='add-client-input'>
            {companies.map((company, index) => (
              <div key={`${company} ${index}`} className='add-client-business'>
                <div className='add-companies'>
                  <label>Business Name</label>
                  <input
                    name={[index]}
                    value={business[index] || ''}
                    onChange={businessInputChange}
                  />
                </div>
                <div className='add-substract-companies'>
                  <div onClick={addBusinessInput} className='counter-btn'>
                    +
                  </div>
                  <div
                    onClick={() => substractBusinessInput(index)}
                    className='counter-btn'
                  >
                    -
                  </div>
                </div>
              </div>
            ))}

            <label>First Name</label>
            <input name='firstName' value={firstName} onChange={handleChange} />

            <label>Last Name</label>
            <input name='lastName' value={lastName} onChange={handleChange} />

            <label>Email</label>
            <input
              name='email'
              value={email}
              onChange={handleChange}
              type='email'
            />
            {error && <p className='register-error'>{error.message}</p>}
          </div>
          <div className='add-client-actions'>
            {loading || (editLoading && <Spinner />)}
            {!isRegistered && !loading && (
              <Button
                classN='add-client-btn register-submit'
                type='submit'
                disabled={
                  !email.trim() ||
                  !firstName.trim() ||
                  !lastName.trim() ||
                  loading
                }
              >
                {editMode ? 'Update' : 'Register Client'}
              </Button>
            )}
            {isRegistered && !loading && (
              <>
                <Button
                  classN='add-client-btn continue-submit'
                  type='submit'
                  name='continue'
                  callback={resetForm}
                  disabled={
                    !email.trim() ||
                    !firstName.trim() ||
                    !lastName.trim() ||
                    loading
                  }
                >
                  Continue
                </Button>
                <Button
                  classN='add-client-btn add-client-cancel'
                  disabled={
                    !email.trim() ||
                    !firstName.trim() ||
                    !lastName.trim() ||
                    loading
                  }
                >
                  Edit
                </Button>
              </>
            )}
          </div>
          {isRegistered && !loading && !error && (
            <p className='register-success'>
              Please continue if everything is ok or go back and edit.
            </p>
          )}
        </form>
      </div>
    </main>
  );
};

export default AddSalesClient;
