import React from 'react';
import {useMutation} from 'react-apollo';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
import CardActions from '@material-ui/core/CardActions';
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import Grid from '@material-ui/core/Grid';
import {
	ContentCardShadowed, FormTextField, FormButton, ContentCardHeader, ContentCardContent
}  from '@tycoonlabs/web-components';
import {UPDATE_BOOKKEEPING_STATUS} from 'graphql/mutations';
import Session from 'utils/session';

const CompanyDetails = () => {
	const {companyId} = useParams();
	const company = Session.getCompany(companyId);
	const [updateBookkeeping, {}] = useMutation(UPDATE_BOOKKEEPING_STATUS, {
		onCompleted: data => {
			let updated = data.updateBookkeepingStatus;
			console.log("updateBookkeeping", data.updateBookkeepingStatus);
			company.last_touched = updated.last_touched;
			company.last_rec = updated.last_rec;
			toast.success('Client profile was updated successfully.');
		},
		onError: error => {
			console.log(error);
			toast.warn("Error while saving profile.")
		}
	});
	return (
		<Formik
			initialValues={{
				lastTouched: company.last_touched ? moment(company.last_touched).format('YYYY-MM-DD HH:mm') : '',
				lastRec: company.last_rec ? moment(company.last_rec).format('YYYY-MM-DD HH:mm') : '',
			}}
			validationSchema={Yup.object().shape({
				lastTouched: Yup.string()
					.test('lastTouched', 'Invalid date value.  Expecting YYYY-MM-DD HH:mm.', value => {
						return moment(value, 'YYYY-MM-DD HH:mm', true).isValid();
					}),
				lastRec: Yup.string()
					.test('lastRec', 'Please enter a validate date value in the format of YYYY-MM-DD HH:mm.', value => {
						return moment(value, 'YYYY-MM-DD HH:mm', true).isValid();
					})
			})}
			onSubmit={(values, {setSubmitting}) => {
				updateBookkeeping({
					variables: {
						input: {
							id: companyId,
							last_touched: moment(values.lastTouched),
							last_rec: moment(values.lastRec)
						}
					},
				})
			}}
		>
			{(props) => {
				const {
					values,
					touched,
					errors,
					handleChange,
					handleBlur,
					setFieldValue
				} = props;
				return (
					<Form>
						<ContentCardShadowed>
							<ContentCardHeader className={""}
																 title={"Bookkeeping Update"}
																 subheader={('Last file upload: ' + ((company.last_upload &&
																	 moment(company.last_upload).format('L [at] h:mm a')) ||
																	 'Never'))}
							/>
							<ContentCardContent className={"flex-col-ss "}>
								<p style={{padding: "0 0 16px 0", fontSize: "1.4rem"}}>Please provide bookkeeping updates if any.</p>
								<Grid container spacing={4} direction="row" justify="flex-start" alignItems="stretch">
									<Grid item xs={8}>
										<FormTextField
											error
											label="Last touched"
											name="lastTouched"
											value={values.lastTouched}
											placeholder="Enter last touched date"
											onChange={handleChange}
											helperText={(errors.lastTouched && touched.lastTouched) && errors.lastTouched}
											margin="dense"
										/>
									</Grid>
									<Grid item className={"flex-col justify-evenly"}>
										<span>set to</span>
										<FormButton variant="outlined" compact onClick={() => {
											setFieldValue("lastTouched", moment(new Date).format('YYYY-MM-DD HH:mm'))
										}}>Just Now</FormButton>
									</Grid>
								</Grid>
								<Grid container spacing={4} direction="row" justify="flex-start" alignItems="stretch">
									<Grid item xs={8}>
										<FormTextField
											error
											label="Last Reconciled"
											name="lastRec"
											value={values.lastRec}
											placeholder="Enter last reconcile date"
											onChange={handleChange}
											onBlur={handleBlur}
											helperText={(errors.lastRec && touched.lastRec) && errors.lastRec}
											margin="dense"
										/>
									</Grid>
									<Grid item className={"flex-col justify-evenly"}>
										<span>set to</span>
										<FormButton variant="outlined" compact onClick={() => {
											setFieldValue("lastRec", moment(new Date).format('YYYY-MM-DD HH:mm'))
										}}>Just Now</FormButton>
									</Grid>
								</Grid>
								<CardActions>
									<FormButton color="primary" type='submit' size={"medium"}>
										UPDATE
									</FormButton>
								</CardActions>
							</ContentCardContent>
						</ContentCardShadowed>
					</Form>
				);
			}}
		</Formik>
	);
};
export default CompanyDetails;
