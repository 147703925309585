import { GET_COMPANY, SET_COMPANY, LOGOUT_USER } from 'actions/actionTypes';

const initialState = {
  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return action.company;
    case LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
};
