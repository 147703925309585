import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-boost';
import dotenv from 'dotenv';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import store from './store';
import { ScrollToTop } from './helpers';

import './index.css';
import './app.css';
import configs from './config';

dotenv.config();
console.log('########API', configs.api.GRAPHQL_URI);
console.log('########WEB', configs.web.URI);
const client = new ApolloClient({
  uri: configs.api.GRAPHQL_URI,
  fetchOptions: {
    credentials: 'include'
  },
  request: operation => {
    const token = localStorage.getItem('authToken') || '';
    operation.setContext({
      headers: {
        Authorization: `JWT ${token}`
      }
    });
  },
  clientState: {
    defaults: {
      isLoggedIn: !!localStorage.getItem('authToken')
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ApolloProvider client={client}>
        <ScrollToTop />
        <Route render={props => <App {...props} />} />
      </ApolloProvider>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
