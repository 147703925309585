class Nano {
	assert(cond, msg) {
		if (!cond) {
			throw new Error(msg)
		}
	}
	exists(e) {
		if (typeof e == "string") {
			return e.length && e !== "undefined" && e !== "null"
		} else if (Array.isArray(e)) {
			return !!e.length
		} else if (typeof e == "object" && e != null) {
			return !!Object.keys(e).length
		} else {
			return typeof e != "undefined" && e != null
		}
	}
	empty(e) {
		return !this.exists(e)
	}
	default(...values) {
		return values.find(e => this.exists(e)) || null
	}
	boolean(e, value = false) {
		if (typeof e == "string") {
			return e === "true" || e === "1"
		} else {
			return this.exists(e) ? !!e : value
		}
	}
	clone(obj) {
		return this.exists(obj) ? JSON.parse(JSON.stringify(obj)) : obj
	}
	caps(s, options = {}) {
		if (!s) {
			return s
		}
		this.assert(typeof s == "string", "invlaid string: " + s);
		if (options.all) {
			let caps = [];
			for (let part of s.split(options.delim || /\s+|_/)) {
				caps.push(this.caps(part))
			}
			return caps.join(" ")
		} else {
			return s.length >= 3 ? s.charAt(0).toUpperCase() + s.substring(1) : s.toUpperCase()
		}
	}
	capsAll(s, options = {}) {
		let list = s ? s.split(options.delimAll || ",") : [s];
		list = list.map(e => {
			return this.caps(e, Object.assign({all: false}, options))
		});
		return list.join(",")
	}
	trim(s) {
		return s ? s.trim() : s
	}
	trunc(value, k) {
		return value && value.length > k ? value.substring(0, k) : value
	}
	shuffle(a) {
		for (let i = a.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1))
			;[a[i], a[j]] = [a[j], a[i]]
		}
		return a
	}
	uuid() {
		let d = new Date().getTime();
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
			let r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === "x" ? r : (r & 0x3) | 0x8).toString(16)
		})
	}
	dot(obj, key) {
		this.assert(obj, "obj is missing");
		this.assert(key, "key is missing");
		let parts = key.split(".");
		if (parts.length === 1) {
			return obj
		} else {
			for (let part of parts.slice(0, -1)) {
				let next = obj[part];
				if (!this.exists(next)) {
					obj[part] = next = {}
				}
				obj = next
			}
			return obj
		}
	}
	unique(list) {
		let map = {};
		return (list || []).filter((e, pos, arr) => {
			if (map[e]) {
				return false
			}
			map[e] = true;
			return true
		})
	}
}
export {Nano}
export default new Nano()
