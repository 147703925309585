import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { HeaderActionLoader,HeaderActionContainer} from 'components/reusables/AppStyles';

import AccountsTable from './AccountsTable';
import PlaidConnect from './PlaidConnect';
import { PageHeader, Page } from '@tycoonlabs/web-components'
import LinkAccountButton from './LinkAccountButton';
import {
  GET_PLAID_ACCOUNTS_FOR_COMPANY
} from 'graphql/queries';
const Accounts = () => {
  const { companyId } = useParams();
  const [accounts, setAccounts] = useState([]);

  const [fetchPlaidAccounts, { loading }] = useLazyQuery(GET_PLAID_ACCOUNTS_FOR_COMPANY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      console.log('### fetchPlaidAccounts result', data);
      let accounts = data.getSingleUserPlaidAccounts;
      setAccounts(accounts);
    },
    onError: e => {
      toast.error('Failed to load user.');
    }
  });

  const refetchAccounts = () =>{
    fetchPlaidAccounts({
      variables: { company_id: companyId }
    });
  }

  useEffect(() => {
    console.log('### fetchPlaidAccounts');
    fetchPlaidAccounts({
      variables: { company_id: companyId }
    });
  }, []);


  return (
    <>
      <PageHeader>
        <h1>Accounts</h1>
        <HeaderActionContainer>
          {loading && (
            <HeaderActionLoader />
          )}
          <LinkAccountButton refresh={refetchAccounts}/>
        </HeaderActionContainer>
      </PageHeader>
      <Page className='accounts-container'>
        {!loading && (
            <>
              {accounts.length ? <AccountsTable accounts={accounts} refresh={refetchAccounts} /> : <PlaidConnect  refresh={refetchAccounts}/>}
            </>
          )}
      </Page>
    </>
  );
};

export default Accounts;
