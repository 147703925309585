import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import UploadDocument from './UploadDocument';
import Documents from './Documents';
import { PrimaryButton } from '@tycoonlabs/web-components'

const UploadButton = () => {
  const history = useHistory();
  const { companyId } = useParams();
  const handleClick = () => {
    history.push(`/documents/${companyId}/new`);
  };
  return (
    <>
      <PrimaryButton size="medium" color="secondary" onClick={handleClick} >
          Upload
        </PrimaryButton>
    </>
  );
};

export default UploadButton;
