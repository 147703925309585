import produce, { setAutoFreeze } from 'immer';

import { SET_USER, LOGOUT_USER, GET_COMPANIES } from 'actions/actionTypes';

const initialState = {
  isLoggedIn: false
};

setAutoFreeze(false);

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...action.user, isLoggedIn: true };
    case LOGOUT_USER:
      return initialState;
    case GET_COMPANIES:
      return produce(state, draftState => {
        draftState.companies = action.companies;
        draftState.loading = false;
        draftState.error = '';
      });
    default:
      return state;
  }
};
