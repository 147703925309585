import React from 'react';
import SearchBox from "../reusables/SearchBox";
import SettingsInputIcon from '@material-ui/icons/TuneOutlined';
import { FilterSection } from 'components/reusables/AppStyles';
import { FormButton } from 'components/reusables/Contents';
const ClientsActions = ({search, onSearchChange, onSearchPressed, handleOpenDrawer}) => {
	return (
		<>
      <FilterSection>
			<div className='flex flex-row-sc  '>
					<SearchBox value={search} onChange={onSearchChange} onPress={onSearchPressed}>
					</SearchBox>
					<FormButton
            color={'primary'}
            size={'small'}
            fill={'#757575'}
            startIcon={<SettingsInputIcon />}
            onClick={() => handleOpenDrawer('filter')}
          >
            Filter
          </FormButton>
				</div>
			</FilterSection>

		</>
	);
};
export default ClientsActions;
