import { combineReducers } from 'redux';

import originalUser from './originalUserReducer';
import currentUser from './currentUserReducer';
import company from './companyReducer';
import modal from './modalReducer';
import resize from './resizeReducer';

const rootReducer = combineReducers({
  originalUser,
  currentUser,
  company,
  modal,
  resize
});

export default rootReducer;
