import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import CardHeader from '@material-ui/core/CardHeader';
import { toast } from 'react-toastify';
import {
  RightDrawer,
  DrawerCard,
  FormButton,
  FormTextField,
  DrawerCardContent,
  DrawerCardActions,
  DrawerCloseButton
} from 'components/reusables/Contents';
import { useMutation } from '@apollo/react-hooks';
import { EDIT_CLIENT } from '../../graphql/client';
import useWindowDimensions from '../reusables/WindowSize';
import { CLIENT } from '../../utils/roles';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import Nano from '../../utils/nano';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const ClientDrawer = ({
  openDrawer,
  setOpenDrawer,
  selectedClient,
  refreshClient,
  inputVariables
}) => {
  const [client, setClient] = useState({});
  const history = useHistory();
  const { width, height } = useWindowDimensions();

  const [editClient, {}] = useMutation(EDIT_CLIENT, {
    variables: {
      input: client
    },
    onCompleted: data => {
      console.log('CLIENT SAVED', data);
      toast.success('Client info saved.', data);
      setOpenDrawer(false);
      if (refreshClient) {
        refreshClient({
          variables: inputVariables
        });
      } else {
        history.push('/platform/client');
      }
    },
    onError: error => {
      try{
        let firstError = error.graphQLErrors && error.graphQLErrors.length > 0 && error.graphQLErrors[0];
        if(firstError){
          toast.error(firstError.message);
        }else{
          toast.error('Error occurred. Please try again or contact support.');
        }
      }catch(e){
        console.log('ERROR OCCURRED WHILE TRYING TO SAVE CLIENT', error);
      }
    }
  });
  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    setClient({ ...client, [name]: value });
  };
  const handleSubmit = e => {
    e.preventDefault();
    console.log('Saving client', client);
    editClient();
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const initValues = () => {
    return {
      id: selectedClient.id,
      name: selectedClient.name || '',
      customer_num: selectedClient.customer_num || '',
      logo_url: selectedClient.logo_url || '',
      website: selectedClient.website || '',
      email: selectedClient.email || '',
      phone: selectedClient.phone || '',
      address: selectedClient.address || '',
      address_two: selectedClient.address_two || '',
      city: selectedClient.city || '',
      state: selectedClient.state || '',
      postal_code: selectedClient.postal_code || '',
      country: selectedClient.country || '',
      removed: selectedClient.removed
    };
  };
  const form = () => {
    console.log('### Setup form with selected client', selectedClient);
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('name', 'Name is required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          editClient({
            variables: {
              input: {
                id: selectedClient.id,
                name: values.name,
                customer_num: values.customer_num,
                website: values.website,
                logo_url: values.logo_url,
                email: values.email,
                phone: values.phone,
                address: values.address,
                address_two: values.address_two,
                city: values.city,
                state: values.state,
                postal_code: values.postal_code,
                country: values.country,
                removed: values.removed
              }
            }
          });
        }}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue
          } = props;
          const handleChecked = value => {
            setFieldValue('removed', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete={'nope'}>
              <DrawerCard className={''} style={{ height: `${height}px` }}>
                <CardHeader
                  title={selectedClient.id ? 'Edit Client' : 'Add Client'}
                  action={
                    <DrawerCloseButton
                      aria-label='Close'
                      onClick={() => handleClose()}
                    />
                  }
                />
                <DrawerCardContent>
                  <FormTextField
                    error
                    margin='dense'
                    name='name'
                    label='Company Name'
                    placeholder='Enter company name'
                    value={values.name}
                    inputProps={{maxLength:250}}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    name='customer_num'
                    label='Customer #'
                    inputProps={{maxLength:50}}
                    value={values.customer_num}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    name='logo_url'
                    label='Logo URL'
                    value={values.logo_url}
                    onChange={handleChange}
                    inputProps={{maxLength:512}}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    name='website'
                    label='Website'
                    inputProps={{maxLength:512}}
                    value={values.website}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    inputProps={{maxLength:254}}
                    name='email'
                    label='Email'
                    value={values.email}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    inputProps={{maxLength:50}}
                    name='phone'
                    label='Phone'
                    value={values.phone || ''}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    inputProps={{maxLength:255}}
                    name='address'
                    label='Address'
                    value={values.address}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    inputProps={{maxLength:255}}
                    name='address_two'
                    label='Address'
                    value={values.address_two}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    inputProps={{maxLength:255}}
                    name='city'
                    label='City'
                    value={values.city}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    inputProps={{maxLength:255}}
                    name='state'
                    label='State'
                    value={values.state}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    inputProps={{maxLength:50}}
                    name='postal_code'
                    label='Postal Code'
                    value={values.postal_code}
                    onChange={handleChange}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    name='country'
                    label='Country'
                    inputProps={{maxLength:2}}
                    value={values.country}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='removed'
                        checked={values.removed}
                        onChange={() => handleChecked(!values.removed)}
                      />
                    }
                    label='Inactive'
                  />
                </DrawerCardContent>
                <DrawerCardActions>
                  <FormButton color='primary' type='submit' size={'medium'}>
                    Submit
                  </FormButton>
                  <FormButton onClick={handleClose}>Cancel</FormButton>
                </DrawerCardActions>
              </DrawerCard>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div>
      <RightDrawer
        variant='temporary'
        width={400}
        anchor='right'
        open={openDrawer}
        onClose={handleClose}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default ClientDrawer;
