import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import {
	RightDrawer,
  FormTextField,
  DrawerCardActions,
  FormButton,
  DrawerCard,
  DrawerCloseButton
} from 'components/reusables/Contents';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const initialFomState = {
  firstName: '',
  lastName: '',
  email: '',
  admin: false
};

const useStyles = makeStyles({
  formContainer: {
    width: '400px'
  }
});
const AddUserDrawer = ({ open, setOpen, addTeamMember }) => {
  const classes = useStyles();
  const { companyId } = useParams();
  const [form, setForm] = useState(initialFomState);

  const handleClose = () => {
    setForm(initialFomState);
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setForm({ ...form, [name]: val });
  };

  const handleSubmit = e => {
    e.preventDefault();
    addTeamMember({
      variables: {
        company_id: companyId,
        first_name: form.firstName,
        last_name: form.lastName,
        email: form.email,
        admin: form.admin
      }
    });
  };

  const Form = () => (
    <div className={classes.formContainer}>
      <DrawerCard>
        <CardHeader
          title='Add Team Member'
          action={
            <DrawerCloseButton aria-label='Close' onClick={handleClose} />
          }
        />
        <form onSubmit={handleSubmit}>
          <div>
            <FormTextField
              error
              name='firstName'
              label='First Name'
              placeholder='Enter First Name'
              value={form.firstName}
              onChange={handleChange}
            />
          </div>
          <div>
            <FormTextField
              error
              name='lastName'
              label='Last Name'
              placeholder='Enter Last Name'
              value={form.lastName}
              onChange={handleChange}
            />
          </div>
          <div>
            <FormTextField
              error
              name='email'
              label='Email'
              placeholder='Enter Email Address'
              value={form.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  name='admin'
                  checked={form.admin}
                  onChange={handleChange}
                />
              }
              label='Grant Admin Access'
            />
          </div>
          <DrawerCardActions>
            <FormButton color='primary' type='submit' size={'medium'}>
              Submit
            </FormButton>
            <FormButton onClick={handleClose}>Cancel</FormButton>
          </DrawerCardActions>
        </form>
      </DrawerCard>
    </div>
  );

  return (
    <RightDrawer
      variant='temporary'
			width={400}
      anchor='right'
      open={open}
      onClose={handleClose}
    >
      {Form()}
    </RightDrawer>
  );
};

export default AddUserDrawer;
