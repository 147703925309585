import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';

import PlaidAuth from './PlaidConnect/PlaidAuth';
import { ReactComponent as WarningIcon } from 'assets/error-exclamation.svg';
import Session from '../../utils/session';
import { ActionCard } from 'components/reusables/AppStyles';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardActions';

import { excludePlaidAccount } from 'actions';

import {
  EXCLUDE_PLAID_ACCOUNT,
  REAUTHENTICATE_PLAID_ACCOUNT
} from 'graphql/mutations';

import { ADMIN, CS, ACCOUNTANT, ASSOCIATE, CLIENT, SALES } from 'utils/roles';

const AccountsTable = ({ accounts, refresh }) => {
  // Utils
  const { companyId } = useParams();
  const dispatch = useDispatch();

  // Global State
  const role = Session.getRole();
  const modified_by_id = Session.getUserId();
  const companyIndex = Session.getCompanyIndex(companyId);

  // Local State
  const [publicToken, setPublicToken] = useState(null);

  const activeAccounts = accounts.filter(account => account.account_id);
  const inactiveAccounts = accounts.filter(
    account => account.inactive && !account.excluded
  );

  // GraphQL Queries and Mutations
  const [reauthenticate, { loading: authload }] = useMutation(
    REAUTHENTICATE_PLAID_ACCOUNT,
    {
      variables: { account_id: inactiveAccounts[0] && inactiveAccounts[0].id },
      onCompleted: ({ reauthenticateAccount }) => {
        setPublicToken(reauthenticateAccount.public_token);
      }
    }
  );

  const [excludeAccount, { loading }] = useMutation(EXCLUDE_PLAID_ACCOUNT, {
    onCompleted: data => {
      const accountIndex = accounts.findIndex(
        account =>
          account.account_id === data.excludePlaidAccount.plaid_account_id
      );
      refresh();
      // dispatch(
      //   excludePlaidAccount(
      //     companyIndex,
      //     accountIndex,
      //     data.excludePlaidAccount.excluded
      //   )
      // );
    }
  });

  // Lifecycle Methods
  useEffect(() => {
    if (inactiveAccounts.length) {
      reauthenticate();
    }
  }, [accounts]);

  return (
    <Grid container spacing={5}>
      {publicToken && <PlaidAuth publicToken={publicToken} />}
      {/* TODO: !accounts.length */}
      {accounts.length > 0 &&
        activeAccounts
          .sort(
            (a, b) =>
              a.excluded - b.excluded ||
              a.institution_name.localeCompare(b.institution_name)
          )
          .map(account => (
            <Grid item xs={12} sm={4} key={account.id}>
              <ActionCard>
                <CardHeader
                  title={`${account.institution_name} - ${account.name}`}
                  subheader={
                    <div className="flex-row-sc justify-between">
                      <div>
                        {account.subtype.toUpperCase()}
                      </div>
                      {account.excluded && (
                        <div>EXCLUDED</div>
                      )}
                    </div>
                  }
                />
                <CardContent className="flex">
                  <div className="flex flex-row-cc">
                    <h2>
                      {account.mask
                        ? `**** **** **** ${account.mask}`
                        : '**** **** **** ****'}
                      {account.mask && account.official_name ? ' - ' : ''}
                      {account.official_name ? `${account.official_name}` : ''}
                    </h2>
                  </div>
                </CardContent>
                <CardActions disableSpacing className={"flex flex-row justify-between"}>
                  {!account.excluded ? (
                    <>
                      <div>
                        <Link to={`/bookkeeping/${companyId}/accounts/${account.id}/balances`}>
                          <Button variant="outlined" color="primary">Balance</Button>
                        </Link>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Link to={`/bookkeeping/${companyId}/accounts/${account.id}/transactions`}>
                          <Button variant="outlined" color="primary">Transactions</Button>
                        </Link>
                      </div>
                    </>
                  ) : (
                      ''
                    )}
                  <div className='account-action'>
                    {role === ADMIN || role === CS || role === CLIENT ? (
                      <Button color="secondary"
                        onClick={() =>
                          excludeAccount({
                            variables: {
                              modified_by_id,
                              plaid_account_id: account.id
                            }
                          })
                        }
                      >
                        {account.excluded ? 'Include' : 'Exclude'}
                      </Button>
                    ) : (
                        ''
                      )}
                  </div>
                </CardActions>
              </ActionCard>
            </Grid>
          ))}
    </Grid>
  );
};

export default AccountsTable;
