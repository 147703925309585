import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import FileThis from '../FileThis';
import StatementsTable from './StatementsTable';

import { GET_FILETHIS, DOWNLOAD_STATEMENT } from 'graphql/queries';

const Statements = () => {
  const { companyId } = useParams();

  const [statements, setStatements] = useState(null);

  const { loading } = useQuery(GET_FILETHIS, {
    fetchPolicy: 'no-cache',
    variables: { company_id: companyId },
    onCompleted: data => {
      setStatements(data.getCompanyStatements);
    }
  });

  const [downloadFile, {}] = useLazyQuery(DOWNLOAD_STATEMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ downloadStatement }) =>
      window.open(downloadStatement, '_blank')
  });

  const handleDownload = id => {
    downloadFile({
      variables: {
        id: id
      }
    });
  };

  useEffect(() => {}, [statements]);

  return (
    <div className='statements-container'>
      {statements ? (
        <>
          {statements.length > 0 ? (
            <StatementsTable
              statements={statements}
              handleDownload={handleDownload}
            />
          ) : (
            <FileThis />
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default Statements;
