/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import Tippy from '@tippy.js/react';
import { exportTransactions } from 'helpers';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SettingsInputIcon from '@material-ui/icons/TuneOutlined';
import Select from 'components/reusables/Select';
import AddTransactions from './AddTransactions';
import { FormButton } from 'components/reusables/Contents';
import CSVReader from 'react-csv-reader';
import SearchBox from 'components/reusables/SearchBox';
import styled from 'styled-components';
import Session from 'utils/session';
import { ADMIN } from 'utils/roles';
import { FilterSection } from 'components/reusables/AppStyles';

const MenuLink = styled.div`
  margin: 8px 0;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TransactionsActions = ({
  transactions,
  onSearchChange,
  syncQBData,
  setSelectedIndex2,
  selectedIndex2,
  setOpenDrawer,
  handleNewTransaction,
  selectedRows,
  handleLoaded,
  papaparseOptions,
  setSelectDropdown,
  handePushTransactions,
  handleOpenDrawer
}) => {
  const role = Session.getRole();
  const { companyId } = useParams();
  const history = useHistory();
  const anchorRef = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const [showModal, setShowModal] = useState(false);
  const options = [
    'Add',
    'Edit',
    'Push Selected',
    'Push All',
    'Process Selected',
    'Process All',
    'Train AI',
    'Export',
    'Import',
    'Sync'
  ];
  const options2 = [
    'Select',
    'Select All',
    'Select All New',
    'Select All Processed'
  ];
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open2, setOpen2] = React.useState(false);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectDropdown(true);
    setSelectedIndex2(index);
    setOpen2(false);
  };
  const handleToggle2 = () => {
    setOpen2(prevOpen => !prevOpen);
  };
  const handleClose2 = event => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    setOpen2(false);
  };
  return (
    <>
      <FilterSection>
        <div className='flex flex-row-sc  '>
          <div className='flex-row-cc '>
            <ButtonGroup
              variant='text'
              ref={anchorRef2}
              aria-label='split button'
            >
              <Button
                size='small'
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label='select merge strategy'
                aria-haspopup='menu'
                onClick={handleToggle2}
              >
                <CheckBoxOutlineBlankIcon
                  fontSize={'large'}
                  color={'disabled'}
                />
                <ArrowDropDownIcon fontSize={'large'} color={'disabled'} />
              </Button>
            </ButtonGroup>
            <Popper
              placement={'bottom-start'}
              open={open2}
              anchorEl={anchorRef2.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: 2 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose2}>
                      <MenuList id='split-button-menu'>
                        {options2.map((option, index) => (
                          <MenuItem
                            key={option}
                            disabled={index === 0}
                            selected={index === selectedIndex2}
                            onClick={event => handleMenuItemClick(event, index)}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <SearchBox onChange={e => onSearchChange(e)} />
          <FormButton
            color={'primary'}
            size={'small'}
            fill={'#757575'}
            startIcon={<SettingsInputIcon />}
            onClick={() => handleOpenDrawer('filter')}
          >
            Filter
          </FormButton>
        </div>
        <div className=' flex-row-ec'>
          <Tippy
            arrow={false}
            content={
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column'
                  // padding: '6px 12px'
                }}
              >
                {role === ADMIN && <MenuLink>Edit Selected</MenuLink>}
                {role === ADMIN && <MenuLink>Request Input</MenuLink>}
                <MenuLink onClick={() => handePushTransactions('selected')}>
                  Push Selected
                </MenuLink>
                <MenuLink onClick={() => handePushTransactions('all')}>
                  Push All
                </MenuLink>
                {role === ADMIN && <MenuLink>Process Selected</MenuLink>}
                {role === ADMIN && <MenuLink>Process All</MenuLink>}
                {role === ADMIN && <MenuLink>Train AI</MenuLink>}
                <MenuLink onClick={() => exportTransactions(selectedRows)}>
                  Export
                </MenuLink>
                <MenuLink>
                  <CSVReader
                    label='Import'
                    onFileLoaded={handleLoaded}
                    parserOptions={papaparseOptions}
                    inputId='transaction-import'
                  />
                </MenuLink>
                <MenuLink onClick={syncQBData}>Sync</MenuLink>
              </div>
            }
            interactive
            placement='bottom-end'
            trigger='click'
          >
            <div className='other-icon'>
              <FormButton endIcon={<ArrowDropDownIcon fontSize={'large'} />}>
                BULK ACTION
              </FormButton>
            </div>
          </Tippy>
          <Tippy
            arrow={false}
            zIndex={3}
            content={
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  padding: '6px 12px'
                }}
              >
                <MenuLink onClick={() => handleOpenDrawer('accounts')}>
                  Accounts
                </MenuLink>
                <MenuLink onClick={() => handleOpenDrawer('categories')}>
                  Categories
                </MenuLink>
                <MenuLink onClick={() => handleOpenDrawer('vendors')}>
                  Payees
                </MenuLink>
                <MenuLink onClick={() => console.log('This ran')}>
                  Sample CSV
                </MenuLink>
              </div>
            }
            interactive
            placement='bottom-end'
            trigger='click'
          >
            <div className='other-icon'>
              <FormButton endIcon={<ArrowDropDownIcon fontSize={'large'} />}>
                SETTINGS
              </FormButton>
            </div>
          </Tippy>
        </div>
        </FilterSection>
      {showModal && <AddTransactions toggleModal={setShowModal} />}
    </>
  );
};
export default TransactionsActions;
