import React, { useMemo } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';

import Paginator from '../../reusables/Paginator';
import {DataTableStyles} from "../../reusables/styles";

const BalancesTable = ({ balances, loading }) => {
  const columns = useMemo(
    () => [
      {
        name: 'DATE',
        selector: 'date',
        sortable: true,
        cell: row => {
          return `${moment(row.date).format('L')}`;
        }
      },
      {
        name: 'BALANCE',
        selector: 'balance',
        center: true,
        cell: row => {
          return `${new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
          }).format(row.balance)}`;
        }
      },
      {
        name: 'LAST UPDATED',
        selector: 'timestamp',
        right: true,
        wrap: true,
        cell: row => {
          return row.timestamp ? moment(row.timestamp).format('lll') : '';
        }
      }
    ],
    []
  );

  return (
    <>
      <div className='I-just-did'>
        {balances.length > 0 && (
          <DataTable
            columns={columns}
            data={balances}
            defaultSortField='date'
            defaultSortAsc={false}
            pagination={balances && balances.length > 25}
            // paginationComponent={Paginator}
            paginationPerPage={25}
            noHeader
            highlightOnHover
						customStyles={DataTableStyles}
          />
        )}
      </div>
      {!loading && !balances.length && (
        <div className='table-empty' style={{ textAlign: 'center' }}>
          There are no records to display
        </div>
      )}
    </>
  );
};

export default BalancesTable;
