import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

let myLineChart;

const LineChart = ({ report }) => {
  const chartRef = useRef();

  const drawChart = () => {
    const ctx = chartRef.current.getContext('2d');

    if (typeof myLineChart !== 'undefined') myLineChart.destroy();

    if (report) {
      myLineChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: report.months || null,
          datasets: [
            {
              borderColor: '#098b8c',
              pointBackgroundColor: '#098b8c',
              label: 'Income',
              fill: false,
              data: report.income || null
            },
            {
              borderColor: '#f16549',
              pointBackgroundColor: '#f16549',
              label: 'Expenses',
              fill: false,
              data: report.expenses.map(el => (el * -1).toString()) || null
            },
            {
              borderColor: '#0e4d92',
              pointBackgroundColor: '#0e4d92',
              label: 'Profit',
              fill: false,
              data: report.profit || null
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            display: false
          },
          animation: {},
          tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
              label: (tooltipItem, data) => {
                const text = `${
                  data.datasets[tooltipItem.datasetIndex].label
                }: $${Number(tooltipItem.yLabel).toLocaleString('en-US', {
                  minimumFractionDigits: 2
                })} for ${tooltipItem.xLabel}`;
                return text;
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: value => {
                    return `$${Number(value).toLocaleString('en-US', {
                      minimumFractionDigits: 2
                    })}`;
                  }
                }
              }
            ]
          }
        }
      });
    }
  };

  useEffect(() => {
    drawChart();
  }, [report]);

  return (
    <div className='line-chart'>
      <canvas ref={chartRef} height='300' />
    </div>
  );
};

export default LineChart;
