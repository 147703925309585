import React, { useState, useEffect } from 'react';
import Drawer from '@material-ui/core/Drawer';
import CardHeader from '@material-ui/core/CardHeader';
import {
  DrawerContent,
  DrawerCard,
  DrawerSecondaryCard,
  DrawerCloseButton
} from 'components/reusables/Contents';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { EDIT_QB_ACCOUNT, EDIT_QB_VENDOR } from 'graphql/mutations';
import AccountsTable from './AccountsTable';
import VendorTable from './VendorTable';
import SettingsForm from './SettingsForm';
import useWindowDimensions from 'components/reusables/WindowSize';
import { DrawerCardContent } from 'components/reusables/Contents';
const initialAccountState = {
  id: '',
  qb_id: '',
  name: '',
  description: '',
  account_type: '',
  account_subtype: '',
  active: false
};
const initialVendorState = {
  id: '',
  qb_id: '',
  display_name: '',
  print_name: '',
  active: false
};
const SettingsDrawer = ({
  accounts,
  categories,
  vendors,
  openDrawer,
  setOpenDrawer,
  accountTypes,
  updateAccounts,
  updateVendors,
  drawer
}) => {
  const { width, height } = useWindowDimensions();
  const useStyles = makeStyles({
    drawerPaper: {
      width: width - 72,
      height: '100%'
    }
  });
  const { companyId } = useParams();
  const classes = useStyles();
  const [selectedAccount, setSelectedAccount] = useState(initialAccountState);
  const [selectedVendor, setSelectedVendor] = useState(initialVendorState);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editAccount, {}] = useMutation(EDIT_QB_ACCOUNT, {
    variables: {
      company_id: companyId,
      qb_id: selectedAccount.qb_id,
      name: selectedAccount.name,
      description: selectedAccount.description
        ? selectedAccount.description
        : '',
      account_type: selectedAccount.account_type,
      account_subtype: selectedAccount.account_subtype,
      active: selectedAccount.active
    },
    onCompleted: data => {
      updateAccounts();
    }
  });
  const [editVendor, {}] = useMutation(EDIT_QB_VENDOR, {
    variables: {
      company_id: companyId,
      qb_id: selectedVendor.qb_id,
      display_name: selectedVendor.display_name,
      print_name: selectedVendor.print_name,
      active: selectedVendor.active
    },
    onCompleted: data => {
      updateVendors();
    }
  });
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const handleRowClick = row => {
    drawer === 'vendors'
      ? setSelectedVendor({ ...selectedVendor, ...row })
      : setSelectedAccount({ ...selectedAccount, ...row });
    if (!edit) {
      setEdit(true);
    }
  };
  const tableTitle =
    drawer === 'accounts'
      ? 'Accounts'
      : drawer === 'categories'
      ? 'Categories'
      : 'Payees';
  const formTitle = () => {
    if (edit) {
      return drawer === 'accounts'
        ? 'Edit Account'
        : drawer === 'categories'
        ? 'Edit Category'
        : 'Edit Payee';
    } else {
      return drawer === 'accounts'
        ? 'Add Account'
        : drawer === 'categories'
        ? 'Add Category'
        : 'Add Payee';
    }
  };
  const formContent = () => {
    if (drawer === 'accounts') {
      return (
        <SettingsForm
          accounts={accounts}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          openDrawer={openDrawer}
          accountTypes={accountTypes}
          editAccount={editAccount}
          initialAccountState={initialAccountState}
          drawer={drawer}
        />
      );
    } else if (drawer === 'categories') {
      return (
        <SettingsForm
          accounts={categories}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          openDrawer={openDrawer}
          drawer={drawer}
          accountTypes={accountTypes}
          editAccount={editAccount}
          initialAccountState={initialAccountState}
        />
      );
    } else if (drawer === 'vendors') {
      return (
        <SettingsForm
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          openDrawer={openDrawer}
          drawer={drawer}
          editVendor={editVendor}
          initialVendorState={initialVendorState}
        />
      );
    }
  };
  const tableContent = () => {
    if (drawer === 'accounts') {
      return (
        <AccountsTable accounts={accounts} handleRowClick={handleRowClick} />
      );
    } else if (drawer === 'categories') {
      return (
        <AccountsTable accounts={categories} handleRowClick={handleRowClick} />
      );
    } else if (drawer === 'vendors') {
      return <VendorTable vendors={vendors} handleRowClick={handleRowClick} />;
    }
  };
  const settingsView = () => (
    <DrawerContent>
      <DrawerSecondaryCard
        style={{ width: width - (width - 72) * 0.7, height: `${height}px` }}
      >
        <CardHeader title={formTitle()} style={{ height: '78px' }} />
        {formContent()}
      </DrawerSecondaryCard>
      <DrawerCard
        className={''}
        style={{ width: '100%', height: `${height}px` }}
      >
        <CardHeader
          title={tableTitle}
          action={
            <DrawerCloseButton
              aria-label='Close'
              onClick={() => setOpenDrawer(false)}
            />
          }
        />
        <DrawerCardContent>
          <div style={{ width: '100%' }}>{tableContent()}</div>
        </DrawerCardContent>
      </DrawerCard>
    </DrawerContent>
  );
  return (
    <div>
      <Drawer
        variant='temporary'
        anchor='right'
        open={openDrawer}
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {settingsView()}
      </Drawer>
    </div>
  );
};
export default SettingsDrawer;
