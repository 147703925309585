import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

let vendorDoughnutChart;

const CHART_COLORS = [
  '#111E6C',
  '#1D2951',
  '#003152',
  '#0E4D92',
  '#0080FF',
  '#008ECC',
  '#6593F5',
  '#73C2FB',
  '#89CFF0',
  '#89CFF0'
];

const VendorDoughnutChart = ({ report }) => {
  const chartRef = useRef();

  const refs = [];

  const handleOnHover = (chart, index, isHovering) => {
    const segment = chart.getDatasetMeta(0).data[index];
    isHovering
      ? chart.updateHoverStyle([segment], null, true)
      : chart.updateHoverStyle([segment], null, false);
    chart.draw();
  };

  const drawChart = () => {
    const ctx = chartRef.current.getContext('2d');

    if (typeof vendorDoughnutChart !== 'undefined')
      vendorDoughnutChart.destroy();

    let current = null;
    if (report) {
      const sortedData = report && report.sort((a, b) => b.value - a.value);

      vendorDoughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: (sortedData && sortedData.map(obj => obj.value)) || null,
              backgroundColor: CHART_COLORS,
              label: 'Dataset 1'
            }
          ],
          labels: (sortedData && sortedData.map(obj => obj.label)) || null
        },
        options: {
          aspectRatio: 1,
          maintainAspectRatio: false,
          responsive: true,
          animation: {
            animateScale: true,
            animateRotate: true,
            duration: 1500
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          legend: {
            display: false
          },
          title: {
            display: false
          },
          tooltips: {
            enabled: false
          },
          onHover: e => {
            const elements = vendorDoughnutChart.getElementAtEvent(e);
            const element = elements.length === 0 ? null : elements[0];

            const previous = current;

            if (e.type === 'mouseout' || element === null) {
              current = null;
            } else {
              // eslint-disable-next-line no-underscore-dangle
              current = element._index;
            }

            if (current !== previous) {
              if (previous !== null) {
                refs[previous].classList.remove('doughnut-active');
              }
              if (current !== null) {
                refs[current].classList.add('doughnut-active');
              }
            }
          }
        }
      });
      return vendorDoughnutChart;
    }
    return null;
  };

  useEffect(() => {
    drawChart();
  }, [report]);

  return (
    <div className='doughnut-chart'>
      <div className='canvas-container'>
        <canvas ref={chartRef} width='100%' height='100%' />
      </div>
      <div className='doughnut-data'>
        {report &&
          report.map((obj, index) => (
            <div
              key={`${obj.label}-${obj.value}-${index}`}
              className='value-and-label'
              ref={element => {
                refs[index] = element;
              }}
              onMouseEnter={() => handleOnHover(drawChart(), index, true)}
              onMouseLeave={() => handleOnHover(drawChart(), index, false)}
            >
              <div className='data-value'>
                <span
                  style={{
                    color: `${CHART_COLORS[index]}`,
                    fontWeight: 600
                  }}
                >
                  ◼
                </span>
                <span>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(obj.value)}
                </span>
              </div>
              <div className='data-label'>
                <span>{obj.label}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default VendorDoughnutChart;
