import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import { PageHeader, FullPage, PrimaryButton } from '@tycoonlabs/web-components'
import { HeaderTitle, HeaderActionLoader, HeaderActionContainer } from 'components/reusables/AppStyles';

import TransactionsActions from './TransactionsActions';
import TransactionsTable from './TransactionsTable';
import Spinner from '../../reusables/Spinner';
import Session from '../../../utils/session';
import { exportBalancesToCSV } from 'helpers';

import { FETCH_TRANSACTIONS_QUERY } from '../../../graphql/queries';

const Transactions = () => {
  // DATE PICKER HANDLERS AND STATE FOR FILTER
  const [dateRange, setDateRange] = useState({
    from: moment()
      .subtract(1, 'months')
      .toDate(),
    to: moment().toDate()
  });
  const [pickerRef, setPickerRef] = useState(null);

  const showFromMonth = () => {
    const { from, to } = dateRange;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      pickerRef.getDayPicker().showMonth(from);
    }
  };

  const handleFromChange = from => {
    setDateRange({ ...dateRange, from });
  };

  const handleToChange = to => {
    setDateRange({ ...dateRange, to });
  };

  const handleFromTodayButton = from => {
    setDateRange({ ...dateRange, from });
    pickerRef.getInput().focus();
  };

  const handleToTodayButton = to => {
    setDateRange({ ...dateRange, to });
    pickerRef.hideDayPicker();
  };

  const { companyId, accountId } = useParams();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [search, setSearch] = useState('');

  const onSearchChange = e => {
    e.preventDefault();
    setSearch(e.target.value);
  };

  const notifyError = () =>
    toast.error(
      'Something went wrong retrieving transactions, please try again.'
    );

  const notifyWarn = () =>
    toast.warn('unable to pull latest transactions, please try again later.');

  const account = Session.getPlaidAccount(companyId, accountId);

  const { loading } = useQuery(FETCH_TRANSACTIONS_QUERY, {
    variables: { plaid_account_id: accountId, company_id: companyId },
    onCompleted: data => {
      const { plaid, database, error } = data.getAccountTransactions;
      if (!error && database) {
        setTransactions(database);
      } else if (error && database) {
        setTransactions(database);
        notifyWarn();
      } else if (plaid) {
        setTransactions(plaid);
      } else {
        notifyError();
      }
    },
    onError: () => notifyError()
  });

  useEffect(() => {
    if (search) {
      const filtered = transactions.filter(
        transaction =>
          transaction.name.toLowerCase().includes(search.toLowerCase()) &&
          moment(transaction.date).isBetween(dateRange.from, dateRange.to)
      );
      setFilteredTransactions(filtered);
    } else {
      const filtered = transactions.filter(transaction =>
        moment(transaction.date).isBetween(dateRange.from, dateRange.to)
      );
      setFilteredTransactions(filtered);
    }
  }, [transactions, dateRange, search]);
  let accountLabel = `${account.institution_name} - ${account.name}${' '}`;
  accountLabel += account.mask ? `(...${account.mask}) ` : ''
  accountLabel += account.official_name ? `${account.official_name}` : ''
  const accountName = `${account.institution_name} - ${account.name} ${account.mask ? ` (...${account.mask})` : ``
    }`;
  return (
    <>
      <PageHeader>
        <HeaderTitle title={accountLabel} back="Back to accounts" to={`/accounts/${companyId}`} />
        <HeaderActionContainer>
          {loading && (
            <HeaderActionLoader />
          )}
          <PrimaryButton size="medium" color="secondary" onClick={() => exportBalancesToCSV(filteredTransactions, accountName)}>
            Export
          </PrimaryButton>
        </HeaderActionContainer>
      </PageHeader>
      <FullPage>
        <TransactionsActions
          dateRange={dateRange}
          pickerRef={pickerRef}
          setPickerRef={setPickerRef}
          showFromMonth={showFromMonth}
          handleFromChange={handleFromChange}
          handleToChange={handleToChange}
          handleFromTodayButton={handleFromTodayButton}
          handleToTodayButton={handleToTodayButton}
          accountId={accountId}
          transactions={filteredTransactions}
          search={search}
          onSearchChange={onSearchChange}
        />
        <TransactionsTable
          loading={loading}
          transactions={filteredTransactions}
        />
      </FullPage>
    </>
  );
};

export default Transactions;
