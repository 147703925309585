import React from 'react';

import { ReactComponent as CloseIcon } from '../../assets/close.svg';

const ToastifyCloseButton = ({ closeToast }) => {
  return (
    <div className='toastify-close'>
      <CloseIcon onClick={closeToast} />
    </div>
  );
};

export default ToastifyCloseButton;
