import React from 'react';
import BookkeepingUpdate from './BookkeepingUpdate';
import { PageHeader, Page } from '@tycoonlabs/web-components';
import Grid from '@material-ui/core/Grid';
const Account = () => {
	return (
		<>
			<PageHeader>
				<h1>Updates</h1>
			</PageHeader>
			<Page>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<BookkeepingUpdate />
					</Grid>
				</Grid>
			</Page>
		</>
	);
};
export default Account;
