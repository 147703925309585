import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import ReactDOM from 'react-dom';

import TextField from 'components/reusables/TextField';

import AddTransactionsActions from './AddTransactionsActions';
import AddTransactionsTable from './AddTransactionsTable';
import AddTransactionsSideBar from './AddTransactionsSidebar';
import Button from '@material-ui/core/Button';

const mockTransactions = [
  {
    date: '2020-02-04',
    description: 'KW RQ329042',
    payee: 'KELLER WILLIAMS',
    category: 'PLACEHOLDER',
    received: 495.99
  },
  {
    date: '2020-02-03',
    description: 'KMART 0934093IMG',
    payee: 'KMART',
    category: 'PLACEHOLDER',
    spend: 103.49
  },
  {
    date: '2020-02-02',
    description: 'ABC LIQ#0093 WPB',
    payee: 'ABC LIQUORS',
    category: 'PLACEHOLDER',
    spend: 348.73
  }
];

const mockStatement = {
  name: 'Capital-One-4439-Jun-2019.pdf'
};

const AddTransactions = ({ toggleModal }) => {
  const [transactions, setTransactions] = useState(mockTransactions);

  useEffect(() => {
    const handleEsc = e => {
      if (e.keyCode === 27) {
        toggleModal(false);
      }
    };

    window.addEventListener('keydown', handleEsc);

    // clean up event listeners on unmount
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const content = (
    <div className='transaction-modal-container'>
      <div className='transactions-container'>
        <div className='left-content'>
          <div className='accounts-table'>
            <div className='table-header grey'>
              <div className='column-primary'>
                <div className='bank-name'>Add Transaction</div>
              </div>
            </div>
            <Formik
              initialValues={{
                date: '',
                description: '',
                payee: '',
                category: '',
                spend: '',
                received: ''
              }}
              // validationSchema={Yup.object({
              //   first_name: Yup.string().required('Please enter your first name.'),
              //   last_name: Yup.string().required('Please enter your last name.'),
              //   address1: Yup.string().required('Please enter an address.'), // TODO: regex check to prevent PO BOX
              //   address2: Yup.string(),
              //   city: Yup.string().required('Please enter a city.'),
              //   state: Yup.string()
              //     .oneOf(states, 'Please select a state.')
              //     .required('Please select a state.'),
              //   postal_code: Yup.string().required('Please enter a postal code.')
              // })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTransactions([
                  ...transactions,
                  {
                    ...values,
                    spend: values.spend
                      ? parseFloat(values.spend.toFixed(2))
                      : null,
                    received: values.received
                      ? parseFloat(values.received.toFixed(2))
                      : null
                  }
                ]);
                resetForm();
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <TextField label='Date' name='date' type='text' />
                  <TextField
                    label='Description'
                    name='description'
                    type='text'
                  />
                  <TextField label='Payee' name='payee' type='text' />
                  <TextField label='Category' name='category' type='text' />
                  <TextField label='Spend' name='spend' type='number' />
                  <TextField label='Received' name='received' type='number' />
                  <div className='buttons'>
                    <Button
                      style={{
                        borderRadius: '5px',
                        padding: '5px'
                      }}
                      type='submit'
                    >
                      RESET
                    </Button>
                    <Button
                      style={{
                        borderRadius: '5px',
                        padding: '5px'
                      }}
                      type='submit'
                    >
                      SAVE
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className='right-content'>
          <h3>Processing Transactions</h3>
          <div className='accounts-table'>
            <div className='table-row2'>
              <div className='column-primary'>
                <span className='statement-name'>{mockStatement.name}</span>
                <div className='statement-metadata'>
                  Uploaded on 01/01/2020 8:30am | Last worked on by David Li
                </div>
              </div>
              <div className='column-secondary'></div>
            </div>
          </div>
          <h4>Transactions</h4>
          <AddTransactionsTable transactions={transactions} />
          <div className='lower-right-content'>
            <form>
              <div className='left-checkbox'>
                <input type='checkbox' />
                Completed processing statement
              </div>
              <div className='right-button'>
                <Button
                  style={{
                    borderRadius: '5px',
                    padding: '5px'
                  }}
                  // type='submit'
                  onClick={() => toggleModal(false)}
                >
                  DONE
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(content, document.querySelector('#modal'));
};

export default AddTransactions;
