const COMPANY_INPUT = `
	id
	name
	customer_num
	email
	phone
	logo_url
	website
	address
	address_two
	city
	state
	postal_code
	country,
	removed
`;
const COMPANY = `
	${COMPANY_INPUT}
	last_rec
	last_touched
	last_upload
	removed
	plaid_count
	filethis_count
	created_at
	created_by{
		id
		first_name
		last_name
	}
	modified_by {
		id
		first_name
		last_name
	}
	modified_at
`;
const COMPANY_DETAIL = `
	${COMPANY_INPUT}
	last_rec
	last_touched
	last_upload
	created_at
	modified_at
	removed
	company_products {
		bookkeeping
		roger
	}
	plaid_accounts {
		id
		account_id
		institution_name
		institution_id
		name
		mask
		type
		subtype
		excluded
		created_at
		modified_at
		inactive
	}
	documents {
		id
		filename
		handle
		mimetype
		size
		source
		status
		uploaded_at
		url
	}
`;
const USER_DETAIL = `
	id
	uid
	first_name
	last_name
	email
	username
	company
	calendar
	phone
	address
	address_two
	city
	state
	postal_code
	country
	date_joined
	role
	is_active
	profile_image_url
	created_by{
		id
		username
	}
	modified_by {
		id
		username
	}
	modified_at
`;

export { COMPANY_INPUT, COMPANY, COMPANY_DETAIL, USER_DETAIL };
