/* eslint-disable react/prop-types */
import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Tippy from '@tippy.js/react';
import { FilterSection } from 'components/reusables/AppStyles';
import SearchBox from 'components/reusables/SearchBox';

import Session from 'utils/session';
import DatePicker from 'components/reusables/DatePicker';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { ReactComponent as ExportIcon } from '../../../assets/export.svg';
import { exportTransactionsToCSV } from '../../../helpers';

import { showModal } from '../../../actions';

const TransactionsActions = ({
  transactions,
  accountId,
  dateRange,
  pickerRef,
  setPickerRef,
  showFromMonth,
  handleFromChange,
  handleToChange,
  handleFromTodayButton,
  handleToTodayButton,
  search,
  onSearchChange
}) => {
  return (
    <FilterSection>
      <div className="flex flex-row-se justify-between">
          <DatePicker
            dateRange={dateRange}
            pickerRef={pickerRef}
            setPickerRef={setPickerRef}
            showFromMonth={showFromMonth}
            handleFromChange={handleFromChange}
            handleToChange={handleToChange}
            handleFromTodayButton={handleFromTodayButton}
            handleToTodayButton={handleToTodayButton}
          />
          <SearchBox
            value={search}
            onChange={onSearchChange}
          />
      </div>
    </FilterSection>
  );
};

export default TransactionsActions;
