import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, useParams } from 'react-router-dom';
import PlaidLink from 'react-plaid-link';
import configs from 'config';

import { ReactComponent as XIcon } from 'assets/x.svg';

const LinkAccountOptions = ({ onClose, handleOnExit, handleOnSuccess }) => {
  const { companyId } = useParams();

  useEffect(() => {
    const handleEsc = e => {
      if (e.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    // clean up event listeners on unmount
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return ReactDOM.createPortal(
    <div className='modal-container'>
      <div className='modal-content'>
        <p className='close-modal'>
          <span>
            <XIcon onClick={() => onClose()} />
          </span>
        </p>

        <div className='temp-modal'>
          <h1>Let's get started by linking your business accounts</h1>
          <p>
            We use our {configs.app.partner}  proprietary web app to complete your monthly
            bookkeeping by utilizing two types of bank connections. One
            connection is in charge of pulling your bank transactions and
            balances in real time. The second connection is in charge of pulling
            your monthly bank statements.
          </p>
          <div
            className='options'
            onClick={() => onClose()}
            style={{ cursor: 'pointer' }}
          >
            <PlaidLink
              className='card-button'
              clientName={configs.app.partner}
              env='production'
              product={['auth', 'transactions']}
              publicKey='1a73cefba99cb20309a1e926c53481'
              onExit={handleOnExit}
              onSuccess={handleOnSuccess}
              style={null} // overrride built-in style
            >
              LINK ACCOUNTS
            </PlaidLink>
            {/* <Link to={`/bookkeeping/${companyId}/filethis`}>
              <button className='card-button'>
                <h3>RETRIEVE STATEMENTS</h3>
              </button>
            </Link> */}
          </div>
          <div className='modal-footer'>
            <span>Your financial information is always safe.</span>
            <span>
              We use industry standard 256-bit encryption to connect your data
              to our software.
            </span>
            <span>
              This is the same level of security used with online banking.
            </span>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
};

export default LinkAccountOptions;
