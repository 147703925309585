import {useSelector, shallowEqual} from 'react-redux';
class Session {
	isLoggedIn() {
		return this._getUser().isLoggedIn || false;
	}
	getUser() {
		return useSelector(state => state.currentUser, shallowEqual);
	}
	// get user role
	getRole() {
		return this._getUser().role || null;
	}
	// get user id
	getUserId() {
		return this._getUser().id || null;
	}
	// note: we only store company id in this store.
	getCompanyId() {
		return useSelector(state => state.company, shallowEqual).id;
	}	
	getCurrentBusiness() {
		return useSelector(state => state.company, shallowEqual);
	}
	getCompany(companyId) {
		return useSelector(
			state =>
				state.currentUser && state.currentUser.companies && state.currentUser.companies.find(company => company.id === companyId),
			shallowEqual
		);
	}
	getCompanyIndex(companyId) {
		return useSelector(state =>
			state.currentUser.companies.findIndex(company => company.id === companyId)
		);
	}
	/**
	 * @deprecated - need to use query instead
	 * @param {*} companyId 
	 */
	getPlaidAccounts(companyId) {
		return useSelector(
			state =>
				state.currentUser.companies.find(company => company.id === companyId)
					.plaid_accounts,
			shallowEqual
		);
	}
	/**
	 * * @deprecated - need to use query instead
	 * @param {*} companyId 
	 * @param {*} accountId 
	 */
	getPlaidAccount(companyId, accountId) {
		return useSelector(
			state =>
				state.currentUser.companies
					.find(company => company.id === companyId)
					.plaid_accounts.find(account => account.id === accountId),
			shallowEqual
		);
	}
	getDocuments(companyId) {
		return useSelector(
			state =>
				state.currentUser.companies.find(company => company.id === companyId)
					.documents
		);
	}
	getClients(){
		return useSelector(
			state => state.currentUser&& state.currentUser.companies && state.currentUser.companies.filter(client => !client.removed),
			shallowEqual
		);
	}
	//internal handler
	_getUser() {
		return this.getUser() || {}
	}
}
export default new Session()
