/**
 * Common styles to keep the platform consistent
 */
const DataTableStyles = {
	headRow: {
		style: {
			backgroundColor: '#f0f0f0',
			fontSize: '1.2rem',
			fontWeight: '600',
			color: '#000000'
		}
	},
	expanderRow: {
		style: {
			backgroundColor: '#fafafa',
		}
	},
	pagination:{
		style:{
		}
	}
};
export {DataTableStyles}