/** PACKAGE IMPORTS */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation, Switch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { toast, Slide } from 'react-toastify';

/** LOCAL IMPORTS */
import configs from 'config';
import PrivateRoute from 'components/PrivateRoute';
import Login from 'components/Login';
import Clients from 'components/Clients';
import Profile from 'components/Profile';
import Team from 'components/Team';
import Platform from 'components/Platform';
import Registration from 'components/Registration';
import ActivateAccount from 'components/ActivateAccount';
import ResetPassword from 'components/ResetPassword';
import ModifyPassword from 'components/ModifyPassword';
import ToastifyCloseButton from 'components/reusables/ToastifyCloseButton';
import Spinner from 'components/reusables/Spinner';
import Accounts from 'components/Accounts';
import Dashboard from 'components/Dashboard';
import Balances from 'components/Accounts/Balances';
import Statements from 'components/Statements';
import Documents from 'components/Documents';
import Reports from 'components/Reports';
import UploadDocument from 'components/Documents/UploadDocument';
import ProfitAndLoss from 'components/Reports/ProfitAndLoss';
import BalanceSheet from 'components/Reports/BalanceSheet';
import FileThis from 'components/FileThis';

import Updates from 'components/Updates';
import AccountTransactions from "components/Accounts/Transactions";
import Transactions from "components/Bookkeeping/Transaction";
import AddTransactions from "components/Bookkeeping/Transaction/AddTransactions";
import Integrations from "components/Integration";
import Internal from "components/Internal";
import Templates from 'components/Templates';

import Session from 'utils/session';
import {SALES } from 'utils/roles';

import { setUser, setCompany } from 'actions';
import { REFRESH_USER_QUERY } from 'graphql/queries';
import { Layout, ToastBox } from '@tycoonlabs/web-components';

/** CSS IMPORTS */
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Navigation from './Navigation';

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const targetCompanyId = location.pathname.match(/\/(\d+)/) && location.pathname.match(/\/(\d+)/)[1];
  const isLoggedIn = Session.isLoggedIn();

  const token = localStorage.getItem('authToken');

  const [refreshUser, { loading }] = useLazyQuery(REFRESH_USER_QUERY, {
    onCompleted: data => {
      console.debug("#### App#data", data);
      const { user, company } = data.refreshUser;
      user.companies = [company]
      dispatch(setUser(user));
      if (company) {
        dispatch(setCompany(company));
        if (user.role === SALES){
          history.replace(`/clients`)
        }else if (location.pathname === '/') {
          history.replace(`/dashboard/${company.id}`)
        }
      }
    },
    onError: e => {
      console.log("#### error while refresh user", e)
      localStorage.removeItem('authToken');
      toast.error(
        'This session has expired. Please re-enter your login credentials.'
      );
      history.replace('/login');
    }
  });
  const refreshApp = async () => {
    await refreshUser({ variables: { token, company_id: targetCompanyId } });
  };

  useEffect(() => {
    if (localStorage.getItem('authToken')) {
      if (location.pathname === '/' || location.pathname === '/login') {
        refreshApp()
        .then(() => {
          console.log("Post refresh")
        }).catch((e) => {
            console.log("###### eee", e);
            history.replace('/login');
          })
      } else {
        refreshApp()
          .then(() => { })
          .catch(() => { });
      }
    }
  }, []);

  const navSetting = Navigation();
  const user = Session.getUser();
  return (
    <>

      <div className='backoffice flex-row'>
        <ToastBox
          autoClose={5000}
          closeButton={<ToastifyCloseButton />}
          draggable={false}
          pauseOnHover={false}
          position='top-center'
          transition={Slide}
        />
        {loading ? (
            <Spinner />
        ) : (
            <Switch>
              <Layout appName={configs.app.appName}
                appIcon={navSetting.appIcon}
                primaryNavs={navSetting.primaryNavs}
                showNav={isLoggedIn}
                widgets={{ notification: " ", apps: " ", account: navSetting.account, business: navSetting.business }}
                styles={configs.styles}
                footer={navSetting.footer}
                footerExpand={navSetting.footerExpand}
                >
                {isLoggedIn && (
                  <>
                    <PrivateRoute
                      exact
                      path='/dashboard/:companyId'
                      component={Dashboard}
                    />
                    <PrivateRoute path='/clients' component={Clients} />
                    <PrivateRoute
                      exact
                      path='/accounts/:companyId'
                      component={Accounts}
                    />
                    <PrivateRoute
                      path='/bookkeeping/:companyId/accounts/:accountId/balances'
                      component={Balances}
                    />
                    <PrivateRoute
                      path='/bookkeeping/:companyId/accounts/:accountId/transactions'
                      component={AccountTransactions}
                    />
                    <PrivateRoute path='/statements/:companyId' component={Statements} />

                    <PrivateRoute
                      exact
                      path='/documents/:companyId'
                      component={Documents}
                    />
                    <PrivateRoute
                      path='/documents/:companyId/new'
                      component={UploadDocument}
                    />
                    <PrivateRoute
                      path='/bookkeeping/:companyId/filethis'
                      component={FileThis} />

                    <PrivateRoute exact
                      path='/reports/:companyId'
                      component={Reports} />
                    <PrivateRoute
                      path='/reports/:companyId/profitandloss'
                      component={ProfitAndLoss}
                    />
                    <PrivateRoute
                      path='/reports/:companyId/balancesheet'
                      component={BalanceSheet}
                    />
                    {/* GLOBAL */}
                    <PrivateRoute path='/profile' component={Profile} />
                    <PrivateRoute path='/team/:companyId' component={Team} />

                    {/* STAFF */}
                    <PrivateRoute
                      exact
                      path='/transactions/:companyId/'
                      component={Transactions}
                    />
                    <PrivateRoute
                      exact
                      path='/updates/:companyId'
                      component={Updates}
                    />
                    <PrivateRoute
                      path='/transactions/:companyId/bookkeeping/add-transactions'
                      component={AddTransactions}
                    />
                    <PrivateRoute
                      path='/internal/:companyId'
                      component={Internal}
                    />
                    <PrivateRoute
                      path='/integrations/:companyId'
                      component={Integrations}
                    />
                    <PrivateRoute
                      path='/templates/:companyId'
                      component={Templates}
                    />

                    <PrivateRoute path='/platform' component={Platform} />
                    <PrivateRoute path='/registration' component={Registration} />
                  </>
                )}

                {/* LOGIN */}
                <Route path='/activate/:token' component={ActivateAccount} />
                <Route path='/pass_recovery' component={ResetPassword} />
                <Route path='/resetpassword/:token' component={ModifyPassword} />
                <Route path='/login' exact component={Login} />
                <Route path='/' exact component={Login} />
              </Layout>
            </Switch>
          )}
      </div>

    </>
  );
};

export default App;
