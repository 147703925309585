import { gql } from 'apollo-boost';
import {COMPANY_DETAIL, USER_DETAIL} from './model';

export const EDIT_USER = gql`
  mutation($input: AdminUserInput!) {
    admin_user(
      input: $input
    ) {
    	user{
    		${USER_DETAIL}
    	}
    }
  }
`;

export const GET_USERS = gql`
  query {
    getUsers {
    	${USER_DETAIL}
    }
  }
`;

export const SEARCH_USERS = gql`
  query($search: String, $size: Int!, $page: Int!, $sort: String, $direction: String) {
    admin_users(
      search: $search
      size: $size
      page: $page
      sort: $sort
      direction: $direction
    )  {
    	page
    	pages
    	count
    	has_next
    	has_prev
    	objects {
    		${USER_DETAIL}
    	}
    }
  }
`;
