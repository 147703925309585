import DatePicker from 'components/reusables/DatePicker';
import React from 'react';


const DatePickerOptions = ({
  onOption,
  dateRange,
  pickerRef,
  setPickerRef,
  showFromMonth,
  handleFromChange,
  handleToChange,
  handleFromTodayButton,
  handleToTodayButton
}) => {
  return (
    <div className='date-picker-options'>
      <div className='date-range-options'>
        <DatePicker
          dateRange={dateRange}
          pickerRef={pickerRef}
          setPickerRef={setPickerRef}
          showFromMonth={showFromMonth}
          handleFromChange={handleFromChange}
          handleToChange={handleToChange}
          handleFromTodayButton={handleFromTodayButton}
          handleToTodayButton={handleToTodayButton}
        />
      </div>
      <div className='div-line'></div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('Today')}>Today</span>
      </div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('Yesterday')}>Yesterday</span>
      </div>
      <div className='div-line'></div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('This Week')}>This Week</span>
      </div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('Last Week')}>Last Week</span>
      </div>
      <div className='div-line'></div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('This Month')}>This Month</span>
      </div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('Last Month')}>Last Month</span>
      </div>
      <div className='div-line'></div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('This Fiscal Year')}>
          This Fiscal Year
        </span>
      </div>
      <div className='date-macro-line'>
        <span onClick={() => onOption('Last Fiscal Year')}>
          Last Fiscal Year
        </span>
      </div>
    </div>
  );
};

export default DatePickerOptions;
