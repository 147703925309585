/** PACKAGE IMPORTS */
import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/react-hooks';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import Session from '../../utils/session';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import { GET_COMPANY } from 'graphql/queries';
import Paginator from 'components/reusables/Paginator';
import { ADMIN, CS, ACCOUNTANT, PA, SALES, ASSOCIATE } from 'utils/roles';
import { setCompany, addCompanies } from 'actions';
import { toast } from 'react-toastify';
import Grid from "@material-ui/core/Grid";
import { ContentEmpty, DisplayList, FormButton } from "../reusables/Contents";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from '@material-ui/core/Typography';
import ListItemText from "@material-ui/core/ListItemText";
import DisplayUtil from '../../utils/display'
import { DataTableStyles } from "../reusables/styles";
const ClientsTable = ({
	clients,
	loading,
	handleEditClient,
	count,
	size,
	setSize,
	page,
	setPage,
	sort,
	setSort,
	direction,
	setDirection
}) => {
	const dispatch = useDispatch();
	const role = Session.getRole();
	const history = useHistory();
	const [getClient] = useLazyQuery(GET_COMPANY, {
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			let company = data.getCompany;
			let companyId = company.id;
			dispatch(setCompany(companyId));
			dispatch(addCompanies([company]));
			let link = `/dashboard/${companyId}`;
			history.replace(link);
			window.location.reload();
		},
		onError: e => {
			toast.error('Failed to load clients.');
		}
	});
	const handleClick = company => {
		let companyId = company.id;
		getClient({ variables: { company_id: companyId } });
	};
	const RowDetail = ({ data }) => (
		<Grid container>
			<Grid item sm={6} xs={12}>
				<DisplayList labelWidth={4} contentWidth={8}>
					<ListItem>
						<ListItemAvatar>
							<Avatar src={data.logo_url} />
						</ListItemAvatar>
						<ListItemText secondary={
							<span className={"flex-row-sc"}>
								<Typography component={"span"} variant={"h4"} style={{wordBreak: 'break-all'}} >{data.name}</Typography>
								{(role === ADMIN ||
									role === ACCOUNTANT ||
									role === SALES ||
									role === PA ||
									role === CS) && (
										<FormButton edge="0 10px" compact variant={"outlined"} style={{minWidth: "50px"}} disableElevation
											onClick={() => handleEditClient(data)}>
											Edit
										</FormButton>
									)}
							</span>
						} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Customer #"} secondary={data.customer_num}/>
					</ListItem>
					<ListItem>
						<ListItemText primary={"# of Plaid Account"} secondary={data.plaid_count} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Auto Statement Import"} secondary={data.filethis_count ? "Yes" : "No"} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Last Reconcil"}
							secondary={(data.last_rec && moment(data.modified_at).format('lll')) || 'N/A'} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Last Touch"}
							secondary={(data.last_touched && moment(data.modified_at).format('lll')) || 'N/A'} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Last Upload"}
							secondary={data.last_upload && moment(data.date_joined).format('lll') || 'N/A'} />
					</ListItem>
				</DisplayList>
			</Grid>
			<Grid item sm={6} xs={12}>
				<DisplayList>
					<ListItem>
						<ListItemText primary={"Email"} secondary={
							<>
								<a target={"_blank"} href={`mailto:${data.email}`}>{data.email}</a>
							</>
						} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Phone"} secondary={data.phone} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Website"} secondary={data.website} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Address"} secondary={(data.address || '') + " " + (data.address_two || '')} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"City"} secondary={data.city} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"State"} secondary={data.state} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Postal Code"} secondary={data.postal_code} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Country"} secondary={data.country} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Modified"}
							secondary={DisplayUtil.userDate(data.modified_by, data.modified_at)} />
					</ListItem>
					<ListItem>
						<ListItemText primary={"Created"}
							secondary={DisplayUtil.userDate(data.created_by, data.created_at)} />
					</ListItem>
				</DisplayList>
			</Grid>
		</Grid>
	);
	const columns = useMemo(
		() => [
			{
				name: 'CUSTOMER #',
				selector: 'customer_num',
				maxWidth: '150px',
				wrap: true,
				sortable: true,
				grow: 1,
			},
			{
				name: 'NAME',
				selector: 'name',
				sortable: true,
				wrap: true,
				maxWidth: '300px',
				grow: 3,
			}, {
				name: 'ACCOUNTS',
				selector: 'plaid_count',
				maxWidth: '75px',
				grow: 1
			},
			{
				name: 'STATEMENT IMPORT',
				selector: 'filethis_count',
				grow: 1,
				maxWidth: '75px',
				cell: row => {
					return row.filethis_count > 0 ? "Yes" : "No";
				}
			},
			{
				name: 'LAST FILE UPLOADED',
				selector: 'last_upload',
				wrap: true,
				maxWidth: '75px',
				grow: 1,
				cell: row => {
					return row.last_upload && moment(row.last_upload).format('lll');
				}
			},
			{
				name: 'LAST TOUCHED',
				selector: 'last_touched',
				wrap: true,
				maxWidth: '75px',
				grow: 1,
				cell: row => {
					return row.last_touched && moment(row.last_touched).format('lll');
				}
			},
			{
				grow: 1,
				name: 'LAST REC',
				selector: 'last_rec',
				maxWidth: '75px',
				wrap: true,
				cell: row => {
					return row.last_rec && moment(row.last_rec).format('lll');
				}
			},
			// {
			// 	grow: 1,
			// 	name: 'Removed',
			// 	selector: 'removed',
			// 	maxWidth: '75px',
			// 	cell: row => {
			// 		return row.removed > 0 ? "Yes" : "No";
			// 	}
			// },
			{
				name: '',
				grow: 1,
				selector: 'id',
				sortable: false,
				minWidth: '125px',
				right: true,
				cell: row => (
					<FormButton variant={"contained"} disableElevation color={"primary"} fill={"#616161"} onClick={() => handleClick(row)}>
						Manage
					</FormButton>
				)
			},
		],
		[]
	);
	const handlePageChange = p => {
		console.log("### handlePageChange", page, "--", p);
		setPage(p);
	};
	const handlePerRowsChange = (perPage, page) => {
		console.log("### handleRowsChange", perPage, page);
		setSize(perPage);
		setPage(page);
	};
	const handleSort = (sort, direction) => {
		console.log("### handleSort", sort, direction);
		setSort(sort.selector);
		setDirection(direction)
	};
	return (
		<>
			<div className='data-table'>
				{clients.length > 0 && (
					<DataTable
						className='pagination-custom'
						columns={columns}
						data={clients}
						noHeader
						expandableRows
						expandableRowsComponent={<RowDetail />}
						expandOnRowClicked
						highlightOnHover
						customStyles={DataTableStyles}
						paginationDefaultPage={page}
						persistTableHead
						paginationPerPage={size}
						pagination
						paginationServer
						paginationTotalRows={count}
						onChangeRowsPerPage={handlePerRowsChange}
						onChangePage={handlePageChange}
						defaultSortField={"name"}
						defaultSortAsc={false}
						onSort={handleSort}
						sortServer={true}
					/>
				)}
			</div>
			{!loading && !clients.length && (
				<ContentEmpty>
					No client record found. Please adjust your filter.
				</ContentEmpty>
			)}
		</>
	);
};
export default ClientsTable;
