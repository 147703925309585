/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-extra-boolean-cast */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Session from '../../utils/session';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = Session.isLoggedIn();

  return (
    <Route
      {...rest}
      render={props =>
        (isLoggedIn && <Component {...props} />) || <Redirect to='/login' />
      }
    />
  );
};

export default PrivateRoute;
