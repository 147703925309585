import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Tippy from '@tippy.js/react';
import { ReactComponent as DownArrow } from 'assets/down-arrow.svg';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_DASHBOARD_REPORT } from 'graphql/queries';
import DatePickerOptions from 'components/reusables/DatePickerOptions';
import ProgressBar from 'components/reusables/ProgressBar';
import DashboardBox from './DashboardBox';
import ExpensesDoughnutChart from './ExpensesDoughnutChart';
import LineChart from './LineChart';
import VendorDoughnutChart from './VendorDoughnutChart';
import { PageHeader, FullPage } from '@tycoonlabs/web-components'
import { HeaderActionLoader, HeaderActionContainer } from 'components/reusables/AppStyles';

const Dashboard = () => {
  const { companyId } = useParams();

  const [dateRange, setDateRange] = useState({
    start_date: null,
    end_date: null
  });
  const [pickerRef, setPickerRef] = useState(null);
  const [report, setReport] = useState(null);
  const [dateMacro, setDateMacro] = useState('Last Fiscal Year');
  const [showLoader, setShowLoader] = useState(true);
  const [showFilter, setShowFilter] = useState(false);


  const {  } = useQuery(GET_DASHBOARD_REPORT, {
    variables: { company_id: companyId },
    onCompleted: ({ getDashboardReport }) => {
      setShowLoader(false);
      setReport(getDashboardReport);
    },
    onError: () => {
      setShowLoader(false);
    }
  });

  const [getReportMacro, { }] = useLazyQuery(GET_DASHBOARD_REPORT, {
    onCompleted: ({ getDashboardReport }) => {
      setShowLoader(false);
      setReport(getDashboardReport);
    },
    onError: () => {
      setShowLoader(false);
    }
  });

  const handleClick = e => {
    e.preventDefault();
  };
  const handleOption = option => {
    setShowFilter(false);
    setShowLoader(true);
    setDateMacro(option);
    getReportMacro({
      variables: {
        company_id: companyId,
        query_string: JSON.stringify({
          date_macro: option,
          start_date: null,
          end_date: null
        })
      }
    });
  };

  const handleDateRange = (start_date, end_date) => {
    return { start_date, end_date };
  };

  const showFromMonth = () => {
    const { from, to } = dateRange;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      pickerRef.getDayPicker().showMonth(from);
    }
  };

  const handleFromChange = from => {
    setDateRange({ ...dateRange, start_date: from });
  };

  const handleToChange = to => {
    setDateRange({ ...dateRange, end_date: to });
  };

  const handleFromTodayButton = from => {
    setDateRange({ ...dateRange, start_date: from });
    pickerRef.getInput().focus();
  };

  const handleToTodayButton = to => {
    setDateRange({ ...dateRange, end_date: to });
    pickerRef.hideDayPicker();
  };

  useEffect(() => {
    if (dateRange.start_date && dateRange.end_date) {
      setShowFilter(false);
      setShowLoader(true);
      setDateMacro(null);
      getReportMacro({
        variables: {
          company_id: companyId,
          query_string: JSON.stringify({
            date_macro: null,
            start_date: dateRange.start_date,
            end_date: dateRange.end_date
          })
        }
      });
    }
  }, [dateRange]);

  return (
    <>
      <PageHeader>
        <h1>Dashboard</h1>
        <HeaderActionContainer>
          {showLoader && (
            <HeaderActionLoader />
          )}
          <Tippy
            arrow={false}
            content={
              <DatePickerOptions
                onOption={handleOption}
                dateRange={dateRange}
                pickerRef={pickerRef}
                setPickerRef={setPickerRef}
                showFromMonth={showFromMonth}
                handleFromChange={handleFromChange}
                handleToChange={handleToChange}
                handleFromTodayButton={handleFromTodayButton}
                handleToTodayButton={handleToTodayButton}
              />
            }
            visible={showFilter}
            onHidden={() => setShowFilter(false)}
            distance={1}
            interactive
            placement='bottom'
            trigger='click'
          >
            <div className='date-macro-options'  onClick={() => setShowFilter(true)} >
              <div>{dateMacro ? dateMacro : 'Custom Date'}</div>
              <DownArrow />
            </div>
          </Tippy>
        </HeaderActionContainer>
      </PageHeader>
      <FullPage className="bookkeeping-container">

        <div className='dashboard-container'>

          <div className='dashboard-top'>
            <div className='income-expenses-line'>
              <div className='dashboard-header'>
                <h3>Income / Expenses</h3>
              </div>
              <div className='income-expenses-profit'>
                <div className='income'>
                  <h3>Income</h3>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(report && report.income_expenses_profit.income)}
                </div>
                <div className='expenses'>
                  <h3>Expenses</h3>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(report && report.income_expenses_profit.expenses)}
                </div>
                <div className='profit'>
                  <h3>Profit</h3>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                  }).format(report && report.income_expenses_profit.profit)}
                </div>
              </div>
              <LineChart report={report && report.profit_and_loss_months} />
            </div>

            <div className='balance-burn-unpaid'>
              <DashboardBox
                label='Cash Balance'
                value={report && report.cash_balance}
              />
              <DashboardBox label='Cash Burn' value={report && report.cash_burn} />
              <DashboardBox
                label='Bills - Unpaid'
                value={report && report.bills_unpaid}
              />
            </div>
          </div>
          <div className='dashboard-bottom'>
            <div className='invoices'>
              <h3>Invoices - Customer Owe You</h3>
              <span className='total'>$0</span>
              <div className='dashboard-progress-bars'>
                <ProgressBar
                  label='Overdue'
                  value={report && report.customers_owe_you.overdue}
                  percent={report && report.customers_owe_you.overdue_percent}
                />
                <ProgressBar
                  label='This Week'
                  value={report && report.customers_owe_you.this_week}
                  percent={report && report.customers_owe_you.this_week_percent}
                />
                <ProgressBar
                  label='Next Week'
                  value={report && report.customers_owe_you.next_week}
                  percent={report && report.customers_owe_you.next_week_percent}
                />
                <ProgressBar
                  label='Future'
                  value={report && report.customers_owe_you.future}
                  percent={report && report.customers_owe_you.future_percent}
                />
              </div>
            </div>
            <div className='top-expenses-category'>
              <h3>Top Expenses by Category</h3>
              <ExpensesDoughnutChart
                report={report && report.top_expenses_by_category}
              />
            </div>
            <div className='top-expenses-vendor'>
              <h3>Top Expenses by Vendor</h3>
              <VendorDoughnutChart
                report={report && report.top_expenses_by_vendor}
              />
            </div>
          </div>
        </div>
      </FullPage>
    </>
  );
};

export default Dashboard;
