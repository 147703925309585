import React, { useState, useEffect } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import DatePicker from 'components/reusables/DatePicker';
import moment from 'moment';
import useWindowDimensions from 'components/reusables/WindowSize';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  RightDrawer,
  DrawerCard,
  FormButton,
  FormTextField,
  DrawerCardActions,
  DrawerCloseButton,
  FormMenuItem
} from 'components/reusables/Contents';
import { Form, Formik } from 'formik';
const FilterDrawer = ({
  openDrawer,
  setOpenDrawer,
  setFilter,
  search,
  filter,
  handleApplyFilters,
  defaultFilters,
  data
}) => {
  const [pickerRef, setPickerRef] = useState(null);
  const { width, height } = useWindowDimensions();
  const dateRange = { from: filter.dateFrom, to: filter.dateTo };
  //date picker
  const showFromMonth = () => {
    const { from, to } = dateRange;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      pickerRef.getDayPicker().showMonth(from);
    }
  };
  const handleFromChange = from => {
    setFilter({ ...filter, dateFrom: from });
  };
  const handleToChange = to => {
    setFilter({ ...filter, dateTo: to });
    showFromMonth();
  };
  const handleFromTodayButton = from => {
    setFilter({ ...filter, dateFrom: from });
    pickerRef.getInput().focus();
  };
  const handleToTodayButton = to => {
    setFilter({ ...filter, dateTo: to });
    pickerRef.hideDayPicker();
  };
  const handleFilterClear = () => {
    setFilter(defaultFilters);
  };
  const handleClose = () => {
    setOpenDrawer(false);
  };
  const initValues = () => {
    return {
      description: filter.description || search || '',
      accountName: filter.accountName || '',
      categoryName: filter.categoryName || '',
      vendorName: filter.vendorName || '',
      amountMin: filter.amountMin,
      amountMax: filter.amountMax,
      showExcluded: filter.showExcluded,
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo
    };
  };
  const resetValues = {
    description: defaultFilters.description,
    accountName: defaultFilters.accountName,
    categoryName: defaultFilters.categoryName,
    vendorName: defaultFilters.vendorName,
    amountMin: defaultFilters.amountMin,
    amountMax: defaultFilters.amountMax,
    showExcluded: defaultFilters.showExcluded,
    dateFrom: defaultFilters.dateFrom,
    dateTo: defaultFilters.dateTo
  };
  const filterOptions = () => {
    console.log('### Filter form for files', filter);
    return (
      <Formik
        initialValues={initValues()}
        onSubmit={(values, { setSubmitting }) => {
          handleApplyFilters(values);
          setOpenDrawer(false);
        }}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setValues,
            setFieldValue
          } = props;
          const handleFormClearing = () => {
            handleFilterClear();
            setValues(resetValues, false);
          };
          const handleChecked = value => {
            setFieldValue('showExcluded', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete={'nope'}>
              <DrawerCard className={''} style={{ height: `${height}px` }}>
                <CardHeader
                  title={'Filter Files'}
                  action={
                    <DrawerCloseButton
                      aria-label='Close'
                      onClick={() => handleClose()}
                    />
                  }
                />
                <CardContent>
                  <DatePicker
                    dateRange={dateRange}
                    pickerRef={pickerRef}
                    setPickerRef={setPickerRef}
                    showFromMonth={showFromMonth}
                    handleFromChange={handleFromChange}
                    handleToChange={handleToChange}
                    handleFromTodayButton={handleFromTodayButton}
                    handleToTodayButton={handleToTodayButton}
                  />
                  <FormTextField
                    error
                    margin='dense'
                    name='description'
                    label='Description'
                    placeholder='Enter description'
                    value={values.description}
                    onChange={handleChange}
                  />
                  <FormTextField
                    width='80%'
                    error
                    select
                    SelectProps={{ displayEmpty: true }}
                    margin='dense'
                    label='Chart of Account'
                    name='accountName'
                    value={values.accountName}
                    onChange={handleChange}
                  >
                    <FormMenuItem value=''>
                      <em>Select an account</em>
                    </FormMenuItem>
                    {data.accounts.map(account => (
                      <FormMenuItem key={account.id} value={account.name}>
                        {account.name}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                  <FormTextField
                    width='80%'
                    error
                    select
                    SelectProps={{ displayEmpty: true }}
                    margin='dense'
                    label='Category'
                    name='categoryName'
                    value={values.categoryName}
                    onChange={handleChange}
                  >
                    <FormMenuItem value=''>
                      <em>Select a category</em>
                    </FormMenuItem>
                    {data.categories.map(category => (
                      <FormMenuItem key={category.id} value={category.name}>
                        {category.name}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                  <FormTextField
                    width='80%'
                    error
                    select
                    SelectProps={{ displayEmpty: true }}
                    margin='dense'
                    label='Payee'
                    name='vendorName'
                    value={values.vendorName}
                    onChange={handleChange}
                  >
                    <FormMenuItem value=''>
                      <em>Select a Payee</em>
                    </FormMenuItem>
                    {data.vendors.map(vendor => (
                      <FormMenuItem key={vendor.id} value={vendor.display_name}>
                        {vendor.display_name}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <FormTextField
                      error
                      margin='dense'
                      type='number'
                      width='45%'
                      name='amountMin'
                      label='Amount Minimum'
                      placeholder='Enter Amount'
                      value={values.amountMin}
                      onChange={handleChange}
                    />
                    <FormTextField
                      error
                      margin='dense'
                      width='45%'
                      type='number'
                      name='amountMax'
                      label='Amount Maximum'
                      placeholder='Enter Amount'
                      value={values.amountMax}
                      onChange={handleChange}
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='showExcluded'
                        checked={values.showExcluded}
                        onChange={() => handleChecked(!values.showExcluded)}
                      />
                    }
                    label='Show Excluded Transactions'
                  />
                </CardContent>
                <DrawerCardActions>
                  <FormButton color='primary' type='submit' size={'medium'}>
                    Apply Filter
                  </FormButton>
                  <FormButton onClick={handleFormClearing}>Reset</FormButton>
                </DrawerCardActions>
              </DrawerCard>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        width={400}
        variant='temporary'
        anchor='right'
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {filterOptions()}
      </RightDrawer>
    </div>
  );
};
export default FilterDrawer;
