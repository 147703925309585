import _objectSpread from "/Users/made02/projects/bo/booking-platform/packages/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
var configs = {
  "dev": {
    web: {
      "URI": "http://localhost:3000"
    },
    api: {
      GRAPHQL_URI: "http://localhost:8000/graphql/"
    }
  },
  "production": {
    web: {
      "URI": "https://www.jtfbookkeeping.com"
    },
    api: {
      GRAPHQL_URI: "https://partner1005api.usebackoffice.com/graphql/"
    }
  }
};
var config = configs[process.env.REACT_APP_STAGE] || configs["dev"];
var styles = {
  navbackground: "#FFFFFF",
  navstyles: "border-right:1px solid #757575",
  navtext: "#282828",
  navicon: "#282828",
  appname: "#282828",
  companyname: "#282828",
  navlinkactive: "#282828"
};
var app = {
  partner: "JT Financial",
  appName: "Bookkeeping",
  appIcon: "//cdn.backoffice.co/media/partner/jtfinancial/icon.png",
  footer: "//cdn.backoffice.co/media/partner/jtfinancial/footer_logo.png",
  navCaret: "//cdn.backoffice.co/media/menu/nav-caret-black.svg",
  logo: "//cdn.backoffice.co/media/partner/jtfinancial/logo.png",
  support: "noreply@accountingfirmbackoffice.com"
};
export default _objectSpread({
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  app: app,
  styles: styles
}, config);