/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import Avatar from './Avatar';
import Button from '../reusables/Button';
import Spinner from '../reusables/Spinner';
import { EDIT_USER } from '../../graphql/mutations';

const ChangeCredentials = ({ user }) => {
  const [userCreds, setUserCreds] = useState({
    currentPass: '',
    newPass: '',
    confirmNewPass: '',
    firstName: '',
    lastName: '',
    email: ''
  });

  const {
    currentPass,
    newPass,
    confirmNewPass,
    firstName,
    lastName,
    email
  } = userCreds;

  const notifySuccess = () =>
    toast.success('Your information was successfully updated.');

  const notifyError = () =>
    toast.error(
      'Something went wrong. Please review your information and try again.'
    );

  const [editUser, { loading, error }] = useMutation(EDIT_USER, {
    variables: {
      user_id: user.id,
      modified_by_id: user.id,
      userinput: {
        currentPassword: currentPass,
        newPassword: newPass,
        email,
        first_name: firstName,
        last_name: lastName
      }
    },
    onCompleted: () => notifySuccess(),
    onError: () => notifyError()
  });

  const onFormSubmit = e => {
    e.preventDefault();
    editUser();
  };

  const changeHandler = e => {
    const { name, value } = e.target;
    setUserCreds({ ...userCreds, [name]: value });
  };

  useEffect(() => {
    if (user) {
      const { first_name, last_name } = user;
      setUserCreds({
        ...userCreds,
        firstName: first_name,
        lastName: last_name
      });
    }
  }, [user]);

  return (
    <>
      <div className='avatar-zone flex-row-cb items-end'>
        <Avatar />
        {/* <div>{user.role}</div> */}
      </div>
      <form
        autoComplete='off'
        onSubmit={e => onFormSubmit(e)}
        className='card-container right'
      >
        <div className='inputs-container'>
          <div className='profile-address'>
            <div>
              <label>First Name</label>
              <input
                name='firstName'
                onChange={changeHandler}
                value={firstName}
              />
            </div>
            <div>
              <label>Last Name</label>
              <input
                className='lastname'
                name='lastName'
                onChange={changeHandler}
                value={lastName}
              />
            </div>
          </div>
          <div>
            <label>Email</label>
            <input
              type='email'
              name='email'
              onChange={changeHandler}
              value={email}
            />
          </div>
          <div>
            <label>Current Password</label>
            <input
              type='password'
              name='currentPass'
              autoComplete='off'
              onChange={changeHandler}
              value={currentPass}
            />
          </div>
          <div>
            <label>New Password</label>
            <input
              type='password'
              name='newPass'
              autoComplete='off'
              onChange={changeHandler}
              value={newPass}
            />
          </div>
          <div>
            <label>Confirm New Password</label>
            <input
              type='password'
              name='confirmNewPass'
              autoComplete='off'
              onChange={changeHandler}
              value={confirmNewPass}
            />
          </div>

          <div className='profile-buttons'>
            {!loading && <Button classN='save-btn'>Save</Button>}
            {loading && <Spinner />}
          </div>
        </div>
      </form>
    </>
  );
};

export default ChangeCredentials;
