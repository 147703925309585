import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import Tippy from '@tippy.js/react';
import styled from 'styled-components';
import DatePickerOptions from 'components/reusables/DatePickerOptions';
import { ReactComponent as DownArrow } from 'assets/down-arrow.svg';
import { GET_PROFIT_AND_LOSS_REPORT } from 'graphql/queries';
import { PageHeader, Page } from '@tycoonlabs/web-components'
import { HeaderTitle, HeaderActionLoader, HeaderActionContainer, ReportEmpty } from 'components/reusables/AppStyles';

const ProfitAndLoss = () => {
  const { companyId } = useParams();
  const [pickerRef, setPickerRef] = useState(null);
  const [dateMacro, setDateMacro] = useState('Last Fiscal Year');
  const [report, setReport] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const { queryData } = useQuery(GET_PROFIT_AND_LOSS_REPORT, {
    variables: { company_id: companyId },
    fetchPolicy: 'no-cache',
    onCompleted: ({ getProfitAndLossReport }) => {
      setShowLoader(false);
      setReport(getProfitAndLossReport.report);
    },
    onError: () => {
      setShowLoader(false);
    }
  });

  const [dateRange, setDateRange] = useState({
    start_date: null,
    end_date: null
  });

  const [getReportMacro, { }] = useLazyQuery(GET_PROFIT_AND_LOSS_REPORT, {
    onCompleted: ({ getProfitAndLossReport }) => {
      setShowLoader(false);
      setReport(getProfitAndLossReport.report);
    },
    onError: () => {
      setShowLoader(false);
    }
  });

  const handleClick = e => {
    e.preventDefault();
  };

  const handleOption = option => {
    setShowFilter(false);
    setDateMacro(option);
    setShowLoader(true);
    getReportMacro({
      variables: {
        company_id: companyId,
        query_string: JSON.stringify({
          date_macro: option,
          start_date: null,
          end_date: null
        })
      }
    });
  };

  const handleDateRange = (start_date, end_date) => {
    return { start_date, end_date };
  };

  const showFromMonth = () => {
    const { from, to } = dateRange;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      pickerRef.getDayPicker().showMonth(from);
    }
  };

  const handleFromChange = from => {
    setDateRange({ ...dateRange, start_date: from });
  };

  const handleToChange = to => {
    setDateRange({ ...dateRange, end_date: to });
  };

  const handleFromTodayButton = from => {
    setDateRange({ ...dateRange, start_date: from });
    pickerRef.getInput().focus();
  };

  const handleToTodayButton = to => {
    setDateRange({ ...dateRange, end_date: to });
    pickerRef.hideDayPicker();
  };

  useEffect(() => { }, [report]);

  useEffect(() => {
    if (dateRange.start_date && dateRange.end_date) {
      setShowFilter(false);
      setShowLoader(true);
      setDateMacro(null);
      getReportMacro({
        variables: {
          company_id: companyId,
          query_string: JSON.stringify({
            date_macro: null,
            start_date: dateRange.start_date,
            end_date: dateRange.end_date
          })
        }
      });
    }
  }, [dateRange]);

  return (
    <>
      <PageHeader>
        <HeaderTitle title="PROFIT AND LOSS REPORT" back="Back to reports" to={`/reports/${companyId}`}/>
        <HeaderActionContainer>
          {showLoader && (
            <HeaderActionLoader />
          )}
          <Tippy
            arrow={false}
            content={
              <DatePickerOptions
                onOption={handleOption}
                dateRange={dateRange}
                pickerRef={pickerRef}
                setPickerRef={setPickerRef}
                showFromMonth={showFromMonth}
                handleFromChange={handleFromChange}
                handleToChange={handleToChange}
                handleFromTodayButton={handleFromTodayButton}
                handleToTodayButton={handleToTodayButton}
              />
            }
            visible={showFilter}
            onHidden={() => setShowFilter(false)}
            distance={1}
            interactive
            placement='bottom'
            trigger='click'
          >
            <div className='date-macro-options'  onClick={() => setShowFilter(true)} >
              <div>{dateMacro ? dateMacro : 'Custom Date'}</div>
              <DownArrow />
            </div>
          </Tippy>
        </HeaderActionContainer>
      </PageHeader>
      <Page className="bookkeeping-container">
        <div className='reports-container'>
          <div className='profitandloss-container'>
            <div className='profitandloss-report'>
              {(!report || report.length == 0) && !showLoader && (
                <ReportEmpty>Report data not available.  Please adjust filter and try again.  Thank you.</ReportEmpty>
              )}
              {report &&
                report.map((el, index) =>
                  // HEADER AND SUB ELEMENTS
                  report[index + 1] && report[index + 1].level >= el.level ? (
                    <div className='reports-row'>
                      <div
                        className={
                          el.level === 0
                            ? `reports-soup total ${el.row_type}`
                            : `reports-soup ${el.row_type}`
                        }
                        style={{ paddingLeft: `${14 * el.level}px` }}
                      >
                        <div>{el.label}</div>
                        {el.value > 0 && (
                          <div>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            }).format(el.value)}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                      // LAST SUB ELEMENT
                      <div className='reports-row'>
                        <div
                          className={
                            el.level === 0
                              ? `reports-soup total ${el.row_type}`
                              : `reports-soup ${el.row_type}`
                          }
                          style={{ paddingLeft: `${14 * el.level}px` }}
                        >
                          <div>{el.label}</div>
                          <div>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD'
                            }).format(el.value)}
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};

export default ProfitAndLoss;
