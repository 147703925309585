import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Session from '../../utils/session';
import ChangeCredentials from './ChangeCredentials';
import EditUserInfo from './EditUserInfo';

const Profile = () => {
  // const dispatch = useDispatch();

  const userData = Session.getUser();

  return (
    <>
      <h1 className='profile-header'>Profile</h1>
      <main className='cards-container'>
        <div className='card left'>
          <ChangeCredentials user={userData} />
        </div>
        <div className='card'>
          <EditUserInfo user={userData} />
        </div>
      </main>
    </>
  );
};

export default Profile;
