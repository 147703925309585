import { gql } from 'apollo-boost';
import { COMPANY, COMPANY_DETAIL, USER_DETAIL } from './model';

export const IS_LOGGED_IN_QUERY = gql`
  query {
    isLoggedIn @client
  }
`;
export const GET_USER_QUERY = gql`
  query($id: ID!) {
    getUser(id: $id) {
    	${USER_DETAIL}
      companies {
      	${COMPANY_DETAIL}
      }
    } 
  }
`;
export const REFRESH_USER_QUERY = gql`
  query($token: String!, $company_id: ID) {
    refreshUser(
    		token: $token
     		company_id: $company_id
     	) {
      user {
      	${USER_DETAIL}    
      }
      company {
      	${COMPANY_DETAIL}
      }
    }
  }
`;
export const GET_PLAID_ACCOUNTS_FOR_COMPANY = gql`
  query($company_id: ID!) {
    getSingleUserPlaidAccounts(company_id: $company_id) {
      id,
      company{
        id
      }
      modified_by{
        id
      }
      account_id
      access_token
      item_id
      link_session_id
      institution_name
      institution_id
      name
      mask
      type
      subtype
      excluded
      created_at
      modified_at
      webhook_url
      inactive
    }
  }
`;

export const FETCH_TRANSACTIONS_QUERY = gql`
  query($plaid_account_id: ID!, $company_id: ID!) {
    getAccountTransactions(
      plaid_account_id: $plaid_account_id
      company_id: $company_id
    ) {
      plaid {
        account_id
        amount
        iso_currency_code
        unofficial_currency_code
        category
        category_id
        date
        location {
          address
          city
          region
          postal_code
          country
          lat
          lon
        }
        name
        payment_meta {
          reference_number
          ppd_id
          payee_name
        }
        pending
        pending_transaction_id
        account_owner
        transaction_id
        transaction_type
      }
      database {
        id
        transaction_id
        account_id
        category
        category_id
        transaction_type
        name
        amount
        iso_currency_code
        unofficial_currency_code
        date
        location
        payment_meta
        pending
        pending_transaction_id
        account_owner
        timestamp
      }
      error
    }
  }
`;
export const FETCH_BALANCES_QUERY = gql`
  query($plaid_account_id: ID!, $company_id: ID!) {
    getAccountBalances(
      plaid_account_id: $plaid_account_id
      company_id: $company_id
    ) {
      balances {
        id
        timestamp
        date
        balance
      }
      current_balance
    }
  }
`;
export const GET_EMAIL = gql`
  query($id: ID!) {
    getEmail(id: $id)
  }
`;
export const GET_FILESTACK = gql`
  query {
    getFileStack {
      policy
      signature
      api_key
    }
  }
`;
export const GET_COMPANIES = gql`
  query {
    getCompanies {
      ${COMPANY}
    }
  }
`;

export const GET_USERS_FOR_COMPANY = gql`
  query($company_id: ID!) {
    getUsersForCompany(company_id: $company_id) {
      is_admin
      removed
      pending
      user {
        id
        email
        first_name
        last_name
        role
        profile_image_url
        phone
        calendar
      }
    }
  }
`;
export const GET_PASSWORD_RESET_TOKEN = gql`
  query($token: String!) {
    getPwdResetToken(token: $token) {
      user {
        id
        email
      }
    }
  }
`;
export const GET_ACCOUNT_ACTIVATION_TOKEN = gql`
  query($token: String!) {
    getAccountActivationToken(token: $token) {
      user {
        id
        email
      }
    }
  }
`;
export const GET_QUICKBOOKS_AUTH = gql`
  query($company_id: ID!) {
    getQBAuth2(company_id: $company_id) {
      redirectURL
    }
  }
`;
export const GET_DASHBOARD_REPORT = gql`
  query($company_id: ID!, $query_string: JSONString) {
    # add query string when calendar is available
    getDashboardReport(company_id: $company_id, query_string: $query_string) {
      bills_unpaid
      cash_balance
      cash_burn
      customers_owe_you {
        overdue
        overdue_percent
        this_week
        this_week_percent
        next_week
        next_week_percent
        future
        future_percent
        total
      }
      income_expenses_profit {
        income
        expenses
        profit
      }
      top_expenses_by_category {
        value
        label
        percent
        scaled_percent
      }
      top_expenses_by_vendor {
        value
        label
        percent
        scaled_percent
      }
      profit_and_loss_months {
        income
        expenses
        profit
        months
      }
    }
  }
`;
export const GET_BALANCE_SHEET_REPORT = gql`
  query($company_id: ID!, $query_string: JSONString) {
    # add query string when calendar is available
    getBalanceSheetReport(
      company_id: $company_id
      query_string: $query_string
    ) {
      report {
        level
        label
        value
        row_type
      }
    }
  }
`;
export const GET_PROFIT_AND_LOSS_REPORT = gql`
  query($company_id: ID!, $query_string: JSONString) {
    # add query string when calendar is available
    getProfitAndLossReport(
      company_id: $company_id
      query_string: $query_string
    ) {
      report {
        level
        label
        value
        row_type
      }
    }
  }
`;
export const GET_QB_CONNECTED_STATUS = gql`
  query($company_id: ID!) {
    getQBConnectedStatus(company_id: $company_id) {
      id
      refresh_token_timestamp
      company {
        id
        name
      }
    }
  }
`;
export const GET_FILETHIS = gql`
  query($company_id: ID!) {
    getCompanyStatements(company_id: $company_id) {
      id
      date
      filename
      account_name
    }
  }
`;
export const DOWNLOAD_STATEMENT = gql`
  query($id: ID!) {
    downloadStatement(id: $id)
  }
`;


export const GET_TRANSACTIONS = gql`
  query($company_id: ID!) {
    getTransactions(company_id: $company_id) {
      id
      qb_id
      status
      account_name
      account_id
      date
      description
      category_name
      category_id
      vendor_name
      vendor_id
      payment_type
      amount
      pushed
      created
      source
    }
  }
`;
export const GET_CHART_OF_ACCOUNTS = gql`
  query($company_id: ID!) {
    getChartOfAcc(company_id: $company_id) {
      id
      name
      account_type
      account_subtype
      current_balance
      classification
      description
      active
      qb_id
    }
  }
`;
export const GET_CATEGORIES = gql`
  query($company_id: ID!) {
    getCategories(company_id: $company_id) {
      id
      name
      account_type
      account_subtype
      current_balance
      classification
      description
      active
      qb_id
    }
  }
`;
export const GET_VENDORS = gql`
  query($company_id: ID!) {
    getVendors(company_id: $company_id) {
      id
      display_name
      print_name
      active
      qb_id
    }
  }
`;
export const GET_ACT_DATA = gql`
  query($company_id: ID!) {
    getCompany(company_id: $company_id) {
      account_company {
        id
        name
        account_type
        active
        qb_id
      }
      category_company {
        id
        name
        qb_id
      }
      vendor_company {
        id
        display_name
        qb_id
      }
      transaction_company {
        id
        qb_id
        status
        account_name
        account_id
        date
        description
        category_name
        category_id
        vendor_name
        vendor_id
        payment_type
        amount
        pushed
        created
        source
      }
    }
  }
`;
export const GET_COMPANY = gql`
  query($company_id: ID!) {
    getCompany(company_id: $company_id) {
      ${COMPANY_DETAIL}
    }
  }
`;

export const GET_QB_ACCOUNT_TYPES = gql`
  query {
    getAccountTypes {
      account
      subAccounts {
        name
        value
      }
    }
  }
`;

export const FILTER_FILES = gql`
  query(
    $company_id: ID!
    $page: Int
    $size: Int
    $sort: String
    $direction: String
    $filter: FilterType
  ) {
    filterFiles(
      company_id: $company_id
      page: $page
      size: $size
      sort: $sort
      direction: $direction
      filter_options: $filter
    ) {
      page
      pages
      count
      has_next
      has_prev
      objects {
        id
        filename
        description
        key
        source
        account_name
        access_level
        filetag {
          id
          name
        }
        filetype {
          id
          name
        }
        status
        date
        archived
      }
    }
  }
`;

export const GET_TYPES = gql`
  query {
    getTypes {
      id
      name
    }
  }
`;

export const GET_TAGS = gql`
  query {
    getTags {
      id
      name
    }
  }
`;

export const DOWNLOAD_FILE = gql`
  query($id: ID!) {
    downloadFile(id: $id)
  }
`;

export const GET_CLIENT_FILES = gql`
  query($company_id: ID!) {
    getAllUserDocuments(company_id: $company_id) {
      id
      filename
      date
      key
      uploaded_by{
        username,
        first_name,
        last_name
      }
    }
  }
`;
