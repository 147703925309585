import React from 'react';

const DashboardBox = ({ label, value }) => {
  return (
    <div className='dashboard-box-container'>
      <div className='dashboard-box'>
        <span className='bold'>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(value)}
        </span>
        <span className='label'>{label}</span>
      </div>
    </div>
  );
};

export default DashboardBox;
