export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const GET_USER = 'GET_USER';
export const LOADING = 'LOADING';
export const GET_ALL = 'GET_ALL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_USER = 'SET_USER';
export const EDIT_USER = 'EDIT_USER';
export const EXCLUDE_PLAID_ACCOUNT = 'EXCLUDE_PLAID_ACCOUNT';
export const GET_COMPANIES = 'GET_COMPANIES';
export const ADD_PLAID_ACCOUNT = 'ADD_PLAID_ACCOUNT';
export const UPLOAD_DOCUMENT = 'UPLOAD_DOCUMENT';
export const SET_COMPANY = 'SET_COMPANY';
export const RESET_USER = 'RESET_USER';
export const RESIZE = 'RESIZE';
export const ADD_COMPANIES = 'ADD_COMPANIES';
export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_CLIENT_PROFILE = 'UPDATE_CLIENT_PROFILE';
