/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';

export default ({
  name,
  children,
  callback,
  disabled,
  type,
  classN,
  style
}) => {
  return (
    <button
      style={{ ...style }}
      name={name}
      className={classN}
      type={type}
      disabled={disabled}
      onClick={callback}
    >
      {children}
    </button>
  );
};
