import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import moment from 'moment';
import Session from 'utils/session';
import useWindowDimensions from 'components/reusables/WindowSize';
import {
  ContentPaper,
  ContentHeader,
  FormButton
} from '@tycoonlabs/web-components';
import {PageHeader,PrimaryButton, FullPage} from '@tycoonlabs/web-components'

import TransactionsActions from './TransactionsActions';
import TransactionsTable from './TransactionsTable';
import TransactionDrawer from './TransactionDrawer';
import FilterDrawer from './FilterDrawer';
import SettingsDrawer from '../Settings/SettingsDrawer';
import {
  GET_TRANSACTIONS,
  GET_ACT_DATA,
  GET_CHART_OF_ACCOUNTS,
  GET_CATEGORIES,
  GET_VENDORS,
  GET_QB_ACCOUNT_TYPES
} from 'graphql/queries';
import { FILTER_TRANSACTIONS } from 'graphql/transaction';
import {
  SYNC_QB_DATA,
  ADD_TRANSACTION,
  PUSH_TRANSACTIONS
} from 'graphql/mutations';
import { from } from 'apollo-boost';
const Transactions = () => {
  const { width, height } = useWindowDimensions();
  const defaultFilters = {
    status: '',
    accountName: '',
    categoryName: '',
    payeeName: '',
    vendorName: '',
    showExcluded: '',
    amountMin: null,
    amountMax: null,
    dateFrom: moment()
      .subtract(6, 'months')
      .toDate(),
    dateTo: moment().toDate()
  };
  const initialTransactionState = {
    id: '',
    qb_id: '',
    account_name: '', //drop down
    account_id: '',
    category_name: '', //drop down
    category_id: '',
    description: '', //text field
    vendor_name: '', //drop down
    vendor_id: '',
    amount: '', //text field
    payment_type: '', //drop down
    date: moment(), //calendar
    processed: false,
    excluded: false
  };
  const { companyId, accountId } = useParams();
  // DATE PICKER HANDLERS AND STATE FOR FILTER
  const [pickerRef, setPickerRef] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [status, setStatus] = useState('');
  const [selectedAcc, setSelectedAcc] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawer, setDrawer] = useState('');
  const [selectedIndex2, setSelectedIndex2] = useState(0);
  const [selectDropdown, setSelectDropdown] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [filter, setFilter] = useState(defaultFilters);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('date');
  const [direction, setDirection] = useState('desc');

  const [fetchTransactions, { loading }] = useLazyQuery(FILTER_TRANSACTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ filterTransactions: { objects, count, page } }) => {
      setFilteredTransactions(objects);
      setCount(count);
      setPage(page);
    }
  });
  const [getCategories, {}] = useLazyQuery(GET_CATEGORIES, {
    fetchPolicy: 'no-cache',
    variables: {
      company_id: companyId
    },
    onCompleted: data => {
      setCategories(data.getCategories);
    }
  });
  const [getChartOfAccounts, {}] = useLazyQuery(GET_CHART_OF_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: {
      company_id: companyId
    },
    onCompleted: data => {
      setAccounts(data.getChartOfAcc);
    }
  });
  const [getVendors, {}] = useLazyQuery(GET_VENDORS, {
    fetchPolicy: 'no-cache',
    variables: {
      company_id: companyId
    },
    onCompleted: data => {
      setVendors(data.getVendors);
    }
  });

  const [getQBAccTypes, {}] = useLazyQuery(GET_QB_ACCOUNT_TYPES, {
    onCompleted: data => setAccountTypes(data.getAccountTypes)
  });

  const [addTransaction, {}] = useMutation(ADD_TRANSACTION, {
    onCompleted: () =>
      fetchTransactions({
        variables: inputVariables
      })
  });

  const [syncQBData, {}] = useMutation(SYNC_QB_DATA, {
    variables: { company_id: companyId },
    onCompleted: ({ syncQBData }) => {
      fetchTransactions();
    }
  });
  const handleApplyFilters = filtersToApply => {
    setFilter({
      ...filter,
      description: filtersToApply.description,
      accountName: filtersToApply.accountName,
      categoryName: filtersToApply.categoryName,
      vendorName: filtersToApply.vendorName,
      amountMin: filtersToApply.amountMin,
      amountMax: filtersToApply.amountMax,
      showExcluded: filtersToApply.showExcluded
    });
    setSearch(filtersToApply.description);
    console.log('####handleApplyFilters', filter);
  };

  const [pushTransactions, {}] = useMutation(PUSH_TRANSACTIONS, {
    onCompleted: () => {
      fetchTransactions({
        variables: inputVariables
      });
    }
  });

  const handePushTransactions = selection => {
    let formatted = null;
    if (selection === 'all') {
      formatted = filteredTransactions.map(t => {
        delete t.__typename;
        return t;
      });
    } else if (selection === 'selected') {
      formatted = selectedRows.map(t => {
        delete t.__typename;
        return t;
      });
    }
    pushTransactions({
      variables: {
        company_id: companyId,
        transactions: formatted
      }
    });
  };

  const getAllData = async () => {
    const asyncGetAccounts = await getChartOfAccounts();
    const asyncGetCategories = await getCategories();
    const asyncGetVendors = await getVendors();
    const asyncGetQBAccTypes = await getQBAccTypes();
  };

  const updateAccounts = async () => {
    const asyncGetAccounts = await getChartOfAccounts();
    const asyncGetCategories = await getCategories();
    const asyncGetTransactions = await fetchTransactions();
  };

  const updateVendors = async () => {
    const asyncGetVendors = await getVendors();
    const asyncGetTransactions = await fetchTransactions();
  };
  const onSearchChange = e => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const notifyError = () =>
    toast.error(
      'Something went wrong retrieving transactions, please try again.'
    );
  const notifyWarn = () =>
    toast.warn('unable to pull latest transactions, please try again later.');
  const account = Session.getPlaidAccount(companyId, accountId);
  const handleTransactionSelection = transaction => {
    console.log(transaction);
    setSelectedTransaction(transaction);
    handleOpenDrawer('editTransaction');
  };

  const handleOpenDrawer = drawer => {
    setDrawer(drawer);
    setOpenDrawer(true);
  };
  const handleNewTransaction = () => {
    setSelectedTransaction(initialTransactionState);
    handleOpenDrawer('addTransaction');
  };

  const handleLoaded = data => {
    const newTransactions = data.map(t => {
      return {
        account_name: t.account,
        description: t.description,
        category_name: t.category,
        vendor_name: t.payee,
        amount: t.amount,
        date: t.date,
        source: 'import',
        id: ''
      };
    });
    addTransaction({
      variables: { company_id: companyId, transactions: newTransactions }
    });
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_')
  };

  const inputVariables = {
    company_id: companyId,
    page,
    size,
    sort,
    direction,
    filter
  };

  useEffect(() => {
    if (
      openDrawer &&
      !categories.length &&
      !accounts.length &&
      !vendors.length
    ) {
      getAllData();
    }
  }, [openDrawer]);
  useEffect(() => {
    fetchTransactions({
      variables: inputVariables
    });
  }, [filter, size, page, sort, direction]);
  const data = {
    accounts,
    categories,
    vendors
  };

  const contentDrawer = () => {
    if (drawer === 'filter') {
      return (
        <FilterDrawer
          setOpenDrawer={setOpenDrawer}
          openDrawer={openDrawer}
          pickerRef={pickerRef}
          setPickerRef={setPickerRef}
          accounts={accounts}
          setFilter={setFilter}
          search={search}
          filter={filter}
          handleApplyFilters={handleApplyFilters}
          defaultFilters={defaultFilters}
          data={data}
        />
      );
    } else if (drawer === 'addTransaction' || drawer === 'editTransaction') {
      return (
        <TransactionDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          data={data}
          selectedTransaction={selectedTransaction}
          setTransactions={setTransactions}
          setAccounts={setAccounts}
          accounts={accounts}
          setCategories={setCategories}
          categories={categories}
          setVendors={setVendors}
          vendors={vendors}
          addTransaction={addTransaction}
          fetchTransactions={fetchTransactions}
          accountTypes={accountTypes}
          inputVariables={inputVariables}
        />
      );
    } else if (
      drawer === 'accounts' ||
      drawer === 'categories' ||
      drawer === 'vendors'
    ) {
      return (
        <SettingsDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          drawer={drawer}
          accounts={accounts}
          categories={categories}
          vendors={vendors}
          accountTypes={accountTypes}
          updateAccounts={updateAccounts}
          updateVendors={updateVendors}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <PageHeader>
        <h1>Transactions</h1>
        <PrimaryButton size="medium" color="secondary" onClick={handleNewTransaction}>
					New
				</PrimaryButton>
      </PageHeader>
      <FullPage>
      <TransactionsActions
          transactions={filteredTransactions}
          onSearchChange={onSearchChange}
          syncQBData={syncQBData}
          setSelectedIndex2={setSelectedIndex2}
          selectedIndex2={selectedIndex2}
          handleNewTransaction={handleNewTransaction}
          setOpenDrawer={setOpenDrawer}
          selectedRows={selectedRows}
          handleLoaded={handleLoaded}
          papaparseOptions={papaparseOptions}
          setSelectDropdown={setSelectDropdown}
          // pushTransactions={pushTransactions}
          handePushTransactions={handePushTransactions}
          handleOpenDrawer={handleOpenDrawer}
        />
        <TransactionsTable
          loading={loading}
          transactions={filteredTransactions}
          selectedIndex2={selectedIndex2}
          handleTransactionSelection={handleTransactionSelection}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          selectDropdown={selectDropdown}
          setSelectDropdown={setSelectDropdown}
          count={count}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          direction={direction}
          setDirection={setDirection}
        />
      </FullPage>
      {openDrawer ? contentDrawer() : null}
    </>
  );
};
export default Transactions;
