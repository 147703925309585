/** PACKAGE IMPORTS */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import ba from 'binascii';
import { toast } from 'react-toastify';

/** LOCAL IMPORTS */
import { ReactComponent as BackOfficeLogo } from 'assets/back-office.svg';
import Button from 'components/reusables/Button';
import Spinner from 'components/reusables/Spinner';
import configs from 'config';
import { Img } from "react-image";

/** GRAPHQL */
import { GET_ACCOUNT_ACTIVATION_TOKEN } from 'graphql/queries';
import { SET_USER_PASSWORD } from 'graphql/mutations';

const ActivateAccount = () => {
  let { token } = useParams();
  const history = useHistory();

  const [creds, setCreds] = useState({
    passwordOne: '',
    passwordTwo: ''
  });
  const authToken = localStorage.getItem('authToken');
  const [match, setMatch] = useState(false);

  const { passwordOne, passwordTwo } = creds;

  const [email, setEmail] = useState('');

  const [userId, setUserId] = useState('');

  const notifyError = e => {
    toast.error(e.graphQLErrors[0].message + '.');
    setCreds({
      passwordOne: '',
      passwordTwo: ''
    });
  };

  const setData = data => {
    setEmail(data.getAccountActivationToken.user.email);
    setUserId(data.getAccountActivationToken.user.id);
  };

  const { data } = useQuery(GET_ACCOUNT_ACTIVATION_TOKEN, {
    variables: { token: token },
    onCompleted: data => setData(data)
  });

  const [
    setPassword,
    { data: setPassData, loading: setPassLoading }
  ] = useMutation(SET_USER_PASSWORD, {
    variables: { password: passwordOne, user_id: userId },
    onCompleted: () => history.replace('/login'),
    onError: e => notifyError(e)
  });

  const changeHandler = e => {
    const { name, value } = e.target;
    setCreds({ ...creds, [name]: value });
  };

  const onFormSubmit = e => {
    e.preventDefault();
    if (passwordOne === passwordTwo) {
      setPassword();
    } else {
      toast.error('Please fill out all fields.');
    }
    setCreds({
      passwordOne: '',
      passwordTwo: ''
    });
  };

  useEffect(() => {
    if (passwordOne === passwordTwo) {
      setMatch(true);
    } else {
      setMatch(false);
    }
    if (localStorage.getItem('authToken')) {
      localStorage.removeItem('authToken');
      window.location.reload();
    }
  }, []);
  const LOGO = <Img src={configs.app.logo} style={{ maxWidth: "300px" }} />;
  return (
    <main className='creds-container'>
      <div className='creds-card'>
        <div className='logo-container'>
          {LOGO}
        </div>
        <form
          onSubmit={e => onFormSubmit(e)}
          className='creds-inputs-container'
        >
          <span>Activate your account</span>
          <h2 style={{ fontSize: 16, marginBottom: 15 }}>{email}</h2>

          <input
            autoComplete='new-password'
            name='passwordOne'
            value={creds.passwordOne}
            type='password'
            onChange={changeHandler}
            placeholder='Enter Password'
            required
          />
          <input
            autoComplete='new-password'
            name='passwordTwo'
            value={creds.passwordTwo}
            type='password'
            onChange={changeHandler}
            placeholder='Verify Password'
            required
          />
          {setPassLoading && <Spinner />}
          {!setPassLoading && (
            <Button type='submit' classN='creds-button' disabled={!match}>
              Activate your account
            </Button>
          )}
        </form>
        <div className='redirect-signup'>
          <span>
            Already have an account?
            <p onClick={() => history.push('/login')}>Sign in</p>
          </span>
        </div>
      </div>
    </main>
  );
};

export default ActivateAccount;
