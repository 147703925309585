import { RESIZE } from '../actions/actionTypes';

const { innerWidth, innerHeight } = window;

const initialState = {
  innerWidth,
  innerHeight
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESIZE:
      return { ...action.resize };
    default:
      return state;
  }
};
