/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState,useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';

import { ReactComponent as BackOfficeLogo } from 'assets/back-office.svg';
import Button from '../reusables/Button';
import Spinner from '../reusables/Spinner';
import configs from 'config';
import { Img } from "react-image";

import {SALES } from 'utils/roles';

import { setUser, setCompany } from 'actions';
import { LOGIN_MUTATION } from 'graphql/mutations';
import { toast } from 'react-toastify';
import Session from '../../utils/session';


const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const targetCompanyId =
    location.pathname.match(/\/(\d+)/) && location.pathname.match(/\/(\d+)/)[1];
  const isLoggedIn = Session.isLoggedIn();
  const token = localStorage.getItem('authToken');
  const companyId = Session.getCompanyId()

  const [creds, setCreds] = useState({
    email: '',
    password: ''
  });

 
  const [login, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: data => {
      const { token_auth } = data;
      const { token, company } = token_auth;
      let { user } = token_auth;
      localStorage.setItem('authToken', token);

      /** SET USER STATE IN USER REDUCER */
      user.companies = [company];
      if (company) {
        dispatch(setCompany(company));
        if (user.role === SALES){
          history.replace(`/clients`)
        }else{
          history.replace(`/dashboard/${company.id}`)
        }
      }
      dispatch(setUser(user));
    },
    onError: e => {
      toast.error('Your username or password was incorrect. Please try again.');
    }
  });

  const { email, password } = creds;

  const changeHandler = e => {
    const { name, value } = e.target;
    setCreds({ ...creds, [name]: value });
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    login({ variables: { email: email.toLowerCase(), password } });
  };
  useEffect(() => {
    if (token || isLoggedIn){
      history.replace("/dashboard/"+companyId);
    }
  }, []);

  const LOGO = <Img src={configs.app.logo} style={{maxWidth: "300px"}}/>;
  
  return (
    <main className='creds-container'>
      <div className='creds-card'>
        <div className='logo-container'>
          {LOGO}
        </div>
        <form
          onSubmit={e => onFormSubmit(e)}
          className='creds-inputs-container'
        >
          <span>Welcome back!</span>
          <input
            value={creds.email}
            onChange={changeHandler}
            name='email'
            type='email'
            placeholder='Email'
            required
          />
          <input
            value={creds.password}
            onChange={changeHandler}
            name='password'
            type='password'
            placeholder='Password'
            required
          />
          <p onClick={() => history.push('/pass_recovery')}>
            Forgot your password?
          </p>
          {loading && <Spinner />}
          {!loading && (
            <Button type='submit' classN='creds-button'>
              Sign in to your account
            </Button>
          )}
        </form>
        {/* <div className='redirect-signup'>
          <span>
            Don't have an account yet?
            <p>
              <a href='http://www.backoffice.co/start.html'>Sign up</a>
            </p>
          </span>
        </div> */}
      </div>
    </main>
  );
};

export default Login;
