/* eslint-disable no-template-curly-in-string */
const Emails = [
	{
		name: "Monthly Report With Followups",
		title: "Monthly Report With Followup Questions",
		desc: "After each month (sending preliminary reports after the phone call).",
		template:
			"Great News ${company.name} , we've completed the reconciliation of your accounts through Jan 31, 2020. " +
			"\n\n" +
			"You can check out your monthly preliminary report online via the following link: \n\n" +
			"${dashboardLink}" +
			"\n\n" +
			"This report is also attached in this email. (Along with it, we also attached an ask client list for " +
			"transactions we wanted to clarify with you. Please complete and return at your earliest " +
			"convenience.)" +
			"\n\n" +
			"If there is anything that needs to be modified or you have any questions, please reach out to support@backoffice.co." +
			"\n\n" +
			"Thank you!" +
			"\n\n" +
			"${user.first_name}" +
			"\n\n" +
			"${disclaimer}"
	},
	{ name : "Monthly Report Without Followup ",
		title: "Monthly Report Without Followup Questions",
		desc: "After each month (sending preliminary reports after the phone call).",
		template:
			"Great News ${company.name} , we've completed the reconciliation of your accounts through Jan 31, 2020. " +
			"\n\n" +
			"You can check out your monthly preliminary report online via the following link: \n\n" +
			"${dashboardLink}" +
			"\n\n" +
			"This report is also attached in this email. " +
			"\n\n" +
			"If there is anything that needs to be modified or you have any questions, please reach out to support@backoffice.co." +
			"\n\n" +
			"Thank you!" +
			"\n\n" +
			"${user.first_name}" +
			"\n\n" +
			"${disclaimer}"
	},
	{ name : "Requesting Information",
		title: "Request Information from Client",
		desc: "General purpose email for requesting information from client. pl  Be sure to fill in the request details. ",
		template:
			"I hope you’re having a great day! We have been trying to reach you by phone and " +
			"email. We would like to get your accounting records updated but we are missing the " +
			"following information:" +
			"\n\n\n\n" +
			"Please provide it as soon as possible by email to support@backoffice.co so we can " +
			"keep your records up to date.\n" +
			"Thank you in advance and we look forward to hearing from you." +
			"\n\n" +
			"Thank you!" +
			"\n\n" +
			"${user.first_name}" +
			"\n\n" +
			"${disclaimer}"
	},
	{ name : "Post Initial Setup",
		title: "Post Initial Setup Followup",
		desc: "After initial setup (sending preliminary reports after the phone call.)",
		template:
			"Great News, we've completed the initial set up of your account. " +
			"\n\n" +
			"Attached are the preliminary reports. (Also attached is an ask client list " +
			"for transactions we wanted to clarify with you. Please complete and return at your" +
			"earliest convenience.)\n\n" +
			"If there is anything that needs to be modified or you have any questions, please reach" +
			"out to support@backoffice.co." +
			"\n\n" +
			"Thank you!"+
			"\n\n" +
			"${user.first_name}" +
			"\n\n" +
			"${disclaimer}"
	},
	{ name : "Post Onboarding Followup",
		title: "Post Onboarding Call with CPA Followup",
		desc: "Followup after onboarding call with CPA to provide clients with communication information.",
		template:
			"Thank you for taking the time to discuss your business.  I want to make sure you have " +
			"my email address, this is the best way to contact me. General information and " +
			"administrative questions can be emailed to support@backoffice.co for your accountant " +
			"or our administrative team to respond.  \n\n" +
			"We want to make sure you are completely satisfied with our services.  If, for whatever " +
			"reason, you encounter any issues, please let me know right away.  You can also use " +
			"the link below to schedule a call with me at your convenience.\n\n" +
			"The accountant assigned to work on your account will reach out to you just to say hello." +
			"\n\n" +
			"Have a great day!" +
			"\n\n" +
			"${user.first_name}" +
			"\n\n" +
			"${disclaimer}"
	},
	{ name : "Post Signing Up Access Request",
		title: "Post Signing Up Access Request",
		desc: "After signing up, request read-only access if not yet done by phone.",
		template:
			"Hello ${company.name}," +
			"\n\n" +
			"This is ${user.first_name} from Back Office. I'm following up the call or voicemail I left on your phone.\n\n" +
			"We are reaching out to get started on your account (s) with us.  We will need read only access to your " +
			"accounts so we may retrieve statements every month." +
			"Please use the following credentials for the access.\n\n" +
			"Name:\n" +
			"Email:\n" +
			"Phone:\n\n" +
			"Please forward any username and password you may need to create on our behalf. " +
			"Should you have any questions regarding this information, please feel free to reach out " +
			"to us at 1-800-545-1403 or by emailing support@backoffice.co. " +
			"\n\n" +
			"Thank you!" +
			"\n\n" +
			"${user.first_name}" +
			"\n\n" +
			"${disclaimer}"
	},
];
export default Emails;
