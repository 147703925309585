import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from 'react-apollo';
import QBLogo from 'assets/qblogo.jpeg';
import { GET_QUICKBOOKS_AUTH, GET_QB_CONNECTED_STATUS } from 'graphql/queries';
import { DISCONNECT_QB, TEST_QB_CONNECTION } from 'graphql/mutations';
import {
  ContentCardShadowed,
  FormButton,
  PageHeader,
  Page
} from '@tycoonlabs/web-components';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
const Quickbook = () => {
  const { companyId } = useParams();
  const [qbConnection, setQBConnection] = useState(false);
  useQuery(GET_QB_CONNECTED_STATUS, {
    variables: {
      company_id: companyId
    },
    onCompleted: data => {
      setQBConnection(data.getQBConnectedStatus);
    }
  });
  const [getQBAuth, {}] = useLazyQuery(GET_QUICKBOOKS_AUTH, {
    fetchPolicy: 'no-cache',
    variables: {
      company_id: companyId
    },
    onCompleted: data => window.open(data.getQBAuth2.redirectURL, '_blank')
  });
  const [disconnectQB, {}] = useMutation(DISCONNECT_QB, {
    variables: {
      company_id: companyId
    },
    onCompleted: () => setQBConnection(false)
  });
  const [testConnection, {}] = useMutation(TEST_QB_CONNECTION, {
    variables: {
      company_id: companyId
    },
    onCompleted: ({ testQBConnection }) => {
      if (testQBConnection.success) {
        setQBConnection({
          ...qbConnection,
          refresh_token_timestamp:
            testQBConnection.qb_integration.refresh_token_timestamp
        });
      }
    }
  });
  const handleQBConnection = () => {
    if (qbConnection) {
      disconnectQB();
    } else {
      getQBAuth();
    }
  };
  let subheader = '';
  let message = '';
  console.log("####", qbConnection)
  if (qbConnection && qbConnection.refresh_token_timestamp) {
    subheader =
      'Last refreshed on: ' +
      moment(qbConnection.refresh_token_timestamp).format('L [at] h:mm a');
  } else {
    message = 'Please setup QuickBooks integration';
  }

  return (
    <>
      <ContentCardShadowed>
        <CardHeader
          title={'QuickBooks'}
          avatar={<Avatar aria-label='QuickBooks' alt={'QB'} src={QBLogo} />}
          subheader={subheader}
        />
        <CardContent>{message}</CardContent>
        <CardActions className={'justify-between'}>
          {qbConnection && (
            <FormButton onClick={testConnection}>Test</FormButton>
          )}
          <FormButton color='primary' onClick={handleQBConnection}>
            {qbConnection ? 'Disconnect' : 'Connect'}
          </FormButton>
        </CardActions>
      </ContentCardShadowed>
    </>
  );
};
const Integrations = () => {
  return (
    <>
      <PageHeader>
        <h1>Integrations</h1>
      </PageHeader>
      <Page>
      <Grid container spacing={1}>
        <Grid item  item xs={12} sm={6}>
          <Quickbook />
        </Grid>
      </Grid>
      </Page>
    </>
  );
};
export default Integrations;
