import React from 'react';
import {Route} from 'react-router-dom';
import User from "./User"
const Admin = () => {
	return (
				<Route
					exact
					path='/platform/user/'
					component={User}
				/>
	);
};
export default Admin;
