import React from 'react';
import { useField } from 'formik';

const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className={`text-field ${props.name}`}>
      <label htmlFor={props.id || props.name}>{label}</label>
      {meta.touched && meta.error ? (
        <span className='error'>{meta.error}</span>
      ) : null}
      <input
        className={
          meta.touched && meta.error ? 'text-input input-error' : 'text-input'
        }
        {...field}
        {...props}
      />
    </div>
  );
};

export default TextField;
