import React from 'react';

const ProgressBar = ({ label, value, percent }) => {
  return (
    <div className='progress-bar-container'>
      <div className='progress-label-value'>
        <div>${value}</div>
        <div>{label}</div>
      </div>
      <div className='progress-bar'>
        <div
          className='progress-bg'
          style={{ width: percent > 0 ? `${percent}%` : '0' }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
