/* eslint-disable react/prop-types */
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { ADMIN, CS, ACCOUNTANT, PA, CLIENT, ASSOCIATE } from 'utils/roles';
import Session from '../../../utils/session';
import { ReactComponent as DocumentsIcon } from 'assets/documents.svg';

import PlaidLink from 'react-plaid-link';
import configs from 'config';

const PlaidCard = ({ handleOnExit, handleOnSuccess, handleOnEvent }) => {
  const { companyId } = useParams();
  const role = Session.getRole();
  return (
    <div className='accounts-table'>
      <div className='card-content'>
        {(role === ADMIN ||
          role === ACCOUNTANT ||
          role === PA ||
          role === CLIENT ||
          role === CS) && (
            <>
              <h1>Let's get started by linking your business accounts</h1>
              <p>
                We use our {configs.app.partner} proprietary web app to complete your monthly
                bookkeeping by utilizing two types of bank connections. One connection
                is in charge of pulling your bank transactions and balances in real
                time. The second connection is in charge of pulling your monthly bank
                statements.
              </p>
              <div className='options'>
                <PlaidLink
                  className='card-button'
                  clientName={configs.app.partner}
                  env='production'
                  publicKey='41268990c10b611a6f7b030a6882c3'
                  publicKey='1a73cefba99cb20309a1e926c53481'
                  product={['auth', 'transactions']}
                  webhook='https://app.backoffice.co/plaid/webhook/'
                  onExit={handleOnExit}
                  onSuccess={handleOnSuccess}
                  onEvent={handleOnEvent}
                  style={null} // overrride built-in style
                >
                  LINK ACCOUNTS
                </PlaidLink>
                {/* <Link to={`/bookkeeping/${companyId}/filethis`}>
                  <button className='card-button'>
                    <h3>RETRIEVE STATEMENTS</h3>
                  </button>
                </Link> */}
              </div>
              <div className='card-footer'>
                <span>Your financial information is always safe.</span>
                <span>
                  We use industry standard 256-bit encryption to connect your data to
                  our software.
                </span>
                <span>This is the same level of security used with online banking.</span>
              </div>
            </>
          )}
          {(role === ASSOCIATE) && (
            <>
              <h1>No business account has been setup yet.</h1>
              <p>Please comeback later or contact your associate to set it up. </p>
            </>
          )}
      </div>
    </div>
  );
};

export default PlaidCard;
