import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { ContentCardShadowed, PageHeader, Page } from '@tycoonlabs/web-components'
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import { ActionCard} from 'components/reusables/AppStyles';

const Reports = () => {
  const history = useHistory();
  const { companyId } = useParams();

  return (
    <>
      <PageHeader>
        <h1>Reports</h1>
      </PageHeader>
      <Page className="bookkeeping-container">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={3}>
            <ActionCard onClick={() => history.replace(`/reports/${companyId}/profitandloss`)}>
              <CardHeader
                title="Profit and Loss"
              />
              <CardActions disableSpacing className={"flex flex-row-es"}>
                <Link  to={`/reports/${companyId}/profitandloss`}>
                  Click here to run report.
                </Link>
              </CardActions>
            </ActionCard>
          </Grid>
          <Grid item xs={12} sm={3}>
            <ActionCard onClick={() => history.replace(`/reports/${companyId}/balancesheet`)}>
              <CardHeader
                title="Balance Sheet"
              />
              <CardActions disableSpacing className={"flex flex-row-es"}>
                <Link to={`/reports/${companyId}/balancesheet`}>
                  Click here to run report.
                </Link>
              </CardActions>
            </ActionCard>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default Reports;
