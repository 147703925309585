const Disclaimer = "\n\n"+
	"Notice: Back office provides bookkeeping services based on the information you provide.   " +
	"Our responsibility is to reconcile the bank and credit card accounts and categorize " +
	"transactions with your approval, reviewed by you. \n" +
	"The financial reports in our platform are generated automatically using whichever compatible " +
	"accounting software you have chosen (e.g. QuickBooks, Freshbooks, Xero, etc.) based on information " +
	"provided to us by you, our client. Further, we reconcile your accounts using a variety of data sources " +
	"including but not limited to your bank statements, credit card statements, payroll reports, sales tax " +
	"filings, deposit reports from merchant services provides, but we do not provide auditing or attestation " +
	"services related to these reconciliations.  All reporting is subject to further edit pending " +
	"your review and approval.";


	export default Disclaimer;
