/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import PlaidLink from 'react-plaid-link';
import { toast } from 'react-toastify';

import { ReactComponent as WarningIcon } from 'assets/error-exclamation.svg';
import Session from '../../../utils/session';
import { plaidAddAccount } from 'actions';
import configs from 'config';

import {
  ADD_PLAID_ACCOUNT,
  ADD_PLAID_ERROR,
  EDIT_INACTIVE_STATUS
} from 'graphql/mutations';

const PlaidAuth = ({ publicToken }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const companyIndex = Session.getCompanyIndex(companyId);

  const [addPlaidAccount, { loading }] = useMutation(ADD_PLAID_ACCOUNT, {
    onCompleted: data => {
      const accounts = data.addPlaidAccount.accounts.map(account =>
        JSON.parse(account)
      );
      dispatch(plaidAddAccount(companyIndex, accounts));
    }
  });

  const [editInactiveStatus, {}] = useMutation(EDIT_INACTIVE_STATUS, {
    onCompleted: data => console.log(data)
  });

  const [sendPlaidError, {}] = useMutation(ADD_PLAID_ERROR, {
    onCompleted: data => {
      toast.warn(
        'There was an error linking your account. Please try again later.'
      );
    }
  });

  const handleOnSuccess = (public_token, metadata) => {
    editInactiveStatus();
  };

  const handleOnExit = (error, metadata) => {
    toast.warn('Your account was not reauthenticated. Please try again.');
  };

  const handleOnEvent = (eventname, metadata) => {
    if (eventname === 'EXIT') {
      sendPlaidError({
        variables: { company_id: companyId, metadata: JSON.stringify(metadata) }
      });
    }
  };

  useEffect(() => {
    const parentDiv = document.getElementsByClassName('plaid-auth')[0]
      .parentElement;
    parentDiv.style.width = '100%';
  }, []);

  return (
    <div className='inactive-accounts'>
      <PlaidLink
        className='plaid-auth'
        clientName={configs.app.partner}
        env='production'
        publicKey='41268990c10b611a6f7b030a6882c3'
        // publicKey='1a73cefba99cb20309a1e926c53481'
        product={['auth', 'transactions']}
        token={publicToken}
        webhook='https://app.backoffice.co/plaid/webhook/'
        onExit={handleOnExit}
        onEvent={handleOnEvent}
        onSuccess={handleOnSuccess}
        style={null}
      >
        <WarningIcon />
        <span>
          One or more of your accounts require reauthentication with Plaid.
          Please click here to reauthenticate.
        </span>
      </PlaidLink>
    </div>
  );
};

export default PlaidAuth;
