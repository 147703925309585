/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import Button from '../reusables/Button';
import Spinner from '../reusables/Spinner';
import { EDIT_USER } from '../../graphql/mutations';

const EditUserInfo = ({ user }) => {
  const [userInfo, setUserInfo] = useState({
    role: '',
    address: '',
    address_two: '',
    phone: '',
    company: '',
    city: '',
    state: '',
    country: '',
    postal_code: '',
    profile_image_url: user.profile_image_url
  });

  const {
    role,
    address,
    address_two,
    phone,
    company,
    city,
    state,
    postal_code,
    country
  } = user;

  const notifySuccess = () =>
    toast('Your information was successfully updated!');

  const notifyError = () =>
    toast(
      'Something went wrong, please review your information and try again.'
    );

  const [editUser, { loading }] = useMutation(EDIT_USER, {
    variables: {
      user_id: user.id,
      modified_by_id: user.id,
      userinput: {
        role,
        company,
        address,
        address_two,
        city,
        state,
        postal_code,
        country
      }
    },
    onCompleted: () => notifySuccess(),
    onError: () => notifyError()
  });

  useEffect(() => {
    if (user) {
      setUserInfo({
        role,
        address,
        address_two,
        phone,
        company,
        city,
        state,
        country,
        postal_code
      });
    }
  }, [user]);

  const onFormSubmit = e => {
    e.preventDefault();
    editUser();
  };

  const changeHandler = e => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  return (
    <form onSubmit={e => onFormSubmit(e)} className='card-container'>
      <div className='inputs-container'>
        <div>
          <label>Company Name</label>
          <input
            name='company'
            onChange={changeHandler}
            value={userInfo.company || ''}
          />
        </div>
        <div>
          <label>Phone Number</label>
          <input
            onChange={changeHandler}
            name='phone'
            value={userInfo.phone || ''}
          />
        </div>
        <div className='profile-address right'>
          <div className='address'>
            <label>Address</label>
            <input
              onChange={changeHandler}
              name='address'
              value={userInfo.address || ''}
            />
          </div>
          <div className='apartment'>
            <label>Suite / Unit</label>
            <input
              onChange={changeHandler}
              name='address_two'
              value={userInfo.address_two || ''}
            />
          </div>
        </div>
        <div>
          <label>City</label>
          <input
            onChange={changeHandler}
            name='city'
            value={userInfo.city || ''}
          />
        </div>
        <div>
          <label>State</label>
          <input
            onChange={changeHandler}
            name='state'
            value={userInfo.state || ''}
          />
        </div>
        <div>
          <label>Country</label>
          <input
            onChange={changeHandler}
            name='country'
            value={userInfo.country || ''}
          />
        </div>
        <div>
          <label>Zip / Postal Code</label>
          <input
            onChange={changeHandler}
            name='postal_code'
            value={userInfo.postal_code || ''}
          />
        </div>
        <div className='profile-buttons'>
          {!loading && <Button classN='save-btn'>Save</Button>}
          {loading && <Spinner />}
        </div>
      </div>
    </form>
  );
};

export default EditUserInfo;
